import { lazy } from 'react';
import { BORouteIdEnum } from 'config/enums';
import { AdminGuard } from 'shared/guards/AdminGuard';

// lazy loaded pages
const AccountsPage = lazy(() => import('pages/AccountsPage/AccountsPage'));
const AddAccountPage = lazy(() => import('pages/AccountsPage/AddAccount/AddAccount'));
const UpdateAccountPage = lazy(() => import('pages/AccountsPage/UpdateAccount/UpdateAccount'));

const AccountsRoutes = {
  path: BORouteIdEnum.Accounts,
  children: [
    {
      path: BORouteIdEnum.Root,
      element: (
        <AdminGuard>
          <AccountsPage />
        </AdminGuard>
      ),
    },
    {
      path: BORouteIdEnum.Add,
      element: <AddAccountPage />,
      children: [{ path: BORouteIdEnum.id, element: <AddAccountPage /> }],
    },
    {
      path: BORouteIdEnum.EditId,
      element: <UpdateAccountPage />,
    },
  ],
};

export default AccountsRoutes;
