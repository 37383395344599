export const exercise = {
  title: 'Bibliothèque ASC',
  subtitle:
    'Retrouvez ici tous les exercices référencés par ASC. Ils sont fait pour vous aider à gagner du temps pour repartir de ces mouvements et configurer vos programmes sportifs',
  asc_library: 'Bibliothèque ASC',
  categories: 'Catégories',
  search_placeholder: 'Rechercher...',

  suggest_new_exercise: 'Proposez votre exercice à vos clients',
  add_my_exercise: 'Créer mon exercice',
  no_exercises_yet: 'Aucun exercice enregistré',
  view_more_programs: 'Voir plus de programmes',
  delete_confirmation: 'Voulez-vous vraiment supprimer l’exercice {{exerciseName}} ?',

  create_exercise: 'Créer un exercice',
  edit_exercise: 'Modifier un exercice',
  configure_exercise_categories: 'Configurez les catégories de l’exercice',
  create_the_exercise: "Créer l'exercice",
  edit_the_exercise: "Modifer l'exercice",

  form_inputs: {
    extra_advice: 'Consigne complémentaire',
    extra_advice_placeholder: 'Ajoutez une consigne si nécessaire',
    product_visual: 'Visuel de l’exercice',
    product_name: 'Nom du produit',
    product_name_placeholder: 'Donnez un nom à votre produit',
    advices: 'Consignes',
    advice: 'Consigne',
    url_link: 'Lien URL',
    url_link_placeholder: 'Ajouter un lien YouTube, Vimeo etc…',
    link_required: 'Le lien URL est requis',
    body_zone: 'Zone du corps',
    body_zone_placeholder: 'Rechercher...',
    muscular_group: 'Groupe musculaire',
    equipment: 'Équipements',
    other_categories: 'Autres catégories',
    indicate_body_zone: 'Indiquez à votre client la zone de travail',
    advice_placeholder: 'Décrivez votre consigne...',
  },

  invalid_url: "Lien d'exercice invalide",
  add_additional_media: 'Ajouter une media supplémentaire',
  allowed_media: 'Format autorisé : {{allowedMedias}}. Max {{maxFiles}} fichiers',

  exercise_created: 'Votre exercice a été créé.',
  exercise_updated: 'Votre exercice a été mise à jour avec succès.',
};
