import { transformApiResponse } from 'redux/api/BaseApiTransform';
import {
  HoursTypes,
  HoursTypesFromApi,
  TrainingHour,
  TrainingHourFromApi,
} from 'redux/api/user/UserForm/HoursUserForm/hoursFormApi.type';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';

{
  /** *** HOURS ****/
}
export const transformHours = (hour: HoursTypesFromApi): HoursTypes => {
  return {
    id: hour.id,
    startTime: hour.start_time,
    endTime: hour.end_time,
  };
};
export const transformHoursListResponse = (
  response: ApiArrayDataResponse<HoursTypesFromApi>,
): ArrayDataResponse<HoursTypes> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((hour) => {
      return transformHours(hour);
    }),
  };
};

{
  /** *** STATISTIC _ HOURS ****/
}
export const transformStatisticResponse = (response: TrainingHourFromApi): TrainingHour => {
  return {
    data: { total: response.data.total, trainingHours: response.data.training_hours },
  };
};
