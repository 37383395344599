import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../BaseQueryConfig';
import { Platform } from 'features/Translation/Types/models/Platform';
import { Section } from 'features/Translation/Types/models/Section';
import { Group } from 'features/Translation/Types/models/Group';
import { Language } from 'features/Translation/Types/models/Language';
import { Key } from 'features/Translation/Types/models/Key';
import {
  transformListingPlatformsResponse,
  transformCreatePlatformResponse,
  transformCreateSectionResponse,
  encodeCreateSectionRequest,
  encodeCreateGroupRequest,
  transformCreateGroupResponse,
  transformListingLanguages,
  transformListingKeys,
  encodeCreateKeyRequest,
  encodeCreateLanguageRequest,
  encodeUploadTranslationRequest,
} from 'redux/api/translation/translationApi.transform';
import {
  CreatePlatform,
  CreateSection,
  CreateSectionResponse,
  DeleteSection,
  GetAllPlatformsResponse,
  CreatePlatformResponse,
  CreateGroup,
  CreateGroupResponse,
  DeleteGroup,
  GetLanguagesResponse,
  UpdatePlatformRequest,
  UpdateSectionRequest,
  UpdateGroup,
  KeyApi,
  CreateKeyRequest,
  UpdateKeyRequest,
  CreateLanguageRequest,
  GetTranslationKeysRequest,
  UploadTranslationRequest,
  GetBackgroundSyncStatusResponse,
} from 'redux/api/translation/translationApi.type';
import { ENDPOINTS } from 'features/Translation/config/endpoints';
import { injectParams, joinPaths } from 'utils/helpers';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { injectGetTranslationKeysToApi } from 'features/Translation/utils/helpers/url.helpers';

export const translationApi = createApi({
  reducerPath: 'translationApi',
  baseQuery: fetchBaseQuery(baseQueryConfig),
  tagTypes: ['Keys', 'Languages', 'Platforms'],
  endpoints: (builder) => ({
    // Get ALL platforms
    getAllPlatforms: builder.query<Platform[], void>({
      query: () => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.PLATFORMS]),
        method: 'GET',
      }),
      transformResponse: (response: GetAllPlatformsResponse) =>
        transformListingPlatformsResponse(response.data),
      providesTags: ['Platforms'],
    }),
    // Create Platform
    createPlatform: builder.mutation<Platform, CreatePlatform>({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.PLATFORMS]),
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreatePlatformResponse) =>
        transformCreatePlatformResponse(response.data),
    }),
    // Delete Platform
    deletePlatform: builder.mutation<Platform, number>({
      query: (id) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.PLATFORMS, ENDPOINTS.BY_ID(id)]),
        method: 'DELETE',
      }),
    }),
    // Update Platform
    updatePlatform: builder.mutation<Platform, UpdatePlatformRequest>({
      query: (params) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.PLATFORMS, ENDPOINTS.BY_ID(params.id)]),
        method: 'PATCH',
        body: {
          name: params.name,
          code: params.code,
        },
      }),
    }),
    // Create Section
    createSection: builder.mutation<Section, CreateSection>({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.SECTIONS]),
        method: 'POST',
        body: encodeCreateSectionRequest(body),
      }),
      transformResponse: (response: CreateSectionResponse) =>
        transformCreateSectionResponse(response.data),
    }),
    // Delete Section
    deleteSection: builder.mutation<Section, DeleteSection>({
      query: ({ sectionId }) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.SECTIONS, ENDPOINTS.BY_ID(sectionId)]),
        method: 'DELETE',
      }),
    }),
    // Update Section
    updateSection: builder.mutation<Section, UpdateSectionRequest>({
      query: ({ sectionId, name }) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.SECTIONS, ENDPOINTS.BY_ID(sectionId)]),
        method: 'PATCH',
        body: { name },
      }),
    }),
    // Create Group
    createGroup: builder.mutation<Group, CreateGroup>({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.GROUPS]),
        method: 'POST',
        body: encodeCreateGroupRequest(body),
      }),
      transformResponse: (response: CreateGroupResponse) => transformCreateGroupResponse(response),
    }),
    // Create Sub Group
    createSubGroup: builder.mutation<Group, CreateGroup>({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.GROUPS]),
        method: 'POST',
        body: encodeCreateGroupRequest(body),
      }),
      transformResponse: (response: CreateGroupResponse) => transformCreateGroupResponse(response),
    }),
    // Delete Group
    deleteGroup: builder.mutation<Group, DeleteGroup>({
      query: ({ groupId }) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.GROUPS, ENDPOINTS.BY_ID(groupId)]),
        method: 'DELETE',
      }),
    }),
    // Update Group
    updateGroup: builder.mutation<Group, UpdateGroup>({
      query: (params) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.GROUPS, ENDPOINTS.BY_ID(params.groupId)]),
        method: 'PATCH',
        body: { name: params.name },
      }),
    }),
    // Get All Languages
    getAllLanguages: builder.query<Language[], void>({
      query: () => ({
        url: injectParams(joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.LANGUAGES]), {
          pagination: 0,
        }),
        method: 'GET',
      }),
      providesTags: ['Languages'],
      transformResponse: (response: GetLanguagesResponse) =>
        transformListingLanguages(response.data),
    }),
    // Listing Keys
    getKeys: builder.query({
      query: ({ selectedItem, queryParams, platformId }: GetTranslationKeysRequest) =>
        injectParams(joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.KEYS]), {
          ...injectGetTranslationKeysToApi({ selectedItem, platformId, queryParams }),
        }),
      transformResponse: (response: ApiArrayDataResponse<KeyApi>) => transformListingKeys(response),
      providesTags: ['Keys'],
      keepUnusedDataFor: 0,
    }),
    // Create Key
    createKey: builder.mutation<Key, CreateKeyRequest>({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.KEYS]),
        method: 'POST',
        body: encodeCreateKeyRequest(body),
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Keys']),
    }),
    // Update Key
    updateKey: builder.mutation<Key, UpdateKeyRequest>({
      query: ({ id, body }) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.KEYS, ENDPOINTS.BY_ID(id)]),
        method: 'PATCH',
        body: encodeCreateKeyRequest(body),
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Keys']),
    }),
    // Delete Key
    deleteKey: builder.mutation<Key, number | undefined>({
      query: (id) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.KEYS, ENDPOINTS.BY_ID(id)]),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Keys']),
    }),
    // Add Language
    createLanguage: builder.mutation<Language, CreateLanguageRequest>({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.LANGUAGES]),
        method: 'POST',
        body: encodeCreateLanguageRequest(body),
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Languages', 'Keys']),
    }),
    // Delete Language
    deleteLanguage: builder.mutation<Language, number | undefined>({
      query: (id) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.LANGUAGES, ENDPOINTS.BY_ID(id)]),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Languages', 'Keys']),
    }),
    // Update Language
    updateLanguage: builder.mutation<Language, CreateLanguageRequest>({
      query: (body) => ({
        url: injectParams(
          joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.LANGUAGES, ENDPOINTS.BY_ID(body.id)]),
          {
            _method: 'PATCH',
          },
        ),
        method: 'POST',
        body: encodeCreateLanguageRequest(body),
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Languages', 'Keys']),
    }),
    // Export Translation Keys
    exportTranslation: builder.query<string, number>({
      query: (platformId) => ({
        url: injectParams(joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.EXPORT_DATA]), {
          platform_id: platformId,
        }),
        responseHandler: (response) => response.blob().then((blob) => URL.createObjectURL(blob)),
      }),
    }),
    // Upload Translation Keys
    uploadTranslation: builder.mutation<void, UploadTranslationRequest>({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.UPLOAD_DATA]),
        method: 'POST',
        body: encodeUploadTranslationRequest(body),
      }),
    }),
    // Sync Translation Data
    syncTranslationData: builder.mutation<void, number>({
      query: (platformId) => ({
        url: joinPaths([ENDPOINTS.TRANSLATIONS, ENDPOINTS.SYNC_DATA]),
        method: 'POST',
        body: { platform_id: platformId },
      }),
    }),
    // Get the status of the sync of the translation data
    getBackgroundSyncStatus: builder.query<GetBackgroundSyncStatusResponse, number>({
      query: (platformId) => ({
        url: joinPaths([
          ENDPOINTS.TRANSLATIONS,
          ENDPOINTS.PLATFORMS,
          ENDPOINTS.BY_ID(platformId),
          ENDPOINTS.STATUS,
        ]),
      }),
    }),
  }),
});

export const {
  useGetAllPlatformsQuery,
  useCreatePlatformMutation,
  useDeletePlatformMutation,
  useUpdatePlatformMutation,
  useCreateSectionMutation,
  useDeleteSectionMutation,
  useUpdateSectionMutation,
  useCreateGroupMutation,
  useCreateSubGroupMutation,
  useDeleteGroupMutation,
  useUpdateGroupMutation,
  useGetAllLanguagesQuery,
  useGetKeysQuery,
  useLazyGetKeysQuery,
  useCreateKeyMutation,
  useDeleteKeyMutation,
  useUpdateKeyMutation,
  useCreateLanguageMutation,
  useUpdateLanguageMutation,
  useDeleteLanguageMutation,
  useLazyExportTranslationQuery,
  useUploadTranslationMutation,
  useExportTranslationQuery,
  useSyncTranslationDataMutation,
  useGetBackgroundSyncStatusQuery,
  useLazyGetBackgroundSyncStatusQuery,
} = translationApi;
