export const coaching = {
  privateTraining: {
    title: 'Entrainement privé',
  },
  publicTraining: {
    title: 'Cours collectif',
  },
  availabilities: {
    title: 'Disponibilités',
    configuration: 'Configuration disponibilités',
    configure_availabilities: 'Configurez vos disponibilités',
    configure_availabilities_description:
      'Déterminez les plages sur lesquelles vous serez disponible sur les prochains jours pour les entrainements privés',
    configure_availabilities_label: 'Vos clients peuvent réserver avec vous pour les :',
    modify_availability_date_label:
      'Ajoutez les dates où votre disponibilité change par rapport à vos heures hebdomadaires',
    you_want_to_select_course_before_or_after:
      'Vous souhaitez ajouter du temps avant ou après vos cours ?',
    before_course: 'Avant le cours',
    after_course: 'Après le cours',
    time_adjustment_preview_description:
      'Accordez-vous une marge de temps pour vous préparer ou pour conclure les événements réservés sur ASC.',
  },
};
