export const page = {
  go_mui_components_dashboard: 'Go to MUI Components Dashboard',
  card: 'Cards',
  typography: 'Typography',
  color: 'Colors',
  button: 'Buttons',
  shadow: 'Shadows',
  dashboard: 'Tableau de bord',
  coach: 'Coaches',
  users: 'Utilisateurs',
  reviews: 'Les Avis',
  accounts: 'Comptes',
  gym: 'Nos salles',
  exercises: 'Les excercices',
  marketing: 'Marketing',
  statistics: 'Statistiques',
  translations: 'Traductions',
  add_account: 'Ajouter un compte',
  add_room: 'Ajouter un lieu',
  title: {
    account: 'Liste des comptes',
  },
  roomFilter: {
    category: 'Catégorie',
    allCategories: 'Toutes les catégories',
    sessionType: 'Type de cours',
    privateSession: 'Entrainement privé',
    publicSession: 'Cours collectif',
  },

  subtitle: {
    account: 'Créer et gérer les comptes',
  },
  alt: {
    room: 'Image de la salle',
    service: 'Image du service',
  },
  salles: {
    add_supplement_amount: 'Ajouter un montant supplémentaire pour ce lieu ?',
    service_available: 'Disponible sur place',
    availability: 'Les disponibilités dans cette salle',
  },
};
