import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import Paginator from 'types/interfaces/Paginator';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { joinPaths } from 'utils/helpers';
import { injectParams } from 'utils/helpers/api-url';
import { paginatorToApiPagination } from 'utils/services/api.service';
import {
  transformBoostingApiOptions,
  transformBoostingListResponse,
  transformBoostingResponse,
} from './boostingApi.transform';
import { BoostingAPI, BoostingOptions } from './boostingApi.type';

export const boostingApi = createApi({
  reducerPath: 'boostingApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['boosting'],
  endpoints: (builder) => ({
    getBoostingList: builder.query({
      query: (params: { paginator?: Paginator; options?: BoostingOptions }) => {
        return injectParams(ENDPOINTS.BOOSTING, {
          ...transformBoostingApiOptions(params?.options),
          ...paginatorToApiPagination(params.paginator),
        });
      },
      transformResponse: (response: ApiArrayDataResponse<BoostingAPI>) => {
        return transformBoostingListResponse(response);
      },
      providesTags: ['boosting'],
    }),
    createBoosting: builder.mutation({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.BOOSTING]),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['boosting'],
    }),
    getBoostingById: builder.query({
      query: (id: string) => `${ENDPOINTS.BOOSTING}/${id}`,
      transformResponse: (response: { data: BoostingAPI }) => {
        return transformBoostingResponse(response.data);
      },
      providesTags: ['boosting'],
    }),
    updateBoosting: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `${ENDPOINTS.BOOSTING}/${id}`,
          method: 'PUT',
          body: body,
        };
      },
      invalidatesTags: ['boosting'],
    }),
    deleteBoosting: builder.mutation({
      query: (body) => {
        return {
          url: ENDPOINTS.DELETE_BOOSTING,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['boosting'],
    }),
  }),
});

export const {
  useGetBoostingListQuery,
  useCreateBoostingMutation,
  useDeleteBoostingMutation,
  useGetBoostingByIdQuery,
  useUpdateBoostingMutation,
} = boostingApi;
