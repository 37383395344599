export const placesFilters = {
  course_type: 'Type de cours',
  private_training: 'Entraînement privé',
  group_training: 'Cours collectif',
  typology: 'Typologie',
  salle: 'Salle',
  public_space: 'Parc',
  country: 'Pays',
  france: 'France',
  belgique: 'Belgique',
  allemagne: 'Allemagne',
  'royaume-_uni': 'Royaume-Uni',
  canada: 'Canada',
  cities: 'Villes',
  paris: 'Paris',
  tours: 'Tours',
  munich: 'Munich',
  strasbourg: 'Strasbourg',
  londres: 'Londres',
  capacities: 'Capacités',
  less_than5: 'Moins de 5',
  between5_and10: 'Entre de 5 et 10',
  between11_and20: 'Entre 11 et 20',
  between21_and50: 'Entre 21 et 50',
  more_than50: 'Plus de 50',
  provision_of_services: 'Mise en disposition',
  body_building_equipment: 'Matériel de musculation',
  body_building_machine: 'Machine de musculation',
  shower: 'Douche',
  towels: 'Serviettes',
  swimming_pool: 'Piscine',
  lockers: 'Casiers',
  sauna: 'Sauna',
  boxing_equipment: 'Matériel de boxe',
  cardio_training_equipment: 'Matériel de cardio training',
  street_workout_area: 'Espace street workout',
  hammam: 'Hammam',
  pilates_equipment: 'Matériel de pilâtes',
  measuring_tools: 'Outils de mesure',
  yoga_equipment: 'Matériel de yoga',
};
