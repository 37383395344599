import { ReactComponent as AccountIcon } from 'assets/icons/account.svg';
import { ReactComponent as MarketingIcon } from 'assets/icons/campaign.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as ExcercicesIcon } from 'assets/icons/excercice.svg';
import { ReactComponent as ReviewIcon } from 'assets/icons/like.svg';
import { ReactComponent as SallesIcon } from 'assets/icons/location_fav.svg';
import { ReactComponent as CoachIcon } from 'assets/icons/mma.svg';
import { ReactComponent as UserIcon } from 'assets/icons/peoples.svg';
import { ReactComponent as TranslationIcon } from 'features/Translation/assets/icons/translation.svg';
import { BORouterPaths, GlobalVariables } from 'config/constant';
import { NavGroup } from 'types/interfaces/NavItem';
// TODO : add icons stats and comptes
export const navConfig: NavGroup[] = [
  {
    order: 1,
    items: [
      {
        order: 1,
        title: 'page.dashboard',
        path: BORouterPaths.dashboardPath,
        icon: <DashboardIcon />,
        role: GlobalVariables.ROLES.ALL,
      },
    ],
  },
  {
    order: 2,
    items: [
      {
        order: 1,
        title: 'page.coach',
        path: BORouterPaths.coachesPath,
        icon: <CoachIcon />,
        role: GlobalVariables.ROLES.ALL,
      },
      {
        order: 2,
        title: 'page.users',
        path: BORouterPaths.usersPath,
        icon: <UserIcon />,
        role: GlobalVariables.ROLES.ALL,
      },
      {
        order: 3,
        title: 'page.reviews',
        path: BORouterPaths.reviewsPath,
        icon: <ReviewIcon />,
        role: GlobalVariables.ROLES.ALL,
      },
    ],
  },
  {
    order: 3,
    items: [
      {
        order: 1,
        title: 'page.accounts',
        path: BORouterPaths.accountsPath,
        icon: <AccountIcon />,
        role: GlobalVariables.ROLES.ADMIN,
      },
      {
        order: 2,
        title: 'page.gym',
        path: BORouterPaths.gymPath,
        icon: <SallesIcon />,
        role: GlobalVariables.ROLES.ALL,
      },
      {
        order: 3,
        title: 'page.exercises',
        path: BORouterPaths.exercisesPath,
        icon: <ExcercicesIcon />,
        role: GlobalVariables.ROLES.ALL,
      },
      {
        order: 4,
        title: 'page.marketing',
        path: BORouterPaths.marketingPath,
        icon: <MarketingIcon />,
        role: GlobalVariables.ROLES.ALL,
      },
      // TODO: hide this sections
      // {
      //   order: 5
      //   title: 'page.statistics',
      //   path: BORouterPaths.statisticsPath,
      //   icon: <StatistiqueIcon />,
      // },
      // TODO: hide this section for client delivery
      {
        order: 6,
        title: 'page.translations',
        path: BORouterPaths.translationsPath,
        icon: <TranslationIcon />,
        role: GlobalVariables.ROLES.ALL,
      },
    ],
  },
];
export const DRAWER_WIDTH = 225;
export const DRAWER_WIDTH_TABLET = 75;
