import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { baseQueryWithReAuth } from '../BaseQueryConfig';
import {
  transformAccountResponse,
  transformAccountsResponse,
  transformCreateReservationApiBody,
  transformReservationApiBody,
} from './accountsApi.transform';
import { AccountsApi, ReservationApiResponse } from './accountsApi.type';
import { injectParams } from 'utils/helpers/api-url';
import { joinPaths } from 'utils/helpers/path.helpers';
import { CreateReservationBody, PostReservationBody } from 'types/models/Reservation/Reservation';

// TODO:  remove this part after refactoring account module
type CreateReservationResponse = {
  amount_gross: number;
  amount_net: number;
};

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: (args, api, extraOptions) => baseQueryWithReAuth(args, api, extraOptions),
  tagTypes: ['accounts', 'account'],
  endpoints: (builder) => ({
    getAccounts: builder.query({
      query: (params: { paginator?: Paginator; options?: { all: number } }) =>
        injectParams(joinPaths([ENDPOINTS.BO, ENDPOINTS.ACCOUNTS.GET]), {
          ...params.options,
          ...paginatorToApiPagination(params.paginator),
        }),
      transformResponse: (response: ApiArrayDataResponse<AccountsApi>) => {
        return transformAccountsResponse(response);
      },
      providesTags: ['accounts'],
    }),

    createAccount: builder.mutation({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.BO, ENDPOINTS.ACCOUNTS.CREATE]),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['accounts'],
    }),
    getAccountById: builder.query({
      query: (id: string) => joinPaths([ENDPOINTS.BO, ENDPOINTS.ACCOUNTS.GETBYID(id)]),
      transformResponse: (response: { data: AccountsApi }) => {
        return transformAccountResponse(response.data);
      },
      keepUnusedDataFor: 0,
      providesTags: ['account'],
    }),
    updateAccount: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: joinPaths([ENDPOINTS.BO, ENDPOINTS.ACCOUNTS.UPDATE(id)]),
          method: 'PATCH',
          body: body,
        };
      },
      transformResponse: (response: { data: AccountsApi }) =>
        transformAccountResponse(response.data),
      invalidatesTags: ['accounts'],
    }),
    deleteMultipleAccounts: builder.mutation({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.BO, ENDPOINTS.ACCOUNTS.DELETE]),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['accounts'],
    }),

    // TODO:  remove this part after refactoring account module
    getReservationsById: builder.query({
      query: ({ id }) => `${ENDPOINTS.RESERVATIONS_TIME_SLOTS}/${id}`,
      transformResponse: (response: { data: ReservationApiResponse }) => {
        return transformReservationApiBody(response.data);
      },
      providesTags: ['accounts'],
    }),
    cancelReservations: builder.mutation({
      query: (body: { ids: number[] }) => ({
        url: ENDPOINTS.RESERVATIONS_CANCEL,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['accounts'],
    }),
    exportReservations: builder.mutation({
      query: (body: { ids: number[] }) => ({
        url: ENDPOINTS.RESERVATIONS_EXPORT,
        method: 'POST',
        body,
      }),
    }),
    calculateReservation: builder.mutation({
      query: (body: PostReservationBody) => ({
        url: ENDPOINTS.RESERVATIONS_CALCULATE,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateReservationResponse) => ({
        amountGross: response.amount_gross,
        amountNet: response.amount_net,
      }),
    }),
    createReservation: builder.mutation({
      query: (body: CreateReservationBody) => ({
        url: ENDPOINTS.RESERVATIONS,
        method: 'POST',
        body: transformCreateReservationApiBody(body),
      }),
    }),
  }),
});
// TODO: -------------------------------------------------------

export const {
  useGetAccountsQuery,
  useCreateAccountMutation,
  useGetAccountByIdQuery,
  useUpdateAccountMutation,
  useDeleteMultipleAccountsMutation,
  useCreateReservationMutation,
  useCancelReservationsMutation,
  useExportReservationsMutation,
  useCalculateReservationMutation,
  useGetReservationsByIdQuery,
} = accountsApi;
