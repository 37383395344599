import { Box } from '@mui/material';
import { Theme, alpha, styled } from '@mui/material/styles';
import { AppPaletteMode } from 'config/enums/app.enum';
import { DRAWER_WIDTH, DRAWER_WIDTH_TABLET } from './SideBar.constants';
import bgSidebar from 'assets/images/bgSidebar.png';

export const RootStyle = styled(Box)(({ open }: { open?: boolean }) => ({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    width: open ? DRAWER_WIDTH : DRAWER_WIDTH_TABLET,
    transition: 'width 0.3s linear',
  },

  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

export const getDrawerPaperStyle = (theme: Theme) => ({
  boxShadow: `0px 0px 89px ${
    theme.palette.mode === AppPaletteMode.Light ? theme.palette.grey[300] : theme.palette.grey[700]
  }`,
  borderRadius: '0px 10px 10px 0px',
  borderRight: 'none',
  background: `transparent linear-gradient(180deg, ${
    theme.palette.mode === AppPaletteMode.Light ? theme.palette.grey[50] : theme.palette.grey[900]
  } 0%, 
    ${
      theme.palette.mode === AppPaletteMode.Light
        ? theme.palette.grey[100]
        : theme.palette.grey[800]
    } 100%)`,
});

export const BoxStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  backgroundImage: `linear-gradient(
          rgba(255, 255, 255, 0.8)
          ,${alpha(theme.palette.grey[500], 0.05)} 40%
          ), url(${bgSidebar})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'cover',
}));
