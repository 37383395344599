import { RouterPaths } from 'config/constant';
import useLoggedIn from 'hooks/useLoggedIn';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

function AuthGuard(props: Props) {
  const { children } = props;

  const isLoggedIn = useLoggedIn();

  if (!isLoggedIn) {
    return <Navigate to={RouterPaths.AuthPaths.loginPath} replace />;
  }

  return <>{children}</>;
}

export default AuthGuard;
