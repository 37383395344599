import { CONFIG, GlobalVariables } from 'config/constant';
import { isString } from 'utils/validators/input.validators';
import { AES, enc } from 'crypto-js';

/**
 * Save a data to local storage by a key
 * @param key string,
 * @param data unknown
 * @returns void
 */
export const persistData = (key: string, data: unknown) => {
  const dataString: string = isString(data) ? (data as string) : JSON.stringify(data);
  localStorage.setItem(key, encryptData(dataString));
};
export const encryptData = (data: string) => {
  return AES.encrypt(data === null ? '' : data, CONFIG.AUTH_SECURITY_KEY).toString();
};
/**
 * Decrypt data
 * @param encryptedData string
 * @returns string
 */
export const deCryptData = (encryptedData: string) => {
  try {
    return AES.decrypt(
      encryptedData === null ? '' : encryptedData,
      CONFIG.AUTH_SECURITY_KEY,
    ).toString(enc.Utf8);
  } catch (error) {
    // clearLocalStorage();
  }
};
// Extract data from local storage buy a key
/**
 * return a data from local storage by a key
 * if parse is true, data will be parsed to JSON
 * @param key string
 * @param parse boolean ,
 * @returns string | object
 */
export const getPersistData = (key: string, parse?: boolean) => {
  return parse
    ? JSON.parse(deCryptData(localStorage.getItem(key) ?? '') || GlobalVariables.EmptyJsonString)
    : deCryptData(localStorage.getItem(key) || GlobalVariables.EmptyString);
};

// Remove data from the local storage buy a key
export const removePersistData = (key: string) => {
  localStorage.removeItem(key);
};

// Transfer date to a date string
export const dateToString = (date: Date) => {
  return date.toISOString().split('T')[0];
};

// Get a character buy his ASCII code
export const indexToAlphabet = (index: number) => {
  return String.fromCharCode(65 + index);
};

// Replace a long String buy ...
export const toShortUrl = (url: string) => {
  const newUrl = url.replace('https://', '').replace('http://', '');
  return newUrl.length > 25 ? newUrl.substring(0, 25) + '...' : newUrl;
};

// Extract the common numbers between two arraysi
export const findCommonElements = (arr1: number[], arr2: number[]) => {
  return arr1.filter((n) => arr2.includes(n));
};
