import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppPaletteMode } from 'config/enums/app.enum';
import { AppThemes } from 'config/enums/theme.enum';
import { RootState } from 'redux/store';
import { ESnackBarSeverity, ISnackBar } from 'types/interfaces/SnachBar';

interface AppState {
  mode: AppPaletteMode;
  theme: AppThemes;
  navBarConfig: {
    active?: boolean;
    title?: string;
    subtitle?: string;
    searchText: string;
    searchPlaceholder?: string;
  };

  snackBarConfig: ISnackBar;
}

const initialState: AppState = {
  mode: AppPaletteMode.Light,
  theme: AppThemes.EPR,
  navBarConfig: {
    searchText: '',
  },
  snackBarConfig: {
    open: false,
    message: '',
    title: '',
    severity: ESnackBarSeverity.INFO,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    switchMode: (state) => {
      // NOTE: Replace this with a switch statement if you have more than 2 modes
      state.mode = state.mode === AppPaletteMode.Light ? AppPaletteMode.Dark : AppPaletteMode.Light;
    },
    setSearchText: (state, action) => {
      state.navBarConfig.searchText = action.payload;
    },
    setNavBarConfig: (state, action) => {
      state.navBarConfig = action.payload;
    },
    setSearchPlaceholder: (state, action) => {
      state.navBarConfig.searchPlaceholder = action.payload;
    },
    resetNavBarConfig: (state) => {
      state.navBarConfig = initialState.navBarConfig;
    },

    setSnackBarConfig: (state, action) => {
      state.snackBarConfig = action.payload;
    },
    changeTheme: (state, action: PayloadAction<{ theme: AppThemes }>) => {
      const { theme } = action.payload;
      if (state.mode === AppPaletteMode.Light) {
        switch (theme) {
          case AppThemes.PUBLIC:
            state.theme = AppThemes.PUBLIC;
            break;
          case AppThemes.SP:
            state.theme = AppThemes.SP;
            break;
          case AppThemes.EPR:
            state.theme = AppThemes.EPR;
            break;
          case AppThemes.SR:
            state.theme = AppThemes.SR;
            break;
          case AppThemes.VOD:
            state.theme = AppThemes.VOD;
            break;
          default:
            state.theme = AppThemes.EPR;
            break;
        }
      } else {
        switch (theme) {
          case AppThemes.PUBLIC:
            state.theme = AppThemes.PUBLIC_DARK;
            break;
          case AppThemes.SP:
            state.theme = AppThemes.SP_DARK;
            break;
          case AppThemes.EPR:
            state.theme = AppThemes.EPR_DARK;
            break;
          case AppThemes.SR:
            state.theme = AppThemes.SR_DARK;
            break;
          case AppThemes.VOD:
            state.theme = AppThemes.VOD_DARK;
            break;
          default:
            state.theme = AppThemes.EPR_DARK;
            break;
        }
      }
    },
  },
});

export const {
  switchMode,
  setSearchText,
  setNavBarConfig,
  resetNavBarConfig,
  setSnackBarConfig,
  setSearchPlaceholder,
  changeTheme,
} = appSlice.actions;
export default appSlice.reducer;
export const GetSnackConfig = (state: RootState) => state.appReducer.snackBarConfig;
export const GetSearchText = (state: RootState) => state.appReducer.navBarConfig.searchText;
