import { Grid } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import MobileNavbar from './MobileNavbar/MobileNavbar';
import {
  ContentStyle,
  DesktopNavbarStyle,
  MobileNavbarStyle,
  RootStyle,
  ToolbarStyle,
} from './Navbar.style';
import PageTitle from './PageTitle/PageTitle';
import ProfileBox from './ProfileBox/ProfileBox';
import SearchBox from './SearchBox/SearchBox';

export default function Navbar({ isMini }: { isMini: boolean }) {
  const config = useAppSelector((state) => state.appReducer.navBarConfig);
  const user = useAppSelector((state) => state.authReducer.user);
  const profilePicture = useAppSelector((state) => state.authReducer.profilePicture);

  return (
    <RootStyle open={!isMini}>
      <ToolbarStyle>
        {/* Mobile navbar */}
        <MobileNavbarStyle>
          <MobileNavbar />
        </MobileNavbarStyle>

        {/* Desktop navbar */}
        <DesktopNavbarStyle>
          <ContentStyle container alignItems="center">
            <Grid item xs={12} md={5} lg={4}>
              <PageTitle title={config.title} subtitle={config.subtitle} />
            </Grid>
            <Grid item xs={12} md={5} lg={4} sx={{ display: 'flex' }} justifyContent={'center'}>
              <SearchBox
                searchPlaceholder={config.searchPlaceholder}
                searchText={config.searchText}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={4} sx={{ display: 'flex' }} justifyContent={'end'}>
              <ProfileBox
                profilePicture={(profilePicture && profilePicture.url) || ''}
                name={`${user?.firstName} ${user?.lastName}`}
              />
            </Grid>
          </ContentStyle>
        </DesktopNavbarStyle>
      </ToolbarStyle>
    </RootStyle>
  );
}
