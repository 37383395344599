import { ApiCoach } from './coachApi.types';
import { Coach } from 'features/Coaches/CoachesTable/CoacheTable.type';

export const mapCoaches = (apiCoach: ApiCoach): Coach => {
  return {
    id: apiCoach.id,
    firstName: apiCoach.first_name,
    lastName: apiCoach.last_name,
    gender: apiCoach.gender,
    photo: apiCoach.profile_picture,
    isActive: true,
    createdAt: apiCoach.created_at,
    acceptedAt: apiCoach?.accepted_at,
    lastConnectionAt: apiCoach.last_connection_at,
    accountState: apiCoach.account_state,
    expertInChargeFirstName: apiCoach.expert?.first_name,
    expertInChargeLastName: apiCoach.expert?.last_name,
    refusedDate: apiCoach?.refusal_date,
  };
};
