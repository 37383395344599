import { ReactComponent as ASCLogo } from 'assets/icons/asc_full_logo.svg';
import { ReactComponent as ASCMiniLogo } from 'assets/icons/asc_mini_logo.svg';
import { RouterPaths } from 'config/constant';
import useResponsive from 'hooks/useResponsive';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResponsiveQueryEnum } from 'types/interfaces/ResponsiveQuery';
import { RootStyle } from './LogoSection.style';
import { LogoSectionProps } from './LogoSection.type';

function LogoSection({ isMini }: LogoSectionProps) {
  const navigate = useNavigate();

  const isDesktop = useResponsive(ResponsiveQueryEnum.DOWN, 'lg');
  const isTablet = useResponsive(ResponsiveQueryEnum.UP, 'sm');

  const onNavigateHome = () => {
    navigate(RouterPaths.RootPaths.rootPath);
  };

  return (
    <RootStyle
      direction="row"
      spacing={4}
      justifyContent="center"
      alignItems="center"
      onClick={onNavigateHome}
    >
      {(!isDesktop && isTablet) || !isMini ? <ASCLogo /> : <ASCMiniLogo />}
    </RootStyle>
  );
}

export default memo(LogoSection);
