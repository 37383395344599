import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { AffirmativeResponse } from 'config/enums/common.enums';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import {
  transformLevelRequest,
  transformLevelsListResponse,
} from 'redux/api/user/UserForm/LevelUserForm/levelFormApi.transform';
import {
  ApiLevelType,
  LevelFormRequest,
} from 'redux/api/user/UserForm/LevelUserForm/levelFormApi.type';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { injectParams } from 'utils/helpers';

export const LevelFormApi = createApi({
  reducerPath: 'LevelsFromApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['LevelsFromApi'],
  endpoints: (builder) => ({
    getLevels: builder.query({
      query: () =>
        injectParams(ENDPOINTS.LISTE_OF_LEVELS, {
          pagination: AffirmativeResponse.No,
        }),

      transformResponse: (response: ApiArrayDataResponse<ApiLevelType>) => {
        return transformLevelsListResponse(response);
      },
      providesTags: ['LevelsFromApi'],
    }),
    createLevel: builder.mutation({
      query: (body: LevelFormRequest) => ({
        url: ENDPOINTS.ADD_LEVEL,
        method: 'POST',
        body: transformLevelRequest(body),
      }),
      invalidatesTags: ['LevelsFromApi'],
    }),
    deleteLevel: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS.DELETE_LEVEL.replace(':id', id)}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LevelsFromApi'],
    }),
    updateLevel: builder.mutation({
      query: (body: LevelFormRequest) => ({
        url: `${ENDPOINTS.UPDATE_LEVEL.replace(':id', String(body.id))}`,
        method: 'PATCH',
        body: transformLevelRequest(body),
      }),
      invalidatesTags: ['LevelsFromApi'],
    }),
  }),
});

export const {
  useGetLevelsQuery,
  useCreateLevelMutation,
  useDeleteLevelMutation,
  useUpdateLevelMutation,
} = LevelFormApi;
