import { transformApiResponse } from 'redux/api/BaseApiTransform';
import { mapGroupOfCoachs } from 'redux/api/coach/groupOfCoaches/GroupApi.decoder';
import {
  ApiGroupResponse,
  Coach,
  GetGroupOfCoach,
  GetGroupOfCoachResponse,
  GroupDetails,
  GroupOptions,
} from 'redux/api/coach/groupOfCoaches/groupOfCoachesApi.types';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';

export const transformGroupsApiOptions = (options: GroupOptions) => {
  return {
    alphabet: options.alphabet,
    start_date: options?.startDate,
    end_date: options?.endDate,
  };
};

export const transformGroupResponse = (
  response: ApiArrayDataResponse<ApiGroupResponse>,
): ArrayDataResponse<GroupDetails> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map(mapGroupOfCoachs),
  };
};

export const transformGroupOfCoaches = (data: GetGroupOfCoachResponse): GetGroupOfCoach => {
  const transformedCoaches = data?.coaches?.map((coach) => {
    return {
      id: coach.id,
      firstName: coach.first_name,
      lastName: coach.last_name,
      email: coach.email,
    };
  });

  return {
    id: data.id,
    name: data.name,
    status: data.status,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    GroupOfCoaches: transformedCoaches,
  };
};
