import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { CONFIG } from 'config/config';
import { GlobalVariables } from 'config/constant';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { STORAGE_KEYS } from 'config/constant/storage.config';
import i18n from 'locales/i18n';
import { resetUser, setRefreshToken, setToken } from 'redux/slices/Auth/authSlice';
import { RootState } from 'redux/store';
import { getPersistData } from 'utils';
import {
  saveRefreshTokenToLocalStorage,
  saveTokenToLocalStorage,
} from 'utils/services/storage.service';

const mutex = new Mutex();

export const baseQueryConfig: FetchBaseQueryArgs = {
  baseUrl: CONFIG.BASE_URL_API,
  prepareHeaders: (headers: Headers, { getState }) => {
    const token = (getState() as RootState).authReducer.token;
    headers.set('apiKey', CONFIG.API_KEY);

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
      headers.set('lang', i18n.language);
    }
    return headers;
  },
};
export const baseQueryRefreshTokenConfig: FetchBaseQueryArgs = {
  baseUrl: CONFIG.BASE_URL_API,
  prepareHeaders: (headers: Headers, { getState }) => {
    const token = (getState() as RootState).authReducer.refreshToken;
    headers.set('apiKey', CONFIG.API_KEY);
    headers.set('lang', i18n.language);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
};

const baseQuery = fetchBaseQuery(baseQueryConfig);
const refreshBaseQuery = fetchBaseQuery(baseQueryRefreshTokenConfig);

// NOTE - use baseQueryWithReAuth for ready to use apis
export const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);
  // check if token has expired (api call unauthorized)
  if (result.error && result.error.status === 401) {
    // check if remember me has been checked
    if (getPersistData(STORAGE_KEYS.REMEMBER_ME) === GlobalVariables.TRUE) {
      // if remember me has been checked, call refresh token api
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResult = await refreshBaseQuery(
            {
              url: ENDPOINTS.REFRESH_TOKEN,
              method: 'POST',
            },
            api,
            extraOptions,
          );
          if (refreshResult.data) {
            const { data } = refreshResult.data as unknown as {
              data: { token: string; refresh_token: string };
            };
            saveTokenToLocalStorage(data.token);
            saveRefreshTokenToLocalStorage(data.refresh_token);
            api.dispatch(
              setToken({
                token: data.token,
              }),
            );
            api.dispatch(setRefreshToken({ refreshToken: data.refresh_token }));
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
          } else {
            // if refresh token api has failed for some reason, remove user data
            api.dispatch(resetUser());
          }
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    } else {
      // if remember me has not been checked, remove user data
      api.dispatch(resetUser());
    }
  }

  return result;
};
