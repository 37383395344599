import { transformApiResponse } from 'redux/api/BaseApiTransform';
import { transformApiMediaType } from 'redux/api/commonTypes/file';
import {
  ApiProduct,
  Product,
  ProductType,
  ProductsOptions,
} from 'redux/api/products/productsApi.type';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';

export const transformProductsApiOptions = (options?: ProductsOptions) => {
  return {
    start_date: options?.startDate,
    end_date: options?.endDate,
    product_type: options?.type,
  };
};

export const transformProductsResponse = (
  response: ApiArrayDataResponse<ApiProduct>,
): ArrayDataResponse<ProductType> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((product) => {
      return {
        id: product.id,
        type: product.product_type,
        coach: {
          id: product?.coach?.id,
          firstName: product?.coach?.first_name,
          lastName: product?.coach?.last_name,
          photo: product?.coach?.profile_picture,
        },
        coachId: product.coach_id,
        productName: product.name,
        createdAt: product.created_at,
      };
    }),
  };
};
export const transformDetailProductResponse = (response: ApiProduct): Product => {
  return {
    id: response.id,
    productType: response.product_type,
    coach: {
      id: response?.coach?.id,
      firstName: response?.coach?.first_name,
      lastName: response?.coach?.last_name,
      profilePicture: response?.coach?.profile_picture,
    },
    coachId: response.coach_id,
    photo: transformApiMediaType(response.image),
    photoId: response.image_id,
    description: response.description,
    name: response.name,
    createdAt: response.created_at,
  };
};
