import { Theme, alpha } from '@mui/material';

export default function Skeleton(theme: Theme) {
  return {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.primary.dark, 0.15),
        },
      },
    },
  };
}
