import { LanguageType } from 'types/models/common/LanguageType/LanguageType';
import { ApiLanguagesResponse } from './languageApi.type';

export const transformLanguages = (response: ApiLanguagesResponse): LanguageType[] => {
  return response.data.map((language) => ({
    id: language.id,
    name: language.name,
    countryCode: language.country_code,
    flag: language.flag ?? undefined,
  }));
};
