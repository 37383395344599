import {
  APP_BAR_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_TABLET,
} from 'layouts/DashboardLayout/DashboardLayout.constants';
import { DRAWER_WIDTH as SIDE_BAR_DRAWER_WIDTH } from 'layouts/DashboardLayout/SideBar/SideBar.constants';
import { DRAWER_WIDTH_TABLET as SIDE_BAR_DRAWER_WIDTH_TABLET } from 'layouts/DashboardLayout/SideBar/SideBar.constants';
export const DRAWER_WIDTH = SIDE_BAR_DRAWER_WIDTH;
export const DRAWER_WIDTH_TABLET = SIDE_BAR_DRAWER_WIDTH_TABLET;
export const APP_MOBILE = APP_BAR_MOBILE;
export const APP_DESKTOP = APP_BAR_DESKTOP;
export const APP_TABLET = APP_BAR_TABLET;
