import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { ApiArrayDataResponse, ClientResponse } from 'types/models/ApiResponse';
import { injectName, injectPaginator } from 'utils/services/api.service';
import { baseQueryConfig } from '../BaseQueryConfig';
import {
  transformClientResponse,
  transformClientsCoachResponse,
  transformClientsResponse,
} from './clientApi.transform';

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: fetchBaseQuery(baseQueryConfig),
  tagTypes: ['Clients'],
  endpoints: (builder) => ({
    // Get all demands list
    getAllCoachClients: builder.query({
      query: (paginator: Paginator) =>
        `${injectName(injectPaginator(ENDPOINTS.CLIENTS, paginator), paginator.searchText, true)}`,
      transformResponse: (response: ApiArrayDataResponse) => transformClientsResponse(response),
      providesTags: ['Clients'],
    }),
    // Get all clients
    getAllClients: builder.query({
      query: (paginator: Paginator) =>
        `${injectName(
          injectPaginator(ENDPOINTS.MY_CLIENTS, paginator),
          paginator.searchText,
          true,
        )}`,
      transformResponse: (response: ApiArrayDataResponse) =>
        transformClientsCoachResponse(response),
      providesTags: ['Clients'],
    }),
    // Get one demand info by its ID
    // Create client by a coach
    createClient: builder.mutation({
      query: ({ firstName, lastName, phone, email }) => ({
        url: `${ENDPOINTS.COACHES}${ENDPOINTS.CLIENTS}`,
        method: 'POST',
        body: { first_name: firstName, last_name: lastName, phone, email },
      }),
      transformResponse: (Response: ClientResponse) => transformClientResponse(Response),
    }),
    deleteClient: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS.COACHES}${ENDPOINTS.CLIENTS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Clients'],
    }),
  }),
});

export const {
  useGetAllCoachClientsQuery,
  useGetAllClientsQuery,
  useCreateClientMutation,
  useDeleteClientMutation,
} = clientApi;
