import { Box, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

export const MenuStyle = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '5px',
    boxShadow: `0px 13px 45px  ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.background.paper,
  },
  ul: {
    padding: '0px !important',
  },
}));

export const MenuItemStyle = styled(MenuItem)(({ theme, selected }) => ({
  padding: 9,
  paddingInline: 16,
  width: '100%',
  color: selected ? theme.palette.text.primary : theme.palette.grey[500],
  whiteSpace: 'normal',
  ':hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
  },
  minWidth: 120,
}));

export const DividerStyle = styled(Box)(({ theme }) => ({
  margin: '0px !important',
  width: '90%',
  height: 0,
  border: `1px solid ${theme.palette.grey[200]}`,
}));
