import { Grid } from '@mui/material';
import { ReactComponent as ASCLogo } from 'assets/icons/asc_full_logo.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu_medium.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/mins.svg';
import { SVGContainer } from 'components/Containers/SvgContainer';
import { RouterPaths } from 'config/constant';
import useDialog from 'hooks/useDialog';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import ProfileBox from '../ProfileBox/ProfileBox';
import DrawerMobile from './DrawerMobile/DrawerMobile';
import { BoxStyle, StackStyle } from './MobileNavbar.style';

function MobileNavbar({ onClose }: { onClose?: () => void }) {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.authReducer.user);

  const { isOpen, openDialog: openNavbar, closeDialog: closeNavbar } = useDialog();

  const location = useLocation();

  useEffect(() => {
    closeNavbar();
  }, [location]);

  const navigateToHome = () => {
    navigate(RouterPaths.DashboardPaths.dashboardPath);
  };

  return (
    <StackStyle container alignItems={'center'}>
      <Grid item xs={4}>
        <DrawerMobile open={isOpen} handleDrawerClose={closeNavbar} />
        {onClose ? (
          <BoxStyle onClick={onClose}>
            <CloseIcon />
          </BoxStyle>
        ) : (
          <BoxStyle onClick={openNavbar}>
            <MenuIcon />
          </BoxStyle>
        )}
      </Grid>
      <Grid item xs={4} display="flex" justifyContent={'center'}>
        <SVGContainer onClick={navigateToHome}>
          <ASCLogo width="73px" height="40px" />
        </SVGContainer>
      </Grid>
      <Grid item xs={4} display="flex" justifyContent={'end'}>
        <ProfileBox profilePicture={(user && user.profilePicture) || ''} />
      </Grid>
    </StackStyle>
  );
}
export default MobileNavbar;
