import { ProductTypeEnum } from './product.enums';

export enum CoachingTypeEnum {
  Private = ProductTypeEnum.Private,
  Public = ProductTypeEnum.Public,
  SportProgram = 3,
  VOD = 4,
  SportsRetirement = 5,
  Shop = 6,
}

export enum CoachPaymentTypeEnum {
  Fix = 1,
  Percentage = 2,
}
