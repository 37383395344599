import {
  ApiArrayDataResponse,
  ApiBODataResponse,
  ArrayDataResponse,
} from 'types/models/ApiResponse';
import { transformApiResponse } from '../BaseApiTransform';
import {
  Account,
  AccountsApi,
  ApiTimeLocation,
  CreateReservationApiBody,
  ReservationApiResponse,
  ReservationOptions,
} from './accountsApi.type';
import { toCamelCase } from 'utils/helpers/object.helpers';
import { CoachingSession } from 'types/models/Session/Session';
import { CreateReservationBody, ReservationByTimeSlot } from 'types/models/Reservation/Reservation';

export const transformAccountsResponse = (
  response: ApiArrayDataResponse<AccountsApi>,
): ArrayDataResponse<Account> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((apiAccount) => {
      return {
        id: apiAccount.id,
        firstName: apiAccount.first_name,
        lastName: apiAccount.last_name,
        email: apiAccount.email,
        identifier: apiAccount.identifier,
        roleId: apiAccount.role_id,
        profilePicture: apiAccount.profile_picture,
        coachsAcceptedCount: apiAccount.coaches_accepted_count,
        coachsRefusedCount: apiAccount.coaches_refused_count,
        coachsWaitingCount: apiAccount.coaches_waiting_count,
        totalCoaches: apiAccount.total_coaches,
        createdAt: apiAccount.created_at,
        updatedAt: apiAccount.updated_at,
      };
    }),
  };
};

export const transformAccountResponse = (apiAccount: AccountsApi): Account => {
  return {
    id: apiAccount.id,
    firstName: apiAccount.first_name,
    lastName: apiAccount.last_name,
    email: apiAccount.email,
    identifier: apiAccount.identifier,
    roleId: apiAccount.role_id,
    profilePicture: apiAccount.profile_picture,
    coachsAcceptedCount: apiAccount.coaches_accepted_count,
    coachsRefusedCount: apiAccount.coaches_refused_count,
    coachsWaitingCount: apiAccount.coaches_waiting_count,
    totalCoaches: apiAccount.total_coaches,
    createdAt: apiAccount.created_at,
    updatedAt: apiAccount.updated_at,
  };
};

// TODO:  remove this part after refactoring account module

export const transformCreateReservationApiBody = (
  body: CreateReservationBody,
): CreateReservationApiBody => {
  const { product, users, materials, slots, sendOption, paymentLink, qrCode } = body;
  const timeLocations: ApiTimeLocation[] = slots.map((slot) => ({
    order: slot.order,
    date: slot.date.start,
    location_id: slot.placeId as number,
  }));

  return {
    users,
    product: product as number,
    materials: materials || null,
    time_locations: timeLocations,
    send_method: sendOption,
    send_link: paymentLink ? 1 : 0,
    send_qr_code: qrCode ? 1 : 0,
  };
};

export const transformReservationsApiOptions = (options: ReservationOptions) => {
  return {
    start_date: options.startDate,
    end_date: options.endDate,
    paid_status: options.paidStatus,
    reservation_type: options.reservationType,
    status: options.status,
  };
};

export const transformReservationsApiBody = (response: ApiArrayDataResponse) => {
  const sessions = response.data.map((value) => {
    return {
      ...toCamelCase(value),
      users: toCamelCase(value.users as Record<string, unknown>[]),
    };
  });
  return {
    ...toCamelCase(response),
    data: [...sessions],
  } as unknown as ArrayDataResponse<CoachingSession>;
};

export const transformReservationApiBody = (response: ReservationApiResponse) => {
  const sessions = {
    ...toCamelCase(response),
    reservationWithRelations: {
      ...toCamelCase(response.reservation_with_relations),
      product: toCamelCase(response.reservation_with_relations.product),
      coaches: toCamelCase(response.reservation_with_relations.coaches),
      users: toCamelCase(response.reservation_with_relations.users),
      materials: toCamelCase(response.reservation_with_relations.materials),
      timeSuggestions: toCamelCase(response.time_suggestions),
    },
  };

  return {
    ...sessions,
  } as unknown as ReservationByTimeSlot;
};
// TODO: -----------------------------------------------------

export const transformAccountsApiBodyResponse = (response: ApiBODataResponse) => {
  const dataResponse = response;
  return {
    ...toCamelCase(dataResponse.data),
    data: {
      firstName: dataResponse.data.first_name,
      lastName: dataResponse.data.last_name,
      email: dataResponse.data.email,
      identifier: dataResponse.data.identifier,
      roleID: dataResponse.data.role_id,
      profilePicture: dataResponse.data.profile_picture,
      coachsAcceptedCount: dataResponse.data.coachs_accepted_count,
      coachsRefusedCount: dataResponse.data.coachs_refused_count,
      coachsWaitingCount: dataResponse.data.coachs_waiting_count,
      createdAt: dataResponse.data.created_at,
      updatedAt: dataResponse.data.updated_at,
    },
  };
};
