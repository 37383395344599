import { useEffect } from 'react';
import { useGetLanguagesTranslationsQuery } from 'redux/api/languages/languageApi';
import { useGetAllLanguagesQuery } from 'redux/api/translation/translationApi';
import useErrorAlert from './useErrorAlert';
import i18nInstance from 'locales/i18n';

export default function useInitLanguage() {
  const { data: res, isLoading, error } = useGetLanguagesTranslationsQuery(undefined);
  useGetAllLanguagesQuery();

  useErrorAlert(error);

  useEffect(() => {
    const data = res?.data;
    if (data) {
      Object.keys(data).forEach((lang) => {
        // Remove the lang from the i18nInstance
        i18nInstance.removeResourceBundle(lang, 'translation');

        // Add the new lang to the i18nInstance
        const values = data[lang].translation;
        i18nInstance.addResourceBundle(
          lang,
          'translation',
          { ...values, is_server: 'true', lng: lang },
          true,
          true,
        );
      });

      // Reload the i18nInstance Language
      i18nInstance.changeLanguage(i18nInstance.language);
    }
  }, [res]);

  return {
    isLoading,
    i18nInstance,
  };
}
