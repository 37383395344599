import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { IServiceResponse } from 'types/models/Services/Services.type';

export const servicesApi = createApi({
  reducerPath: 'servicesApi',
  baseQuery: fetchBaseQuery(baseQueryConfig),
  tagTypes: ['servicesApi'],
  endpoints: (builder) => ({
    // Get all demands list
    getServices: builder.query({
      query: () => ENDPOINTS.SERVICES.GET,
      providesTags: ['servicesApi'],
      transformResponse: (response: IServiceResponse) => response.data,
    }),
  }),
});
export const { useGetServicesQuery } = servicesApi;
