export const calendar = {
  add_date: 'Ajouter une date',
  add_the_date: 'Ajouter la date',
  add_date_hint: 'En consultant votre agenda vous pourez ajouter une date',
  modify_a_date: 'Modifier une date',
  modify_a_date_hint: 'Sélectionnez les dates sur lesquelles vous souhaitez adapter vos horaires',
  what_time_are_you_available: 'À quelles heures êtes-vous disponible ?',
  title: 'Agenda',
  subTitle: 'Liste des rendez-vous',
  search: 'Chercher ...',
  select_the_slots: 'Sélectionnez les créneaux',
  add_another_slot: 'Ajouter un autre créneau',
  add_a_time_slot: 'Ajouter une plage horaire',
  copy_hours_in: 'Copier les heures sur...',
  the: 'Le',
  from: 'De',
  from2: 'Du',
  to: 'à',
  duration: 'Durée',

  more_events: '{{number}} de plus',

  upcoming: 'Prochaine',

  before_yesterday: 'Avant-hier',
  yesterday: 'Hier',
  today: "Aujourd'hui",
  tomorrow: 'Demain',
  all_days: 'Toujours',

  week: 'Semaine',
  month: 'Mois',
  day: 'Jour',
  hour: 'Heure',
  minute: 'Minute',
  min: 'min',
  second: 'seconde',

  invalid_slot: "Créneau doit avoir une date et un lieu d'entrainement",

  other_date_proposition: 'Proposez une autre date à votre client',
  suggest_time_slots: 'Proposer les créneaux',

  suggestion: 'Proposition',
  choose_a_date: 'Choisir une date',
  see_my_agenda: 'Voir mon agenda',

  calendar_days: 'Jours calendaires',
  working_days: 'Jours ouvrés',

  time_zone: 'Fuseau horaire',
  time_zones: {
    'Europe/Paris': 'Heure Europe centrale - Paris',
    'Europe/London': 'Heure Europe occidentale - Londres',
    'Europe/Berlin': 'Heure Europe centrale - Berlin',
    'Europe/Podgorica': 'Heure Europe centrale - Podgorica',
    'Europe/Prague': 'Heure Europe centrale - Prague',
    'Europe/Rome': 'Heure Europe centrale - Rome',
    'Europe/San_Marino': 'Heure Europe centrale - Saint-Marin',
    'Europe/Sarajevo': 'Heure Europe centrale - Sarajevo',
    'Europe/Skopje': 'Heure Europe centrale - Skopje',
    'Europe/Amsterdam': 'Heure Europe centrale - Amsterdam',
    'Europe/Copenhagen': 'Heure Europe centrale - Copenhague',
    'Europe/Busingen': 'Heure Europe centrale - Büsingen',
    'Europe/Brussels': 'Heure Europe centrale - Bruxelles',
    'Europe/Monaco': 'Heure Europe centrale - Monaco',
    'Europe/Moscow': "Heure Europe de l'Est - Moscou",
    'Europe/Oslo': "Heure Europe de l'Est - Oslo",
    'America/Adak': "Heure normale d'Hawaï-Aleutien - Adak",
    'America/Anchorage': "Heure normale d'Hawaï-Aleutien - Anchorage",
    'America/Cambridge_Bay': 'Heure normale des Rocheuses - Cambridge Bay',
    'America/Chicago': 'Heure normale du Centre - Chicago',
    'America/Los_Angeles': 'Heure normale du Pacifique - Los Angeles',
    'America/Moncton': "Heure normale de l'Atlantique - Moncton",
    'America/New_York': "Heure normale de l'Est - New York",
    'America/St_Johns': 'Heure normale de Terre-Neuve - Saint-Jean',
    'America/Toronto': "Heure normale de l'Est - Toronto",
    'America/Vancouver': 'Heure normale du Pacifique - Vancouver',
  },
  days: 'Jours',
  // Week days
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  saturday: 'Samedi',
  sunday: 'Dimanche',
};
