import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { CityType, CountryType } from 'types/models/common/CityType/CityType';
import { transformApiResponse } from '../BaseApiTransform';
import { ApiCityType, ApiCountryType } from './citiesApi.type';

export const transformCountriesResponse = (
  response: ApiArrayDataResponse<ApiCountryType>,
): ArrayDataResponse<CountryType> => ({
  ...transformApiResponse(response),
  data: response.data.map((country) => ({
    id: country.id,
    name: country.translated_name,
    translatedName: country.translated_name,
    code: country.code,
  })),
});

export const transformCitiesResponse = (response: ApiCityType[]): { data: CityType[] } => ({
  data: response.map((city) => ({
    id: city.id,
    name: city.name,
  })),
});
