import CustomLoader from 'components/CustomLoader/CustomLoader';
import { useTranslation } from 'react-i18next';
import { useLogoutMutation } from 'redux/api/auth/login/loginApi';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { resetUser } from 'redux/slices/Auth/authSlice';
import { LogoutButtonStyle, RootStyle } from './SwitchPaletteMode.style';
import useClearApiCache from 'hooks/useClearApiCache';
import useResponsive from 'hooks/useResponsive';
import { ResponsiveQueryEnum } from 'types/interfaces/ResponsiveQuery';

function SwitchPaletteMode() {
  const paletteMode = useAppSelector((state) => state.appReducer.mode);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [logout, { isLoading: isLoggingOut }] = useLogoutMutation();
  const { onClearApiCachedData } = useClearApiCache();

  const onSwitchMode = () => {
    // TODO: Activate dark mode switching
    // dispatch(switchMode());
  };

  const isDesktop = useResponsive(ResponsiveQueryEnum.DOWN, 'lg');
  const isTablet = useResponsive(ResponsiveQueryEnum.UP, 'sm');
  const onLogout = async () => {
    try {
      onClearApiCachedData();
      await logout(null).unwrap();
      dispatch(resetUser());
    } catch {
      dispatch(resetUser());
    }
  };

  return (
    <RootStyle justifyContent="center" alignItems="center" spacing={2}>
      {/* 
        TODO: Restore this when working on dark mode
        <MaterialUISwitch onChange={onSwitchMode} checked={!isLight} /> 
      */}
      {isLoggingOut ? (
        <CustomLoader size={5} />
      ) : (
        <LogoutButtonStyle onClick={onLogout}>
          {t(!isDesktop && isTablet ? 'common.auth.logout' : 'common.auth.logout_abbreviation')}
        </LogoutButtonStyle>
      )}
    </RootStyle>
  );
}

export default SwitchPaletteMode;
