import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { ApiCommand, CommandOptions } from './commandApi.type';
import { transformCommandsApiOptions, transformCommandsResponse } from './commandApi.transform';
import { baseQueryWithReAuth } from '../BaseQueryConfig';

export const commandApi = createApi({
  reducerPath: 'commandApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['commands', 'command'],
  endpoints: (builder) => ({
    getCommands: builder.query({
      query: (params: { paginator?: Paginator; options?: CommandOptions }) => {
        return injectParams(ENDPOINTS.COMMANDS, {
          ...transformCommandsApiOptions(params?.options),
          ...paginatorToApiPagination(params.paginator),
        });
      },
      transformResponse: (response: ApiArrayDataResponse<ApiCommand>) => {
        return transformCommandsResponse(response);
      },
      providesTags: ['commands'],
    }),
    exportCommand: builder.mutation({
      query: (body) => {
        return {
          url: ENDPOINTS.COMMAND_EXPORT,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: { data: string }) => {
        if (response.data && response.data != '') {
          window.open(response.data, '_blank');
        }
      },
      invalidatesTags: ['commands'],
    }),
  }),
});

export const { useGetCommandsQuery, useExportCommandMutation } = commandApi;
