export enum AppThemes {
  EPR = 'private',
  PUBLIC = 'public',
  SP = 'program',
  VOD = 'vod',
  SR = 'retirement',

  EPR_DARK = 'private_dark',
  PUBLIC_DARK = 'public_dark',
  SP_DARK = 'program_dark',
  VOD_DARK = 'vod_dark',
  SR_DARK = 'retirement_dark',
}
