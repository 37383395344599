export const CONFIG = {
  BASE_URL_API: process.env.REACT_APP_API_ENDPOINT || '',
  MAP_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  API_KEY: process.env.REACT_APP_API_KEY || '',
  AUTH_SECURITY_KEY: process.env.REACT_APP_AUTH_SECURITY_KEY || '',
  API_KEY_TINY_MCE: process.env.REACT_APP_API_KEY_TINY_MCE || '',
  CLIENT_TRACKING_LINK_URL: process.env.REACT_APP_CLIENT_TRACKING_LINK_URL || '',
  COACH_TRACKING_LINK_URL: process.env.REACT_APP_COACH_TRACKING_LINK_URL || '',
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '',
  WEB_COACH_URL: process.env.REACT_APP_WEB_COACH_URL ?? '',
};
