export const ENDPOINTS = {
  // languages
  GET_LANGUAGES: 'translations/languages',
  GET_TRANSLATIONS: 'translations/get-data',

  // clients
  CLIENTS: '/clients',

  // Notes
  NOTES: 'notes',

  // coaches
  COACHES_BO: 'bo/coaches',
  COACHES: 'bo/coaches/index',
  NUMBER_OF_COACHES: '/bo/coaches/count?status=:status',
  ADD_COACH: '/bo/coaches/profile/create',
  DELETE_COACHES: '/bo/coaches/profile/delete',
  GET_COACH_BY_ID: '/bo/coaches/profile',
  UPDATE_COACH: '/bo/coaches/profile',
  SUSPEND_COACHES: '/bo/coaches/suspend',
  REACTIVATE_COACHES: '/bo/coaches/reactivate',
  UPDATE_COACH_STATE: 'update-state',
  REASSIGN_COACH: '/bo/reassign-coach',
  CREATE_ACCOUNT_STRIPE: '/bo/stripe/coaches',
  RESCHDULE_VISIO: '/bo/appointments',
  IMPERSONATE_COACH: 'bo/impersonate-coach',
  GET_STRIPE_REQUIRED_FIELDS: '/bo/stripe/coaches',
  CREATE_EXTERNAL_ACCOUNT: '/bo/stripe/coaches/external-account',
  GET_STRIPE_LOCATIONS_REQUIRED_FIELDS: '/bo/stripe/locations',
  CREATE_PLACE_EXTERNAL_ACCOUNT: '/bo/stripe/locations/external-account',
  BEST_PRODUCTS: 'bo/statistics/best-products',

  COACH_INVOICES: '/bo/coaches/:id/invoices',
  COACH_INVOICES_EXPORT: '/bo/coaches/:id/invoices/export',

  // Group of Coaches
  GROUP_OF_COACHES: '/bo/coaches/groups',
  DELETE_GROUP_OF_COACHES: '/bo/coaches/groups/delete',
  CREATE_GROUP: '/bo/coaches/groups',

  // CURRENCIES
  CURRENCIES: `/bo/stripe/currency`,

  // Review
  REVIEW_STATISTIC: '/bo/reviews/statistics',
  REVIEWS: '/bo/reviews',
  DELETE_REVIEWS: '/bo/reviews/delete',
  REVIEW_DETAILS: '/bo/reviews',
  // My clients
  MY_CLIENTS: '/coaches/clients',
  // appointments
  APPOINTMENTS: '/bo/appointments',
  TIME_SUGGESTIONS: '/time-suggestions',
  // AVAILIBILITIES
  AVAILABILITIES: '/bo/accounts/availabilities',
  EXPERT_AVAILIBILITIES: '/bo/experts/availabilities',
  // Users
  USERS: '/bo/users',
  DELETE_USERS: '/bo/users/delete',
  REACTIVATE_USERS: '/bo/users/reactivate',
  SUSPEND_USERS: '/bo/users/suspend',

  // UsersForm
  LIST_OF_GOALS: '/bo/goals',
  LIST_OF_ACTIVITIES: '/bo/sports',
  LISTE_OF_LEVELS: 'bo/training-levels',
  LISTE_OF_HOURS: 'bo/training-hours',
  STATISTIC_HOUR: 'bo/training-hours/statistics',
  ADD_GOAL: 'bo/goals',
  ADD_LEVEL: 'bo/training-levels',
  ADD_ACTIVITY: 'bo/sports',
  ADD_HOUR: 'bo/training-hours',
  DELETE_GOAL: 'bo/goals',
  DELETE_LEVEL: 'bo/training-levels/:id',
  DELETE_HOUR: 'bo/training-hours/:id',
  DELETE_ACTIVITY: 'bo/sports/:id',
  UPDATE_GOAL: 'bo/goals/:id',
  UPDATE_LEVEL: 'bo/training-levels/:id',
  UPDATE_ACTIVITY: '/bo/sports/:id',
  RESERVATIONS_TIME_SLOTS: '/bo/reservations/time-slots',

  // Commands
  COMMANDS: '/bo/orders',
  COMMAND_EXPORT: '/bo/orders/export-invoices',

  // Activities
  ACTIVITIES: '/api/activities',
  // countries
  COUNTRIES: '/api/countries',
  // products
  PRODUCTS: '/bo/coaches/products',
  GET_PRODUCT_BY_ID_TYPE: '/bo/coaches/products/:id?product_type=:type',
  ADDITIONAL_PRODUCTS: '/coaches/materials',
  DELETE_ALL_BY_IDS: '/delete-all-by-ids',
  UPDATE_ALL_BY_IDS: '/update-all-by-ids',

  // reservations
  RESERVATIONS: '/coaches/reservations',
  RESERVATIONS_CALCULATE: '/coaches/reservations/calculate',
  RESERVATIONS_CANCEL: '/coaches/reservations/time-slots/cancel',
  RESERVATIONS_EXPORT: '/coaches/reservations//invoices/export',
  // RESERVATIONS_TIME_SLOTS: '/coaches/reservations/time-slots',
  NEXT_RESERVATION: 'reservations/next-reservation',

  EXPENSES: 'expenses',

  // locations
  LOCATIONS: '/bo/locations',
  MAIN_LOCATIONS: '/locations',
  PRIVATE_INFO_LOCATIONS: '/bo/locations/info',

  // exercises
  EXERCISE_CATEGORIES: 'api/sports-program/categories',
  BO_SPORT_PROG_EXERCISES: 'bo/sports-program/exercises',
  BO_EXERCISES: 'bo/sports-program/exercises',

  // cities and regions
  CITY: '/regions',
  REGION: 'districts',

  // common
  CLONE: '/clone',
  SHARE: '/share',
  REFUSE: '/refuse',
  ACCEPT: '/accept',
  REMINDER: '/reminder',
  BO: 'bo',
  MEDIA: '/bo/media',
  UPLOAD_MEDIA: 'bo/upload-media',
  // dashboard
  DASHBOARD: 'dashboard',
  STATISTICS: '/bo/statistics/globals',
  COACH_REVENUES: '/bo/statistics/coaches-revenues',
  ASC_TURNOVER_FEES: '/bo/statistics/asc',

  // marketing section
  POP_UP: '/bo/popups',
  BOOSTING: '/bo/campaigns',
  NOTIFICATION: '/bo/notifications',
  DELETE_POP_UP: '/bo/popups/delete',
  DELETE_BOOSTING: '/bo/campaigns/delete',
  DELETE_Notification: '/bo/notifications/delete',
  // Tracking
  TRACKING: '/bo/tracking-campaigns',
  DELETE_TRACKING_LINK: '/bo/tracking-campaigns',
  // revenues
  REVENUES: 'revenues',

  // auth
  API: 'api',
  LOGIN: 'login',
  LOGOUT: 'logout',
  REGISTER: 'register',
  CALCULATE: '/calculate',
  DOCUMENTS: 'documents',
  REFRESH_TOKEN: 'bo/refresh',

  // Send otp code
  SEND_OTP_CODE: 'send-otp',

  // Verify otp code
  VERIFY_OTP_CODE: 'verify-otp',

  // Set new password
  RESET_PASSWORD: 'reset-password',
  UPDATE_PASSWORD: 'update-password',

  // BO-accounts
  ACCOUNTS: {
    GET: 'accounts',
    CREATE: 'accounts',
    GETBYID: (id: string) => `accounts/${id}`,
    DELETE: 'accounts/delete',
    UPDATE: (id: string) => `accounts/${id}`,
  },
  ROOMS: {
    GET: 'locations',
    POST: 'locations',
    EQUIPMENTS: '/services',
  },
  SERVICES: {
    GET: 'services',
  },
};

export const PARAMS = {
  API_KEY: 'apiKey',
  TOKEN: 'token',
  DIRECTION: 'direction',
  KEYWORD: 'keyword',
  ORDER_BY: 'orderBy',
  PAGE: 'page',
  PER_PAGE: 'perPage',
  PRODUCT_TYPE: 'productType',
  PREFER: 'Prefer',
  PACKS: 'packs',
};
