import { BORouteIdEnum } from 'config/enums';
import { joinPaths } from 'utils/helpers/path.helpers';
// FIXME - delete web routes

export namespace RouterPaths {
  export namespace RootPaths {
    export const anyPath = '*';
    export const rootPath = '/';
  }
  export namespace RegisterPaths {
    export const inscriptionPage = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.Register]);
    export const inscriptionInformationPage = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Register,
      BORouteIdEnum.Information,
    ]);
    export const inscriptionSupportingPage = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Register,
      BORouteIdEnum.Supporting,
    ]);
    export const inscriptionActivityPage = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Register,
      BORouteIdEnum.Activity,
    ]);
    export const inscriptionFinalizationPage = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Register,
      BORouteIdEnum.Finalization,
    ]);
    export const inscriptionFinalizationThanksPage = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.ThankPage,
    ]);
  }

  export namespace AuthPaths {
    export const loginPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Auth,
      BORouteIdEnum.Login,
    ]);
    export const forgetPasswordPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Auth,
      BORouteIdEnum.ForgetPassword,
    ]);
    export const verificationCodePath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Auth,
      BORouteIdEnum.VerificationCode,
    ]);
    export const resetPasswordPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Auth,
      BORouteIdEnum.ResetPassword,
    ]);
  }
  export namespace DashboardPaths {
    export const rootPath = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.Dashboard]);
    export const dashboardPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.BO,
      BORouteIdEnum.Dashboard,
    ]);
    export namespace shopPath {
      export const root = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.Dashboard,
        BORouteIdEnum.Shop,
        BORouteIdEnum.Products,
      ]);
      export const ShopAddProducts = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.Dashboard,
        BORouteIdEnum.Shop,
        BORouteIdEnum.Products,
        BORouteIdEnum.Add,
      ]);
      export const ShopCommands = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.Dashboard,
        BORouteIdEnum.Shop,
        BORouteIdEnum.Commands,
      ]);
    }
    export namespace CoachesPath {
      export const root = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.BO,
        BORouteIdEnum.Coaches,
        BORouteIdEnum.allCoaches,
      ]);
      export const waitingCoaches = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.BO,
        BORouteIdEnum.Coaches,
        BORouteIdEnum.waitingCoaches,
      ]);
      export namespace waitingCoachesUnderPaths {
        export const pendingCoaches = joinPaths([
          BORouteIdEnum.Root,
          BORouteIdEnum.BO,
          BORouteIdEnum.Coaches,
          BORouteIdEnum.waitingCoaches,
          BORouteIdEnum.all,
        ]);
        export const schedule = joinPaths([
          BORouteIdEnum.Root,
          BORouteIdEnum.BO,
          BORouteIdEnum.Coaches,
          BORouteIdEnum.waitingCoaches,
          BORouteIdEnum.schedule,
        ]);
        export const availabilities = joinPaths([
          BORouteIdEnum.Root,
          BORouteIdEnum.BO,
          BORouteIdEnum.Coaches,
          BORouteIdEnum.waitingCoaches,
          BORouteIdEnum.availabilities,
        ]);
      }
      export const refusalCoach = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.BO,
        BORouteIdEnum.Coaches,
        BORouteIdEnum.refusalCoach,
      ]);
      export const allProducts = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.BO,
        BORouteIdEnum.Coaches,
        BORouteIdEnum.allProducts,
      ]);
      export const groupOfCoaches = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.BO,
        BORouteIdEnum.Coaches,
        BORouteIdEnum.groupOfCoaches,
      ]);
    }
    export namespace CoachingPaths {
      export const root = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.Dashboard,
        BORouteIdEnum.Coaches,
      ]);
      export const privateTraining = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.Dashboard,
        BORouteIdEnum.Coaching,
        BORouteIdEnum.PrivateTraining,
      ]);
      export namespace PrivateTrainingPaths {
        export const course = joinPaths([
          BORouteIdEnum.Root,
          BORouteIdEnum.Dashboard,
          BORouteIdEnum.Coaching,
          BORouteIdEnum.PrivateTraining,
          BORouteIdEnum.PrivateTrainingCourse,
        ]);
        export const reservation = joinPaths([
          BORouteIdEnum.Root,
          BORouteIdEnum.Dashboard,
          BORouteIdEnum.Coaching,
          BORouteIdEnum.PrivateTraining,
          BORouteIdEnum.PrivateTrainingReservation,
        ]);
        export const agenda = joinPaths([
          BORouteIdEnum.Root,
          BORouteIdEnum.Dashboard,
          BORouteIdEnum.Coaching,
          BORouteIdEnum.PrivateTraining,
          BORouteIdEnum.PrivateTrainingAgenda,
        ]);
        export const waiting = joinPaths([
          BORouteIdEnum.Root,
          BORouteIdEnum.Dashboard,
          BORouteIdEnum.Coaching,
          BORouteIdEnum.PrivateTraining,
          BORouteIdEnum.PrivateTrainingWaiting,
        ]);
        export const clients = joinPaths([
          BORouteIdEnum.Root,
          BORouteIdEnum.Dashboard,
          BORouteIdEnum.Coaching,
          BORouteIdEnum.PrivateTraining,
          BORouteIdEnum.PrivateTrainingClients,
        ]);
        export const availability = joinPaths([
          BORouteIdEnum.Root,
          BORouteIdEnum.Dashboard,
          BORouteIdEnum.Coaching,
          BORouteIdEnum.PrivateTraining,
          BORouteIdEnum.PrivateTrainingAvailability,
        ]);
      }
      export const groupCourse = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.Dashboard,
        BORouteIdEnum.Coaching,
        BORouteIdEnum.GroupCourse,
      ]);
      export const sportProgram = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.Dashboard,
        BORouteIdEnum.Coaching,
        BORouteIdEnum.SportProgram,
      ]);
      export const vod = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.Dashboard,
        BORouteIdEnum.Coaching,
        BORouteIdEnum.Vod,
      ]);
      export const sportsRetirement = joinPaths([
        BORouteIdEnum.Root,
        BORouteIdEnum.Dashboard,
        BORouteIdEnum.Coaching,
        BORouteIdEnum.SportsRetirement,
      ]);
    }
    // TO FIX : We DON't NEED THIS trainingRoomsPath
    // WE SHOULD DELETE IT AFTER FIXING ACCOUNT MODULE
    export const trainingRoomsPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Dashboard,
      BORouteIdEnum.trainingRooms,
    ]);
    export const clientsPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Dashboard,
      BORouteIdEnum.Clients,
    ]);
    export const noticePath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Dashboard,
      BORouteIdEnum.Notice,
    ]);
    export const agendaPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Dashboard,
      BORouteIdEnum.Agenda,
    ]);
    export const messengerPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Dashboard,
      BORouteIdEnum.Messenger,
    ]);
    export const revenuesPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Dashboard,
      BORouteIdEnum.Revenues,
    ]);
    export const ascLabsPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Dashboard,
      BORouteIdEnum.AscLabs,
    ]);
    export const ascTrainingRoomsPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.BO,
      BORouteIdEnum.Gym,
      BORouteIdEnum.ascTrainingRooms,
    ]);
    export const coachesTrainingRoomsPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.BO,
      BORouteIdEnum.Gym,
      BORouteIdEnum.coachesTrainingRooms,
    ]);
    export const ascExercisesPath = joinPaths([
      BORouteIdEnum.Root,
      BORouteIdEnum.Dashboard,
      BORouteIdEnum.trainingRooms,
      BORouteIdEnum.Exercises,
      BORouteIdEnum.ascLibrary,
    ]);
  }
}

// NOTE - BO routes
export namespace BORouterPaths {
  export const dashboardPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Dashboard,
  ]);
  export const coachesPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Coaches,
  ]);
  export const usersPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Users,
    BORouteIdEnum.all,
  ]);
  export const usersDetailsPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Users,
    BORouteIdEnum.all,
    BORouteIdEnum.View,
  ]);
  export const reviewsPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Reviews,
  ]);

  export const commandsPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Users,
    BORouteIdEnum.Commands,
  ]);
  export const UserFormPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Users,
    BORouteIdEnum.Form,
  ]);

  export const accountsPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Accounts,
  ]);
  export const gymPath = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.BO, BORouteIdEnum.Gym]);
  export const exercisesPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Exercises,
  ]);

  export const marketingPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Marketing,
  ]);

  export const trackingLinkPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Marketing,
    BORouteIdEnum.TrackingLink,
  ]);

  export const promoCodesPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Marketing,
    BORouteIdEnum.PromoCodes,
  ]);
  export const coachAffiliationPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Marketing,
    BORouteIdEnum.CoachAffiliation,
  ]);
  export const sendNatificationPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Marketing,
    BORouteIdEnum.SendNotification,
  ]);
  export const homeUserPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Marketing,
    BORouteIdEnum.HomeUser,
  ]);
  export const PopUpPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Marketing,
    BORouteIdEnum.PopUp,
  ]);

  export const statisticsPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Statistics,
  ]);
  export const translationsPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Translations,
  ]);
}
