import {
  ApiGroupResponse,
  GroupDetails,
} from 'redux/api/coach/groupOfCoaches/groupOfCoachesApi.types';

export const mapGroupOfCoachs = (apiGroup: ApiGroupResponse): GroupDetails => {
  return {
    id: apiGroup.id,
    groupName: apiGroup.name,
    status: apiGroup.status,
    createdAt: apiGroup.created_at,
  };
};
