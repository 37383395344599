export enum ExerciseTypeEnum {
  COACH = 2,
  ASC = 1,
}

export enum ExerciseCategoryLabelsEnum {
  Category = 'category',
  Equipment = 'equipment',
  MuscleGroup = 'muscle_group',
  OtherCategories = 'other_categories',
  BodyArea = 'body_area',
}

export enum ExerciseCategoryEnum {
  Category = 1,
  Equipment = 3,
  MuscleGroup = 2,
  OtherCategories = 4,
  BodyArea = 1,
}

export enum ExerciseFormModeEnum {
  CREATE = 1,
  EDIT = 2,
}
