import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { injectParams } from 'utils';
import { baseQueryWithReAuth } from '../BaseQueryConfig';
import { transformCitiesResponse, transformCountriesResponse } from './citiesApi.transform';
import { ApiCityType, ApiCountryType } from './citiesApi.type';
import { paginatorToApiPagination } from 'utils/services/api.service';

export const citiesApi = createApi({
  reducerPath: 'citiesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Coaches'],
  endpoints: (builder) => ({
    // get list countries
    getAllCountries: builder.query({
      query: (paginator) =>
        injectParams(`${ENDPOINTS.COUNTRIES}`, {
          ...paginatorToApiPagination(paginator),
        }),
      transformResponse: (response: ApiArrayDataResponse<ApiCountryType>) => {
        return transformCountriesResponse(response);
      },
    }),

    // Get all coach clients list
    getAllCities: builder.query({
      query: (countryCode?: string) =>
        injectParams(ENDPOINTS.CITY, {
          country_code: countryCode,
          pagination: 0,
        }),
      transformResponse: (response: ApiCityType[]) => transformCitiesResponse(response),
      providesTags: ['Coaches'],
    }),
  }),
});

export const { useGetAllCitiesQuery, useGetAllCountriesQuery } = citiesApi;
