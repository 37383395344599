import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialState } from 'features/Translation/Types/models/InitialState';
import { RowsPerPage } from 'features/Translation/Types/models/TranslationQueryParams';
import { GroupEnum } from 'features/Translation/config/enums/groups.enum';
import { translationApi } from 'redux/api/translation/translationApi';
import {
  addGroupToGroup,
  addGroupToNestedGroup,
  getConstantsSectionIds,
  removeGroupFromGroup,
  updateGroupInSection,
} from 'redux/slices/translation/translation.helpers';

const INITIAL_STATE: InitialState = {
  platforms: [],
  languages: [],
  activePlatformIndex: 0,
  activeSectionIndex: 0,
  selectedItem: { sectionId: undefined, groupId: undefined },
  parentType: GroupEnum.SECTION,
  queryParams: {
    page: 1,
    perPage: 25,
    orderBy: 'created_at',
    order: 'DESC',
    globalSearchText: undefined,
  },
  constantsSectionIds: [],
};

const translationSlice = createSlice({
  name: 'translation',
  initialState: INITIAL_STATE,
  reducers: {
    setActivePlatformIndex: (state, action: PayloadAction<number>) => {
      const { platforms } = state;
      return {
        ...state,
        activePlatformIndex: action.payload,
        activeSectionIndex: 0,
        selectedItem: {
          sectionId:
            platforms[action.payload].sections.length > 0
              ? platforms[action.payload].sections[0].id
              : undefined,
          groupId: undefined,
        },
        parentId:
          platforms[action.payload].sections.length > 0
            ? platforms[action.payload].sections[0].id
            : undefined,
        queryParams: INITIAL_STATE.queryParams,
      };
    },
    setActiveSectionIndex: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        activeSectionIndex: action.payload,
        queryParams: INITIAL_STATE.queryParams,
      };
    },
    setSelectedItemToSection: (state, action: PayloadAction<number | undefined>) => {
      return {
        ...state,
        parentId: action.payload,
        parentType: GroupEnum.SECTION,
        platformId: undefined,
        selectedItem: {
          sectionId: action.payload,
          groupId: undefined,
        },
        queryParams: INITIAL_STATE.queryParams,
      };
    },
    setSelectedItemToGroup: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        parentId: action.payload,
        parentType: GroupEnum.GROUP,
        selectedItem: {
          sectionId: undefined,
          groupId: action.payload,
        },
      };
    },
    setGlobalSearchText: (state, action: PayloadAction<string | undefined>) => {
      state.queryParams.globalSearchText = action.payload;
      state.parentId = 0;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      return { ...state, queryParams: { ...state.queryParams, page: action.payload } };
    },
    setRowsPerPage: (state, action: PayloadAction<RowsPerPage>) => {
      return { ...state, queryParams: { ...state.queryParams, perPage: action.payload } };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      translationApi.endpoints.getAllPlatforms.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          platforms: payload,
          selectedItem:
            payload.length > 0 && payload[0].sections.length > 0
              ? { sectionId: payload[0].sections[0].id, groupId: undefined }
              : {},
          constantsSectionIds: getConstantsSectionIds(payload),
        };
      },
    );

    builder.addMatcher(
      translationApi.endpoints.createPlatform.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          platforms: [...state.platforms, payload],
          activePlatformIndex: state.platforms.length,
          selectedItem: {},
        };
      },
    );

    builder.addMatcher(translationApi.endpoints.deletePlatform.matchFulfilled, (state, action) => {
      const { originalArgs } = action.meta.arg;
      const { platforms, activePlatformIndex } = state;
      const { sections } = platforms[activePlatformIndex - 1];
      return {
        ...state,
        platforms: platforms.filter((platform) => platform.id !== Number(originalArgs)),
        activePlatformIndex: activePlatformIndex !== 0 ? activePlatformIndex - 1 : 0,
        selectedItem: {
          sectionId: sections.length > 0 ? sections[0].id : undefined,
          groupId: undefined,
        },
      };
    });

    builder.addMatcher(translationApi.endpoints.updatePlatform.matchFulfilled, (state, action) => {
      const { id, name, code } = action.meta.arg.originalArgs;
      return {
        ...state,
        platforms: state.platforms.map((platform) => {
          if (platform.id === Number(id)) {
            return {
              ...platform,
              name,
              code,
            };
          }
          return platform;
        }),
      };
    });

    builder.addMatcher(
      translationApi.endpoints.createSection.matchFulfilled,
      (state, { payload }) => {
        const { platforms, activePlatformIndex } = state;
        const { sections } = platforms[activePlatformIndex];
        return {
          ...state,
          platforms: platforms.map((platform) => {
            if (platform.id === payload.platformId) {
              const updatedSections = [...platform.sections, payload];
              return {
                ...platform,
                sections: updatedSections,
              };
            }
            return platform;
          }),
          activeSectionIndex: sections.length,
          parentId: payload.id,
          selectedItem:
            state.selectedItem.sectionId || state.selectedItem.groupId
              ? state.selectedItem
              : { sectionId: payload.id, groupId: undefined },
        };
      },
    );

    builder.addMatcher(translationApi.endpoints.deleteSection.matchFulfilled, (state, action) => {
      const { originalArgs } = action.meta.arg;
      const { platforms, activeSectionIndex, activePlatformIndex } = state;

      const updatedActiveSectionIndex = activeSectionIndex !== 0 ? activeSectionIndex - 1 : 0;
      const updatedSections = platforms.map((platform) => {
        if (platform.id === originalArgs.platformId) {
          return {
            ...platform,
            sections: platform.sections.filter((section) => section.id !== originalArgs.sectionId),
          };
        }
        return platform;
      });

      const selectedSectionIndex = activeSectionIndex !== 0 ? activeSectionIndex - 1 : 1;
      const updatedParentId = platforms[activePlatformIndex].sections[selectedSectionIndex].id;

      return {
        ...state,
        activeSectionIndex: updatedActiveSectionIndex,
        platforms: updatedSections,
        parentId: updatedParentId,
      };
    });

    builder.addMatcher(translationApi.endpoints.updateSection.matchFulfilled, (state, action) => {
      const { originalArgs } = action.meta.arg;
      return {
        ...state,
        platforms: state.platforms.map((platform) => {
          if (platform.id === originalArgs.platformId) {
            return {
              ...platform,
              sections: platform.sections.map((section) => {
                if (section.id === originalArgs.sectionId) {
                  return {
                    ...section,
                    name: originalArgs.name,
                  };
                }
                return section;
              }),
            };
          }
          return platform;
        }),
      };
    });

    builder.addMatcher(
      translationApi.endpoints.createGroup.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          platforms: state.platforms.map((platform) => {
            if (platform.id === state.platforms[state.activePlatformIndex].id) {
              const sections = state.platforms[state.activePlatformIndex].sections;
              return {
                ...platform,
                sections: sections.map((section) => {
                  if (section.id === payload.parentId) {
                    const updatedGroups = [...section.groups, payload];
                    return {
                      ...section,
                      groups: updatedGroups,
                    };
                  }
                  if (section.id === sections[state.activeSectionIndex].id) {
                    return {
                      ...section,
                      groups: addGroupToNestedGroup(section.groups, payload.parentId, payload),
                    };
                  }
                  return section;
                }),
              };
            }
            return platform;
          }),
        };
      },
    );

    builder.addMatcher(
      translationApi.endpoints.createSubGroup.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          platforms: state.platforms.map((platform) => {
            if (platform.id === state.platforms[state.activePlatformIndex].id) {
              const sections = state.platforms[state.activePlatformIndex].sections;
              return {
                ...platform,
                sections: addGroupToGroup(sections, state.activeSectionIndex, payload),
              };
            }
            return platform;
          }),
        };
      },
    );

    builder.addMatcher(translationApi.endpoints.deleteGroup.matchFulfilled, (state, action) => {
      const { platformId, parentId, groupId } = action.meta.arg.originalArgs;

      return {
        ...state,
        platforms: state.platforms.map((platform) => {
          const { id, sections } = platform;

          if (id === platformId) {
            return {
              ...platform,
              sections: removeGroupFromGroup(groupId, parentId, sections),
            };
          }
          return platform;
        }),
      };
    });

    builder.addMatcher(translationApi.endpoints.updateGroup.matchFulfilled, (state, action) => {
      const { platformId, parentId, groupId, name } = action.meta.arg.originalArgs;
      return {
        ...state,
        platforms: state.platforms.map((platform) => {
          const { id, sections } = platform;

          if (id === platformId) {
            return {
              ...platform,
              sections: updateGroupInSection(sections, parentId, groupId, name),
            };
          }
          return platform;
        }),
      };
    });

    builder.addMatcher(
      translationApi.endpoints.getAllLanguages.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          languages: payload,
        };
      },
    );
  },
});

export const {
  setActivePlatformIndex,
  setActiveSectionIndex,
  setSelectedItemToSection,
  setSelectedItemToGroup,
  setGlobalSearchText,
  setCurrentPage,
  setRowsPerPage,
} = translationSlice.actions;
export default translationSlice.reducer;
