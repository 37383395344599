import { lazy } from 'react';
import { BORouteIdEnum } from 'config/enums';

// lazy loaded pages
const ReviewPage = lazy(() => import('pages/ReviewPage/ReviewPage'));
const ReviewDetails = lazy(() => import('pages/ReviewPage/ReviewDetails/ReviewDetails'));

const ReviewsRoutes = {
  path: BORouteIdEnum.Reviews,
  children: [
    { path: BORouteIdEnum.Root, element: <ReviewPage /> },
    {
      path: BORouteIdEnum.EditId,
      element: <ReviewDetails />,
    },
  ],
};

export default ReviewsRoutes;
