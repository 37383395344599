import {
  CreateCampaignRequest,
  CreateCampaignResponse,
  EncodedCreateCampaignRequest,
} from './campaignApi.type';
import { CONFIG } from 'config/config';
import { Campaign } from 'types/models/Marketing/Campaign/Campaign';
import { SocialMediaSource } from 'types/models/Marketing/TrackingLink/TrackingLinkSource';

export const transformCreateCampaignRequest = (
  request: CreateCampaignRequest,
): EncodedCreateCampaignRequest => {
  const { values, sources } = request;
  const sourcesIds = sources.map((source) => source.id);

  const trackingLinkPrefixkUrl =
    values.target === 1 ? CONFIG.COACH_TRACKING_LINK_URL : CONFIG.CLIENT_TRACKING_LINK_URL;
  const link = `${trackingLinkPrefixkUrl}${values.url}`;

  return {
    title: values.title,
    target: values.target,
    link,
    sources: sourcesIds,
  };
};

export const transformGetCampaignDetails = (response: CreateCampaignResponse): Campaign => {
  const { data } = response;

  const sources: SocialMediaSource[] = data.sources.map((source) => ({
    id: source.id,
    sourceId: source.source_id,
    dynamicLink: source.dynamic_link,
    trackingCampaignLinkId: source.tracking_campaign_link_id,
    clickConversionCount: source.click_conversion_count,
    clickCount: source.click_count,
    clickPaymentCount: source.click_payment_count,
  }));

  return {
    id: data.id,
    title: data.title,
    target: data.target,
    url: data.url,
    sources,
    clickCount: data.click_count,
    conversionCount: data.conversion_count,
    confirmationCount: data.confirmation_count,
    conversionRate: data.conversion_rate,
    confirmationRate: data.confirmation_rate,
  };
};
