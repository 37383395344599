import { Theme } from '@mui/material/styles';

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          input: {
            marginLeft: 10,
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
            '& :hover': {
              borderWidth: 0,
            },
          },
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
          '& .MuiButton-root, .MuiButton-root:hover, .MuiButton-root:focus': {
            cursor: 'default',
            backgroundColor: 'transparent',
            width: 0,
            minWidth: 0,
            position: 'absolute',
            right: '15px',
            svg: {
              fill: theme.palette.primary.main,
            },
          },
          '& .MuiButton-endIcon, .MuiButton-endIcon:focus': {
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: 'transparent',
          },
        },
        paper: {
          boxShadow: `0px 20px 45px ${theme.palette.grey[300]}`,
          borderRadius: '0px 0px 8px 8px !important',
        },
        listbox: {
          minHeight: '10vh',
          maxHeight: '26vh',
          padding: 0,
        },
        tag: {
          backgroundColor: theme.palette.action.selected,
          borderRadius: '8px',
        },
        endAdornment: {
          top: 0,
          right: 0,
        },
      },
    },
  };
}
