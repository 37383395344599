import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { RegisterData, RegisterResponse } from 'types/models/Coach/coach';
import { transformRegisterRequest, transformRegisterResponse } from './registerApi.transform';
import { joinPaths } from 'utils/helpers/path.helpers';

export const registerApi = createApi({
  reducerPath: 'registerApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    // register api
    register: builder.mutation<RegisterResponse, RegisterData>({
      query: (registerData) => ({
        url: joinPaths([ENDPOINTS.COACHES, ENDPOINTS.REGISTER]),
        method: 'POST',
        body: transformRegisterRequest(registerData),
      }),
      transformResponse: (response: RegisterResponse) => transformRegisterResponse(response),
    }),
    uploadDocuments: builder.mutation<{ data: string }, FormData>({
      query(data) {
        return {
          url: joinPaths([ENDPOINTS.COACHES, ENDPOINTS.DOCUMENTS]),
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useRegisterMutation, useUploadDocumentsMutation } = registerApi;
