import { Theme } from '@mui/material';
import { GlobalFonts } from 'config/constant/fonts.config';

export default function Input(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          backgroundColor: theme.palette.background.paper,
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.grey[300]}`,
          },
          '&:hover fieldset': {
            border: `1px solid ${theme.palette.primary.main} !important`,
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.grey[300]}`,
            },
            '&:hover fieldset': { border: `1px solid ${theme.palette.grey[300]} !important` },
          },
        },
        input: {
          padding: '16px 23px',
          '&::placeholder': {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            color: theme.palette.grey[500],
            fontFamily: GlobalFonts.FONT_PRIMARY_ITALIC,
            opacity: 1,
          },
        },
        sizeSmall: {
          padding: '8px 10px',
        },
      },
    },
  };
}
