import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import Paginator from 'types/interfaces/Paginator';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { joinPaths } from 'utils/helpers';
import { injectParams } from 'utils/helpers/api-url';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { PopUpOptions, PopUpsAPI } from './popup.type';
import {
  transformPopUpApiOptions,
  transformPopUpResponse,
  transformPopUpsResponse,
} from './popupApi.transform';

export const popupApi = createApi({
  reducerPath: 'popupApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['popup'],
  endpoints: (builder) => ({
    getPopUps: builder.query({
      query: (params: { paginator?: Paginator; options?: PopUpOptions }) => {
        return injectParams(ENDPOINTS.POP_UP, {
          ...transformPopUpApiOptions(params?.options),
          ...paginatorToApiPagination(params.paginator),
        });
      },
      transformResponse: (response: ApiArrayDataResponse<PopUpsAPI>) => {
        return transformPopUpsResponse(response);
      },
      providesTags: ['popup'],
    }),
    createPopUp: builder.mutation({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.POP_UP]),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['popup'],
    }),
    getPopUpById: builder.query({
      query: (id: string) => `${ENDPOINTS.POP_UP}/${id}`,
      transformResponse: (response: { data: PopUpsAPI }) => {
        return transformPopUpResponse(response.data);
      },
      providesTags: ['popup'],
    }),
    updatePopUp: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `${ENDPOINTS.POP_UP}/${id}?_method=PATCH`,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['popup'],
    }),
    deletePopUps: builder.mutation({
      query: (body) => {
        return {
          url: ENDPOINTS.DELETE_POP_UP,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['popup'],
    }),
  }),
});

export const {
  useGetPopUpsQuery,
  useCreatePopUpMutation,
  useGetPopUpByIdQuery,
  useUpdatePopUpMutation,
  useDeletePopUpsMutation,
} = popupApi;
