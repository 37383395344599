import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateSelected, MeetDateType } from 'types/models/Inscription/DateSelected';
import { InformationDataType } from 'types/models/Inscription/Information.type';
import { DocumentsType, SupportingDataType } from 'types/models/Inscription/Supporting.type';

const INITIAL_STATE = {
  register: {
    information: {
      firstName: '',
      lastName: '',
      phone: 0,
      email: '',
      referralCode: '',
    },
    supporting: {
      siret: 0,
      professionalCardStatus: false,
      professionalCard: '',
      documents: [],
    } as SupportingDataType,
    activities: [''],
    meetDate: {
      firstDate: undefined,
      lastDate: undefined,
    } as MeetDateType,
  },
  documents: null as DocumentsType[] | null,
  selectDate: {
    rowIndex: -1,
    columnIndex: -1,
    numberWeek: -1,
    day: -1,
  },
  isRegistered: false as boolean,
};

const inscriptionSlice = createSlice({
  name: 'inscription',
  initialState: INITIAL_STATE,
  reducers: {
    setInformationData: (state, action: PayloadAction<{ information: InformationDataType }>) => {
      state.register.information = action.payload.information;
    },
    resetInformationData: (state) => {
      state.register.information = INITIAL_STATE.register.information;
    },
    setSupportingData: (state, action: PayloadAction<{ supporting: SupportingDataType }>) => {
      state.register.supporting = action.payload.supporting;
    },
    resetSupportingData: (state) => {
      state.register.supporting = INITIAL_STATE.register.supporting;
    },
    setActivitiesData: (state, action: PayloadAction<{ activities: string[] }>) => {
      state.register.activities = action.payload.activities.map((activity) => activity);
    },
    resetActivitiesData: (state) => {
      state.register.activities = INITIAL_STATE.register.activities;
    },
    setSelectedDate: (state, action: PayloadAction<{ selectDate: DateSelected }>) => {
      state.selectDate = action.payload.selectDate;
    },
    resetSelectedDate: (state) => {
      state.selectDate = INITIAL_STATE.selectDate;
    },
    setDocuments: (state, action: PayloadAction<{ documents: DocumentsType[] | null }>) => {
      state.documents = action.payload.documents;
    },
    removeDocument: (state, action: PayloadAction<{ index: number }>) => {
      state.register.supporting.documents.splice(action.payload.index, 1);
      state.documents?.splice(action.payload.index, 1);
    },
    resetDocuments: (state) => {
      state.documents = INITIAL_STATE.documents;
    },
    setSelectedMeetDate: (state, action: PayloadAction<{ meetDate: MeetDateType }>) => {
      state.register.meetDate = action.payload.meetDate;
    },
    resetSelectedMeetDate: (state) => {
      state.register.meetDate = INITIAL_STATE.register.meetDate;
    },
    resetForm: (state) => {
      state.register.activities = INITIAL_STATE.register.activities;
      state.register.information = INITIAL_STATE.register.information;
      state.register.supporting = INITIAL_STATE.register.supporting;
      state.documents = INITIAL_STATE.documents;
      state.selectDate = INITIAL_STATE.selectDate;
    },
    setIsRegistered: (state, action: PayloadAction<{ isRegistered: boolean }>) => {
      state.isRegistered = action.payload.isRegistered;
    },
  },
});
export const {
  setInformationData,
  resetInformationData,
  setSupportingData,
  resetSupportingData,
  setActivitiesData,
  resetActivitiesData,
  setSelectedDate,
  resetSelectedDate,
  setSelectedMeetDate,
  resetSelectedMeetDate,
  setDocuments,
  removeDocument,
  resetDocuments,
  resetForm,
  setIsRegistered,
} = inscriptionSlice.actions;
export default inscriptionSlice.reducer;
