import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { AffirmativeResponse } from 'config/enums/common.enums';
import Paginator from 'types/interfaces/Paginator';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { baseQueryConfig } from '../BaseQueryConfig';
import { transformEvents } from './eventApi.transform';
import { injectParams } from 'utils/helpers/api-url';
import { ApiEvent } from 'redux/api/event/eventApi.type';

type EventsQueryParams = {
  paginator?: Paginator;
  startDate?: number;
  endDate?: number;
  groupBy: string;
  pagination?: AffirmativeResponse;
};

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: fetchBaseQuery(baseQueryConfig),
  tagTypes: ['Events'],
  endpoints: (builder) => ({
    getCoachesEvents: builder.query({
      query: ({ paginator, startDate, endDate, groupBy, pagination }: EventsQueryParams) => {
        return injectParams(ENDPOINTS.APPOINTMENTS, {
          ...paginatorToApiPagination(paginator),
          start_date: startDate,
          end_date: endDate,
          group_by: groupBy,
          pagination,
        });
      },
      transformResponse: (response: ApiArrayDataResponse<ApiEvent>) => transformEvents(response),
      providesTags: ['Events'],
    }),
  }),
});

export const { useGetCoachesEventsQuery } = eventApi;
