import MediaType from 'types/models/common/MediaType/MediaType';

export interface ApiMediaType {
  id: number;
  hash: string;
  icon: string;
  small: string;
  medium: string;
  large: string;
  original?: string;
  thumbnail: string;
  document?: string;
  video: string;
  mime_type: string;
  created_at: number;
  updated_at: number;
  deleted_at: number;
}

export const transformApiMediaType = (apiMediaType: ApiMediaType | string): MediaType => {
  const original = (typeof apiMediaType === 'string' ? apiMediaType : apiMediaType?.original) ?? '';

  let mediaType: MediaType = {
    id: 0,
    src: {
      original,
      icon: original,
      small: original,
      medium: original,
      large: original,
      thumbnail: original,
    },
    mimeType: '',
    video: undefined,
    hash: undefined,
  };

  if (typeof apiMediaType !== 'string') {
    mediaType = {
      id: apiMediaType?.id,
      src: {
        original: apiMediaType?.original ?? original,
        icon: apiMediaType?.icon ?? original,
        small: apiMediaType?.small ?? original,
        medium: apiMediaType?.medium ?? original,
        large: apiMediaType?.large ?? original,
        thumbnail: apiMediaType?.thumbnail ?? original,
      },
      mimeType: apiMediaType?.mime_type ?? '',
      video: undefined,
      hash: undefined,
    };
  }
  return mediaType;
};
