import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { AffirmativeResponse } from 'config/enums/common.enums';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import {
  ApiSportType,
  SportFormRequest,
} from 'redux/api/user/UserForm/ActivityUserForm/activityUserForm.type';
import {
  encodeSportFormRequest,
  transformSportsListResponse,
} from 'redux/api/user/UserForm/ActivityUserForm/activityUserFormApi.transform';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { injectParams } from 'utils/helpers';

export const ActivityUserForm = createApi({
  reducerPath: 'ActivityFormApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['ActivityFormApi'],
  endpoints: (builder) => ({
    getActivities: builder.query({
      query: () =>
        injectParams(ENDPOINTS.LIST_OF_ACTIVITIES, {
          pagination: AffirmativeResponse.No,
        }),
      transformResponse: (response: ApiArrayDataResponse<ApiSportType>) => {
        return transformSportsListResponse(response);
      },
      providesTags: ['ActivityFormApi'],
    }),
    createActivity: builder.mutation({
      query: (body: SportFormRequest) => ({
        url: ENDPOINTS.ADD_ACTIVITY,
        method: 'POST',
        body: encodeSportFormRequest(body),
      }),
      invalidatesTags: ['ActivityFormApi'],
    }),
    deleteActivity: builder.mutation({
      query: (sportId) => ({
        url: `${ENDPOINTS.DELETE_ACTIVITY.replace(':id', sportId)}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ActivityFormApi'],
    }),
    updateActivity: builder.mutation({
      query: (body: SportFormRequest) => ({
        url: `${ENDPOINTS.UPDATE_ACTIVITY.replace(':id', String(body?.id))}`,
        method: 'PATCH',
        body: encodeSportFormRequest(body),
      }),
      invalidatesTags: ['ActivityFormApi'],
    }),
  }),
});

export const {
  useGetActivitiesQuery,
  useCreateActivityMutation,
  useDeleteActivityMutation,
  useUpdateActivityMutation,
} = ActivityUserForm;
