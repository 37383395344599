import { SelectedCategoriesType } from './../../../components/Common/CategoriesList/CategoriesList.type';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialSelectedCategory } from 'components/Common/CategoriesList/CategoriesList.constants';

interface PlaceFilters {
  filters: Record<string, (string | number)[]>;
  selectedCategories: SelectedCategoriesType[];
}

const INITIAL_STATE: PlaceFilters = {
  filters: {},
  selectedCategories: [initialSelectedCategory],
};

export const placeFiltersSlice = createSlice({
  name: 'placeFilters',
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action: PayloadAction<SelectedCategoriesType[]>) => {
      const values: Record<string, (string | number)[]> = {};
      action.payload.forEach((category) => {
        if (category.category !== 0) {
          values[category.category] = category.values.map((v) => v.value);
        }
      });

      return { ...state, filters: values };
    },
    setSelectedCategories: (
      state,
      action: PayloadAction<{
        categoryId: number | string;
        selectedValue: number;
        selectedLabel: string;
      }>,
    ) => {
      const { categoryId, selectedLabel, selectedValue } = action.payload;
      const categoryIndex = state.selectedCategories.findIndex(
        (category) => category.category === categoryId,
      );

      switch (true) {
        case categoryId === 0:
          // If the selected category is the default, remove all selected categories and replace them with the default
          state.selectedCategories = [initialSelectedCategory];
          break;
        case categoryIndex === -1: {
          const updatedCategories = state.selectedCategories.filter(
            (category) => category.category !== 0,
          );
          updatedCategories.push({
            category: categoryId,
            values: [{ value: selectedValue, label: selectedLabel }],
          });
          state.selectedCategories = updatedCategories;
          break;
        }
        default: {
          const updatedCategories = [...state.selectedCategories];
          const updatedCategoriesValues = [...updatedCategories[categoryIndex].values];
          const valueIndex = [...updatedCategories[categoryIndex].values].findIndex(
            (v) => v.value === selectedValue,
          );
          if (valueIndex === -1) {
            updatedCategories[categoryIndex].values = [
              ...updatedCategoriesValues,
              { value: selectedValue, label: selectedLabel },
            ];
          } else {
            const newValues = [...updatedCategoriesValues.filter((v) => v.value !== selectedValue)];
            if (newValues.length) {
              updatedCategories[categoryIndex].values = [...newValues];
            } else {
              updatedCategories.splice(categoryIndex, 1);
            }
          }

          if (updatedCategories.length) {
            state.selectedCategories = updatedCategories;
          } else {
            state.selectedCategories = [initialSelectedCategory];
          }

          break;
        }
      }
    },
    resetFilters: (state) => {
      state.filters = {};
      state.selectedCategories = [initialSelectedCategory];
    },
  },
});

export const { setFilters, setSelectedCategories, resetFilters } = placeFiltersSlice.actions;

export default placeFiltersSlice.reducer;
