import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import {
  ApiCoach,
  ApiCoachResponse,
  CoachDetails,
  CoachNoteApi,
  CoachRequestBody,
  CoachesOptions,
  Reason,
} from './coachApi.types';
import { Coach } from 'features/Coaches/CoachesTable/CoacheTable.type';
import { transformApiResponse } from '../BaseApiTransform';
import { mapCoaches } from 'redux/api/coach/coachApi.decoder';
import { transformApiMediaType } from 'redux/api/commonTypes/file';
import MediaType from 'types/models/common/MediaType/MediaType';
import { ApiCoachRevenues } from '../commonTypes/expenses';
import { CoachRevenues } from 'types/models/common/ExpenseType/ExepenseType';
import { activityApi } from 'redux/api/activities/activityApi.type';
import SportType from 'types/models/common/SportType/SportType';
import { CoachNote } from 'types/models/Coach/coach';
import { mimeTypeToMediaEnum } from 'utils/helpers';
export const transformCreateCoachBody = (body: CoachRequestBody) => {
  const {
    firstName,
    lastName,
    postalCode,
    birthDate,
    professionalCard,
    professionalCardStatus,
    countryId,
    companyType,
    insuranceNumber,
    email,
    phone,
    city,
    address,
    gender,
    siret,
    diplomas,
    documents,
    activities,
    sports,
    tva,
    notes,
    photoId,
  } = body;
  return {
    photo_id: photoId,
    first_name: firstName,
    last_name: lastName,
    postal_code: postalCode,
    birth_date: birthDate,
    professional_card: professionalCard,
    professional_card_status: professionalCardStatus,
    country_id: countryId,
    company_type: companyType,
    insurance_number: insuranceNumber,
    email,
    phone,
    city,
    address,
    gender,
    siret,
    diplomas,
    documents,
    activities,
    sports,
    tva,
    notes,
  };
};

export const transformCoachesResponse = (
  response: ApiArrayDataResponse<ApiCoach>,
): ArrayDataResponse<Coach> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map(mapCoaches),
  };
};
export const transformCoachesApiOptions = (options: CoachesOptions) => {
  return {
    alphabet: options.alphabet,
    syllable: options.syllable,
    account_status: options.accountStatus,
  };
};

export const transformRevenuesCoachesApiOptions = (months?: string) => {
  return {
    months: months,
  };
};

export const transformCoachNotes = (notes?: CoachNoteApi[]): CoachNote[] => {
  if (notes === undefined) return [];

  return notes.map((note) => ({
    id: note.id,
    description: note.description,
    writerId: note.writer_id,
    concernedId: note.concerned_id,
    createdAt: note.created_at,
    updatedAt: note.updated_at,
  }));
};

export const transformCoachResponse = (response: ApiCoachResponse): CoachDetails => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transformMediaDocument = (media: any, index: number) =>
    media.document
      ? {
          ...transformApiMediaType(media.document),
          type: mimeTypeToMediaEnum(media.mime_type),
          apiPath: media.document,
          index,
        }
      : null;

  const documents: (MediaType | null)[] =
    (response.coach_documents || []).map(transformMediaDocument) ?? [];

  const diplomas: (MediaType | null)[] =
    (response.coach_diplomas || []).map(transformMediaDocument) ?? [];

  const sports: SportType[] = response.sports.map((sport) => ({
    id: sport.id,
    translatedName: sport.translated_key
      ? `sports.${sport.translated_key.name}`
      : sport.translated_name,
    isAsc: sport.is_asc,
    image: transformApiMediaType(sport.icon ?? ''),
    designation: sport.designation,
  }));

  const refusReasons: Reason[] = response.reasons.refus.map((reason) => ({
    id: reason.id,
    isAsc: reason?.is_asc,
    designation: reason?.designation,
    type: reason.type,
    translatedName: reason.translated_name,
  }));

  const reschedulingReasons: Reason[] = response.reasons.rescheduling.map((reason) => ({
    id: reason.id,
    isAsc: reason?.is_asc,
    designation: reason?.designation,
    type: reason.type,
    translatedName: reason.translated_name,
  }));

  return {
    id: response.id,
    firstName: response.first_name,
    lastName: response.last_name,
    profilePicture: response.profile_picture,
    gender: response.gender,
    phone: response.phone,
    email: response.email,
    address: response.address,
    city: response.city,
    birthDate: response.birth_date,
    postalCode: response.zip_code,
    boUserId: response.bo_user_id,
    referralCode: response.referral_code,
    siret: response.siret,
    professionalCard: response.professional_card,
    professionalCardStatus: response.professional_card_status,
    recommendedToCount: response.recommended_to_count,
    activities: response.activities ? response.activities.map((act: activityApi) => act.id) : [],
    sports: sports,
    documents: documents,
    diplomas: diplomas,
    photo: transformApiMediaType(response.photo),
    country: {
      id: response.country.id,
      code: response.country.code,
      defaultCurrency: response.country.default_currency,
      translatedName: response.country.translated_name,
      label: `COUNTRIES.${response?.country?.translated_key?.name}`,
    },
    stripeLink: response.stripe_link,
    businessType: response.business_type,
    tva: response.tax,
    ascVisio: {
      date: response?.asc_visio?.date,
      startTime: response?.asc_visio?.start_time,
      endTime: response?.asc_visio?.end_time,
      interviewLink: response?.asc_visio?.interview_link,
    },
    expert: {
      id: response.expert.id,
      firstName: response.expert.first_name,
      lastName: response.expert.last_name,
      profilePicture: response.expert.profile_picture,
      totalCoaches: response.expert.total_coaches,
    },
    accountStatus: response.account_status,
    waitingAccountState: response.account_state,
    total: response.total,
    reasons: {
      refus: refusReasons || [],
      rescheduling: reschedulingReasons || [],
    },
    insuranceNumber: response.insurance_number,
    notes: transformCoachNotes(response.bo_notes),
  };
};

export const transformCoachRevenuesResponse = (response: ApiCoachRevenues): CoachRevenues => {
  const revenuesByMonth = response.revenues_by_month.map((revenue) => {
    return {
      type: revenue.type,
      revenues: revenue.revenues,
    };
  });
  return {
    globalStatistics: {
      productsCreatedNumber: response.global_statistics.products_created_number,
      ordersNumber: response.global_statistics.orders_number,
      turnover: response.global_statistics.turnover,
      ascFees: response.global_statistics.asc_fees,
    },
    revenuesByMonth: revenuesByMonth,
  };
};
