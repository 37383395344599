import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { ApiInvoices, CoachInvoiceApi, InvoicesOptions } from './invoices.type';
import { transformCoachInvoicesResponse, transformInvoicesApiOptions } from './invoices.transform';

export const invoicesApi = createApi({
  reducerPath: 'invoicesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['invoices'],
  endpoints: (builder) => ({
    getInvoicesByCoach: builder.query({
      query: (params: { paginator?: Paginator; options: InvoicesOptions }) => {
        return injectParams(ENDPOINTS.COACH_INVOICES.replace(':id', params?.options?.id), {
          ...transformInvoicesApiOptions(params?.options),
          ...paginatorToApiPagination(params.paginator),
        });
      },

      transformResponse: (response: ApiArrayDataResponse<ApiInvoices>) => {
        return transformCoachInvoicesResponse(response);
      },
      providesTags: ['invoices'],
    }),
    exportInvoice: builder.mutation({
      query: (body) => {
        return {
          url: ENDPOINTS.COACH_INVOICES_EXPORT.replace(':id', body.coachId),
          method: 'POST',
          body: body.invoices,
        };
      },
      transformResponse: (response: { data: string }) => {
        if (response.data && response.data != '') {
          window.open(response.data, '_blank');
        }
      },
      invalidatesTags: ['invoices'],
    }),
  }),
});

export const { useGetInvoicesByCoachQuery, useExportInvoiceMutation } = invoicesApi;
