import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { transformStripeRequiredFields } from 'redux/api/stripe/stripeApi.transform';

import { ResponseData, transformedRequiredFields } from 'redux/api/stripe/stripeApi.type';

export const placeStripeApi = createApi({
  reducerPath: 'placeStripeApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Fields'],
  endpoints: (builder) => ({
    getStripeLocationsRequiredFields: builder.query({
      query: ({ id }) => `${ENDPOINTS.GET_STRIPE_LOCATIONS_REQUIRED_FIELDS}/${id}`,
      transformResponse: (response: ResponseData): transformedRequiredFields => {
        return transformStripeRequiredFields(response);
      },
      providesTags: ['Fields'],
    }),
    createPlaceExternalAccount: builder.mutation({
      query: ({ token, id }) => ({
        url: `${ENDPOINTS.CREATE_PLACE_EXTERNAL_ACCOUNT}/${id}`,
        method: 'POST',
        body: token,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Fields']),
    }),
  }),
});
export const { useGetStripeLocationsRequiredFieldsQuery, useCreatePlaceExternalAccountMutation } =
  placeStripeApi;
