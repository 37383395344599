import OfflineDialog from 'components/Dialogs/OfflineDialog/OfflineDialog';
import { Router } from 'routes';
import './assets/sass/common.scss';
import AlertDialog from 'components/Dialogs/AlertDialog/AlertDialog';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useAppSelector } from 'redux/hooks';
import generateTheme from 'theme/theme';
import { useMemo } from 'react';
import GlobalStyles from 'theme/globalStyles';
import { CustomSnack } from 'components/CustomSnack/CustomSnack';
import { useLocation } from 'react-router-dom';
import useScroll from 'hooks/useScroll';
import useInitLanguage from 'hooks/useInitLanguage';
import { I18nextProvider } from 'react-i18next';
import CustomAscLoader from 'components/CustomLoader/CustomAscLoader/CustomAscLoader';
function App() {
  const appConfig = useAppSelector((state) => state.appReducer);
  const theme = useMemo(() => generateTheme(appConfig.mode), [appConfig.mode]);
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useScroll(pathname);

  const { i18nInstance, isLoading } = useInitLanguage();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {isLoading ? (
          <CustomAscLoader />
        ) : (
          <I18nextProvider i18n={i18nInstance}>
            <Router />
            <CustomSnack />
            <OfflineDialog />
            <AlertDialog />
          </I18nextProvider>
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
