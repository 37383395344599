export enum TypeEnum {
  All = 0,
  Private = 1,
  Public = 2,
  SportProgram = 3,
  VOD = 4,
  SportRetreat = 5,
  Shop = 6,
}

export enum TypesLabelsEnum {
  All = 'expenses.all',
  Private = 'expenses.private',
  Public = 'expenses.public',
  SportProgram = 'expenses.sport_program',
  VOD = 'expenses.VOD',
  SportRetreat = 'expenses.sport_retreat',
  Shop = 'expenses.shop',
}
