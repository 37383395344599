import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { AffirmativeResponse } from 'config/enums/common.enums';
import { injectParams } from 'utils';
import { baseQueryWithReAuth } from '../BaseQueryConfig';
import { transformLanguages } from './languageApi.transform';
import { ApiLanguagesResponse } from './languageApi.type';
import { PLATFORM_CODE } from 'config/constant/app.config';

export const languageApi = createApi({
  reducerPath: 'languageApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Translation'],
  endpoints: (builder) => ({
    getLanguagesList: builder.query({
      query: () => injectParams(ENDPOINTS.GET_LANGUAGES, { pagination: AffirmativeResponse.No }),
      transformResponse: (response: ApiLanguagesResponse) => transformLanguages(response),
    }),
    getLanguagesTranslations: builder.query({
      query: () => injectParams(ENDPOINTS.GET_TRANSLATIONS, { code: PLATFORM_CODE }),
      keepUnusedDataFor: 1000000,
      providesTags: ['Translation'],
    }),
  }),
});

export const { useGetLanguagesListQuery, useGetLanguagesTranslationsQuery } = languageApi;
