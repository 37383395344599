import { Collapse, List, Stack } from '@mui/material';
import { GlobalVariables } from 'config/constant';
import useResponsive from 'hooks/useResponsive';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import NavItem from 'types/interfaces/NavItem';
import { ResponsiveQueryEnum } from 'types/interfaces/ResponsiveQuery';
import {
  ArrowDowIconStyle,
  ItemIconStyle,
  ItemStyle,
  ListItemStyle,
  ListItemTextStyle,
  SubItemBoxStyle,
  SubItemStyle,
} from './NavSectionItem.style';
import { NavItemProps } from './NavSectionItem.type';

function NavSectionItem({ item, isMini, checkIsActive }: NavItemProps) {
  const [open, setOpen] = useState(false);
  const { title, path, icon, children } = item;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const isDesktop = useResponsive(ResponsiveQueryEnum.DOWN, 'lg');
  const isTablet = useResponsive(ResponsiveQueryEnum.UP, 'sm');

  const isActiveRoot = checkIsActive(item.path);

  const handleOpen = () => {
    if (children) {
      setOpen(!open);
    } else {
      navigate(path);
    }
  };

  if (children) {
    return (
      <ListItemStyle
        active={isActiveRoot ? GlobalVariables.TRUE : GlobalVariables.FALSE}
        open={open}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding={1}
          paddingX={2}
          onClick={handleOpen}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            {icon ?? <ItemIconStyle />}
            {((!isDesktop && isTablet) || !isMini) && (
              <ListItemTextStyle disableTypography primary={t(title)} />
            )}
          </Stack>

          <ArrowDowIconStyle down={open ? GlobalVariables.TRUE : GlobalVariables.FALSE} />
        </Stack>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component={Stack} spacing={'11px'} disablePadding sx={{ pl: 2, pb: 2 }}>
            {children.map((subItem: NavItem) => {
              const isActiveSub = checkIsActive(subItem.path);
              return (
                <SubItemStyle
                  active={isActiveSub ? GlobalVariables.TRUE : GlobalVariables.FALSE}
                  key={t(subItem.title)}
                  component={RouterLink}
                  to={subItem.path}
                  color={subItem.color}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <SubItemBoxStyle color={subItem.color} />
                    {((!isDesktop && isTablet) || !isMini) && (
                      <ListItemTextStyle disableTypography primary={t(subItem.title)} />
                    )}
                  </Stack>
                </SubItemStyle>
              );
            })}
          </List>
        </Collapse>
      </ListItemStyle>
    );
  }

  return (
    <ItemStyle
      component={RouterLink}
      to={path}
      active={isActiveRoot ? GlobalVariables.TRUE : GlobalVariables.FALSE}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        {icon || <ItemIconStyle />}
        {((!isDesktop && isTablet) || !isMini) && (
          <ListItemTextStyle disableTypography primary={t(title)} />
        )}
      </Stack>
    </ItemStyle>
  );
}

export default NavSectionItem;
