import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { CreateTrainingPlace } from 'types/models/TrainingPlace/TrainingPlace';
import { injectParams } from 'utils/helpers/api-url';
import { baseQueryConfig } from '../BaseQueryConfig';
import {
  transformCreatePlaceBody,
  transformEquipmentResponse,
  transformGetPlaceParams,
  transformPlacesResponse,
  transformTrainingPlace,
} from './placeApi.transform';
import { AllPlacesQueryParams, ApiEquipment, ApiTrainingPlace } from './placeApi.type';
import { FieldValues } from 'react-hook-form';

export const placeApi = createApi({
  reducerPath: 'placeApi',
  baseQuery: fetchBaseQuery(baseQueryConfig),
  tagTypes: ['Places'],
  endpoints: (builder) => ({
    getAllBasicPlaces: builder.query({
      query: (params: AllPlacesQueryParams) => {
        return injectParams(ENDPOINTS.LOCATIONS, transformGetPlaceParams(params));
      },
      transformResponse: (response: ApiArrayDataResponse<ApiTrainingPlace>) =>
        transformPlacesResponse(response),
      providesTags: ['Places'],
    }),

    getAllPlaces: builder.query({
      query: (params: AllPlacesQueryParams) => {
        return injectParams(ENDPOINTS.LOCATIONS, transformGetPlaceParams(params));
      },
      transformResponse: (response: ApiArrayDataResponse<ApiTrainingPlace>) =>
        transformPlacesResponse(response),
      providesTags: ['Places'],
    }),

    getPlaceById: builder.query({
      query: (id: number) => {
        return `${ENDPOINTS.MAIN_LOCATIONS}/${id}`;
      },
      transformResponse: (response: { data: ApiTrainingPlace }) =>
        transformTrainingPlace(response.data),
      providesTags: ['Places'],
    }),

    createPlace: builder.mutation({
      query: (body: CreateTrainingPlace) => ({
        url: ENDPOINTS.LOCATIONS,
        method: 'POST',
        body: transformCreatePlaceBody(body),
      }),
      invalidatesTags: ['Places'],
    }),

    updatePlace: builder.mutation({
      query: (body: CreateTrainingPlace & { id: number }) => ({
        url: `${ENDPOINTS.LOCATIONS}/${body.id}`,
        method: 'PATCH',
        body: { ...transformCreatePlaceBody(body), id: body.id },
      }),
      invalidatesTags: ['Places'],
    }),

    deletePlace: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.MAIN_LOCATIONS}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Places'],
    }),

    getPlaceEquipments: builder.query({
      query: () => ENDPOINTS.ROOMS.EQUIPMENTS,
      transformResponse: (response: ApiArrayDataResponse<ApiEquipment>) =>
        transformEquipmentResponse(response),
    }),

    createPlacePrivateInfo: builder.mutation({
      query: (body: FieldValues) => ({
        url: ENDPOINTS.PRIVATE_INFO_LOCATIONS,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetAllPlacesQuery,
  useGetPlaceEquipmentsQuery,
  useGetAllBasicPlacesQuery,
  useGetPlaceByIdQuery,
  useCreatePlaceMutation,
  useUpdatePlaceMutation,
  useDeletePlaceMutation,
  useCreatePlacePrivateInfoMutation,
} = placeApi;
