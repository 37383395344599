import React from 'react';
import {
  Alert,
  AlertTitle,
  Grow,
  GrowProps,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { GlobalVariables } from 'config/constant';
import { useTranslation } from 'react-i18next';
import { ESnackBarSeverity } from 'types/interfaces/SnachBar';
import { GetSnackConfig, setSnackBarConfig } from 'redux/slices/app/appSlice';

function GrowTransition(props: GrowProps) {
  return <Grow {...props} />;
}

export function CustomSnack() {
  const dispatch = useDispatch();
  const snackConfig = useSelector(GetSnackConfig);
  const handleClose = () => {
    dispatch(setSnackBarConfig({ open: false, message: '' }));
  };
  const { t } = useTranslation();

  return (
    <Snackbar
      autoHideDuration={GlobalVariables.Alerts.DefaultDuration}
      open={snackConfig.open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent={GrowTransition}
    >
      <Alert severity={snackConfig.severity} onClose={handleClose}>
        <AlertTitle>{t(snackConfig.title ?? '')}</AlertTitle>
        <Typography variant="body2">{t(snackConfig.message ?? '')}</Typography>
      </Alert>
    </Snackbar>
  );
}
