import { createSlice } from '@reduxjs/toolkit';
import { UserFormInitialState } from './userFormSlice.type';
import { GoalsFormApi } from 'redux/api/user/UserForm/GoalsUserForm/goalsFormApi';
import { ActivityUserForm } from 'redux/api/user/UserForm/ActivityUserForm/activityUserFormApi';
import { LevelFormApi } from 'redux/api/user/UserForm/LevelUserForm/levelFormApi';

const INITIAL_STATE: UserFormInitialState = {
  goals: [],
  sports: [],
  levels: [],
};

const userFormSlice = createSlice({
  name: 'userForm',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(GoalsFormApi.endpoints.getGoals.matchFulfilled, (state, { payload }) => {
      return {
        ...state,
        goals: payload.data,
      };
    });

    builder.addMatcher(
      ActivityUserForm.endpoints.getActivities.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          sports: payload.data,
        };
      },
    );

    builder.addMatcher(LevelFormApi.endpoints.getLevels.matchFulfilled, (state, { payload }) => {
      return {
        ...state,
        levels: payload.data,
      };
    });
  },
});

export default userFormSlice.reducer;
