import {
  CreateExternalAccount,
  CreateExternalAccountRequest,
  ResponseData,
  transformedRequiredFields,
} from 'redux/api/stripe/stripeApi.type';

export const encodeAddExternalAccountRequest = (
  body: CreateExternalAccountRequest,
): CreateExternalAccount => {
  return { ...body, routing_number: body.routingNumber, account_number: body.accountNumber };
};

export const transformStripeRequiredFields = (
  response: ResponseData,
): transformedRequiredFields => {
  return {
    sections: response.sections.map((Section) => {
      const secondSubSection = Section?.sub_sections?.map((subS) => {
        return {
          id: subS.id,
          name: subS.name,
          subSections: subS.sub_sections,
        };
      });
      return {
        id: Section.id,
        name: Section.name,
        subSections: secondSubSection,
      };
    }),
  };
};
