import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { BORouteIdEnum } from 'config/enums';

// Lazy loaded pages
const UsersPage = lazy(() => import('pages/UsersPage/UsersPage'));
const UsersSection = lazy(() => import('pages/UsersPage/UsersSection/UsersSection'));
const ConsultUser = lazy(() => import('pages/UsersPage/UsersSection/ConsultUser/ConsultUser'));
const NextCourseDetails = lazy(
  () => import('pages/UsersPage/UsersSection/NextCourseDetails/NextCourseDetails'),
);
const CommandsSectionByUser = lazy(
  () => import('pages/UsersPage/CommandsSectionByUser/CommandsSectionByUser'),
);
const CommandsSection = lazy(() => import('pages/UsersPage/CommandsSection/CommandsSection'));
const FormSection = lazy(() => import('pages/UsersPage/FormSection/FormSection'));

const UserRoutes = {
  path: BORouteIdEnum.Users,
  element: <UsersPage />,
  children: [
    {
      path: BORouteIdEnum.Root,
      element: <Navigate to={BORouteIdEnum.all} />,
    },
    {
      path: BORouteIdEnum.all,
      children: [
        { path: BORouteIdEnum.Root, element: <UsersSection /> },
        {
          path: BORouteIdEnum.ViewId,
          children: [
            { path: BORouteIdEnum.Root, element: <ConsultUser /> },
            { path: BORouteIdEnum.UserCourseId, element: <NextCourseDetails /> },
            {
              path: BORouteIdEnum.Commands,
              children: [{ path: BORouteIdEnum.Root, element: <CommandsSectionByUser /> }],
            },
          ],
        },
      ],
    },
    {
      path: BORouteIdEnum.Commands,
      children: [
        {
          path: BORouteIdEnum.Root,
          element: <CommandsSection />,
        },
      ],
    },
    {
      path: BORouteIdEnum.Form,
      children: [
        {
          path: BORouteIdEnum.Root,
          element: <FormSection />,
        },
      ],
    },
  ],
};

export default UserRoutes;
