export enum OrderTypeEnum {
  All = 0,
  Shop = 1,
  Private = 2,
  Public = 3,
  SportProgram = 4,
  VOD = 5,
  ProgramGeneral = 6,
  ProgramCustom = 7,
}

export enum OrderLabelsEnum {
  All = 'command.all_types',
  Shop = 'command.shop',
  Private = 'command.private',
  Public = 'command.public',
  SportProgram = 'command.sport_program',
  VOD = 'command.VOD',
  ProgramGeneral = 'command.general_program',
  ProgramCustom = 'command.custom_program',
}

export enum OrdersStatusEnum {
  WAITING_PAYMENT = 0,
  PAYMENT_MADE = 1,
  IN_PROGRESS = 2,
  IN_DELIVERY = 3,
  COMPLETED = 4,
  REFUND = 5,
  ENDED_REFUNDED = 6,
  PAYMENT_FAILED = 7,
}
