import { transformApiResponse } from 'redux/api/BaseApiTransform';
import { transformApiMediaType } from 'redux/api/commonTypes/file';
import {
  ApiSportType,
  EncodedSportFormRequest,
  SportFormEncodedObject,
  SportFormRequest,
  SportType,
} from 'redux/api/user/UserForm/ActivityUserForm/activityUserForm.type';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { transformTranslatedKey } from '../userForm.transform';

{
  /** *** SPORT ****/
}
export const transformSport = (sport: ApiSportType): SportType => {
  const { id, is_asc, translated_key, icon, translation_key_id } = sport;
  return {
    id: id,
    image: transformApiMediaType(icon ?? ''),
    isAsc: is_asc,
    translatedName: translated_key ? `sports.${translated_key?.name}` : '',
    translationKeyId: translation_key_id,
    translatedKey: transformTranslatedKey(translated_key),
  };
};

export const transformSportsListResponse = (
  response: ApiArrayDataResponse<ApiSportType>,
): ArrayDataResponse<SportType> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((sport) => {
      return transformSport(sport);
    }),
  };
};

export const encodeSportFormRequest = (request: SportFormRequest): EncodedSportFormRequest => {
  const { values, icon } = request;

  const data: SportFormEncodedObject[] = [];

  Object.keys(values).forEach((key) => {
    if (key.startsWith('activity-')) {
      const languageId = key.split('-')[1];
      data.push({
        language_id: parseInt(languageId),
        value: values[key],
      });
    }
  });

  return {
    icon,
    data,
  };
};
