const TRY_AGAIN = 'Veuillez réessayer.';
const FORBIDDEN_ACTION = 'Action interdite';

export const error = {
  image: {
    image_is_required: "L'image est obligatoire",
  },
  error: 'Erreur',
  swh: 'Une erreur est survenue! Veuillez réessayer ultérieurement.',
  SWH: 'Veuillez réessayer ultérieurement.',
  SWH_title: 'Une erreur est survenue!',
  FETCH_ERROR: "Impossible d'établir une connexion au réseau. Veuillez réessayer ultérieurement.",
  FETCH_ERROR_title: 'Erreur de connexion au réseau',
  IDENTIFIER_UNIQUE_title: 'Identifiant unique ',
  IDENTIFIER_UNIQUE: "L'identifiant doit être unique",
  EMAIL_UNIQUE_title: 'Email invalide',
  EMAIL_UNIQUE: "L'email doit être unique",
  PASSWORD_REQUIRED_title: 'mot de passe obligatoire',
  PASSWORD_REQUIRED: 'Le mot de passe est obligatoire',
  INVALID_OTP_title: 'Le code est invalide',
  INVALID_OTP: 'Votre code de vérification est invalide',
  CAN_NOT_LOGIN_title: 'Impossible de se connecter',
  CAN_NOT_LOGIN: "Votre mot de passe est incorrect ou ce compte n'existe pas.",
  'IDENTIFIER_UNIQUE,EMAIL_UNIQUE': "L'identifiant et l'email doivent être unique",
  Time_failed: "La date et l'heure de fin doivent être supérieures à la date et l'heure de début",
  Start_Time_greater_than_actual_time:
    "La date et l'heure de début doivent être supérieures à la date et l'heure actuelle ",
  End_Time_greater_than_actual_time:
    "La date et l'heure de fin doivent être supérieures à la date et l'heure actuelle ",
  Notification_Time_greater_than_actual_time:
    "La date et l'heure de la notification doivent être supérieures à la date et l'heure actuelle ",
  END_TIME_OVERLAP_WITH_EXISTING_title: 'Plage horaire déjà existante',
  END_TIME_OVERLAP_WITH_EXISTING: 'Plage horaire déjà existante dans la base de donnée',
  END_TIME_THE_END_TIME_MUST_BE_GREATER_THAN_THE_START_TIME:
    'La date de début doit être antérieure à la date de fin.',
  PHONE_UNIQUE_title: 'Le numéro de téléphone est unique',
  PHONE_UNIQUE: 'Le numéro de téléphone a déjà été pris',
  SIRET_UNIQUE_title: 'Le numéro de SIRET est unique',
  SIRET_UNIQUE: 'Le numéro de SIRET a déjà été pris',
  group_required: 'Ajouter un groupe de coach au moins pour un seul groupe',
  required_target: 'Sélectionner  au moins un cible',
  EVENT_NOT_FOUND_title: "ce coach n'est pas affecté à toi",
  EVENT_NOT_FOUND: "Vous n'êtes pas autorisé à modifier le rendez-vous de ce coach",
  UNAVAILABLE_EXPERT_title: 'Créneau déjà réservé',
  UNAVAILABLE_EXPERT: 'Ce créneau est déjà réservé par un autre coach',
  MY_REASON_MAX_title: 'La raison est trop long',
  MY_REASON_MAX: 'La longueur maximale autorisée est de 150 caractères.',
  ACTION_FORBIDDEN_title: 'Non autorisé',
  ACTION_FORBIDDEN:
    "Vous n'êtes pas autorisé à modifier le rendez-vous d'un coach qui est affecté à un autre expert.",
  START_DATE_IMPOSSIBLE_DE_METTRE_à_JOUR_LES_éLéMENTS_TERMINéES_title:
    'Impossible de mettre à jour ',
  START_DATE_IMPOSSIBLE_DE_METTRE_à_JOUR_LES_éLéMENTS_TERMINéES:
    'Impossible de mettre à jour les popups terminés',
  SERVER_ERROR_title: 'Erreur Serveur',
  SERVER_ERROR: 'Une erreur est survenue!',
  "WE_COULDN'T_FIND_THE_BANK_FOR_THAT__title": 'Impossible de trouver le compte bancaire',
  "WE_COULDN'T_FIND_THE_BANK_FOR_THAT_": 'Impossible de trouver le compte bancaire',
  INVALID_BIC: 'Veuillez saisir un BIC valide',
  INVALID_BIC_title: 'Le numéro BIC est invalide',
  INVALID_IBAN: 'Veuillez saisir un IBAN valide',
  INVALID_IBAN_title: 'Le numéro IBAN est invalide',
  INVALID_BE_POSTAL_CODE_title: 'Le code postal est invalide',
  INVALID_FR_POSTAL_CODE_title: 'Le code postal en France est invalide.',
  INVALID_FR_POSTAL_CODE: 'Veuillez vérifier le code postal.',
  COUNTRY_ID_REQUIRED_title: 'Pays requis',
  COUNTRY_ID_REQUIRED: 'Veuillez ajouter le pays',
  'INVALID_BANK_COUNTRY_FR_FOR_CURRENCY_AUD._THERE_ARE_NO_VALID_COUNTRIES_THAT_YOU_ARE_ABLE_TO_USE_FOR_THIS_CURRENCY.':
    'Pays de banque non valide pour la devise choisit ',
  'INVALID_BANK_COUNTRY_FR_FOR_CURRENCY_AUD._THERE_ARE_NO_VALID_COUNTRIES_THAT_YOU_ARE_ABLE_TO_USE_FOR_THIS_CURRENCY._title':
    "Le pays est n'est pas valide pour la devise que vous avez choisit",
  'ROUTING_NUMBERS_SHOULD_NOT_BE_PROVIDED_FOR_BANK_ACCOUNTS_IN_MX.':
    'Les numéros de routage ne doivent pas fournir pour les comptes bancaires en Mexique.',
  'ROUTING_NUMBERS_SHOULD_NOT_BE_PROVIDED_FOR_BANK_ACCOUNTS_IN_MX._title': 'Numéros de routage',
  ACCOUNT_TYPE_INVALID_title: 'Type de compte invalide',
  ACCOUNT_TYPE_INVALID: 'Le type de compte de la salle est invalide',
  DAYS_OVERLAP_WITH_EXISTING_INTERVALS_title:
    'Un problème de chevauchement de plage horaire a été identifié.',
  DAYS_OVERLAP_WITH_EXISTING_INTERVALS: 'On ne peut pas ajouter des créneaux successifs.',
  account_number_invalid_title: 'Le numéro de compte est invalide',
  account_number_invalid: 'Veuillez entrer un numéro de compte valide',
  routing_number_invalid_title: 'BIC est invalide',
  routing_number_invalid: 'Veuillez entrer un BIC valide',
  EXERCISE_NOT_FOUND: TRY_AGAIN,
  EXERCISE_NOT_FOUND_title: 'Exercice non trouvé',
  BODY_PARTS_REQUIRED: '',
  BODY_PARTS_REQUIRED_title: 'Le champ "Zone du corps" est obligatoire.',
  BODY_AREA_REQUIRED: '',
  BODY_AREA_REQUIRED_title: 'Le champ "Zone de travail" est obligatoire.',
  INSTRUCTIONS_REQUIRED: 'Veuillez taper au moins un conseil pour votre exercice',
  INSTRUCTIONS_REQUIRED_title: 'Les consignes sont obligatoires.',
  UNKNOWN_FILE_MIME_TYPE: TRY_AGAIN,
  UNKNOWN_FILE_MIME_TYPE_title: 'Type de fichier inconnu.',
  NAME_YOU_ARE_NOT_ALLOWED_TO_UPDATE_NAME_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM:
    "Vous n'êtes pas autorisé à mettre à jour le nom car cet exercice est utilisé dans un programme acheté.",
  NAME_YOU_ARE_NOT_ALLOWED_TO_UPDATE_NAME_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM_title:
    FORBIDDEN_ACTION,
  MUSCLE_GROUP_YOU_ARE_NOT_ALLOWED_TO_UPDATE_MUSCLE_GROUP_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM:
    "Vous n'êtes pas autorisé à mettre à jour le groupe musculaire car cet exercice est utilisé dans un programme acheté.",
  MUSCLE_GROUP_YOU_ARE_NOT_ALLOWED_TO_UPDATE_MUSCLE_GROUP_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM_title:
    FORBIDDEN_ACTION,
  BODY_AREA_YOU_ARE_NOT_ALLOWED_TO_UPDATE_BODY_AREA_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM:
    "Vous n'êtes pas autorisé à mettre à jour la zone du corps car cet exercice est utilisé dans un programme acheté.",
  BODY_AREA_YOU_ARE_NOT_ALLOWED_TO_UPDATE_BODY_AREA_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM_title:
    FORBIDDEN_ACTION,
  EQUIPMENT_YOU_ARE_NOT_ALLOWED_TO_UPDATE_EQUIPMENT_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM:
    "Vous n'êtes pas autorisé à mettre à jour les équipements car cet exercice est utilisé dans un programme acheté.",
  EQUIPMENT_YOU_ARE_NOT_ALLOWED_TO_UPDATE_EQUIPMENT_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM_title:
    FORBIDDEN_ACTION,
  OTHER_CATEGORY_YOU_ARE_NOT_ALLOWED_TO_UPDATE_OTHER_CATEGORY_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM:
    "Vous n'êtes pas autorisé à mettre à jour les autres catégories car cet exercice est utilisé dans un programme acheté.",
  OTHER_CATEGORY_YOU_ARE_NOT_ALLOWED_TO_UPDATE_OTHER_CATEGORY_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM_title:
    FORBIDDEN_ACTION,
  INSTRUCTIONS_YOU_ARE_NOT_ALLOWED_TO_UPDATE_INSTRUCTIONS_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM:
    "Vous n'êtes pas autorisé à mettre à jour les consignes car cet exercice est utilisé dans un programme acheté.",
  INSTRUCTIONS_YOU_ARE_NOT_ALLOWED_TO_UPDATE_INSTRUCTIONS_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM_title:
    FORBIDDEN_ACTION,
  BODY_PARTS_YOU_ARE_NOT_ALLOWED_TO_UPDATE_BODY_PARTS_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM:
    "Vous n'êtes pas autorisé à mettre à jour la zone de travail car cet exercice est utilisé dans un programme acheté.",
  BODY_PARTS_YOU_ARE_NOT_ALLOWED_TO_UPDATE_BODY_PARTS_BECAUSE_THIS_EXERCISE_IS_USED_IN_A_PURCHASED_PROGRAM_title:
    FORBIDDEN_ACTION,
  EXPIRED_OTP_title: 'Code expiré',
  EXPIRED_OTP: 'Le code que vous avez saisi a expiré.',
  COUNTRY_CODE_IN_title: 'Le code ISO est invalid',
  COUNTRY_CODE_IN: 'Veuillez saisir un code ISO valid',
  CAN_NOT_LOGIN_helper_text: 'Email ou mot de passe incorrect',
  SIRET_UNIQUE_helper_text: 'Le numéro de SIRET a déjà été pris',
  EMAIL_UNIQUE_helper_text: 'Email déjà utilisé',
  PHONE_UNIQUE_helper_text: 'Le numéro de téléphone a déjà été pris',
  GENDER_IN_title: 'Le sexe est  requis',
};
