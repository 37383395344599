export const trackingLink = {
  create_tracking_link: 'Créer le lien de tracking',
  add_tracking_link_description:
    "Entrez l'URL de votre landing de campagne afin de générer un lien que vous pourrez mettre sur toutes les publicités",
  title: 'Création lien de tracking campagne',
  placeholder: ' https://ascclientdev.com/...',
  link_generated_success: 'Lien de suivi généré avec succès.',
  add_tracking_campaign: "Création d'une campagne de tracking",
  compaign_for: 'Cette campagne est pour?',
  Show_detail_tracking_campaign: 'Détails',
  form: {
    title: 'Titre de la campagne',
    url: 'URL',
    shortUrl: 'Lien de tracking de votre campagne',
    title_placeholder: 'Saisissez le titre de la campagne',
  },
  add_success_trackingLink: 'Lien de tracking a été ajouté avec succès',
  required_target: 'Cible est requis',
  social_Networks: 'réseaux sociaux',
  numbers: 'Nombre',
  total_Clics: 'Total clics',
  total_creation_compte: 'Total création compte',
  number_of_payments_made: 'Nombre de paiement effectué',
  conversion_rate: 'Taux de conversion',
  confirmation_rate: 'Taux de confirmation',
  rate: 'Taux',
  percent: 'Pourcentage',
  statistic: {
    googleAds: 'Google Ads',
    Statistic: 'Statistiques',
    instagram: 'Instagram',
    facebook: 'Facebook',
    tiktok: 'Tiktok',
    bingAds: 'Bing Ads',
    pinterest: 'Pinterest',
    linkedin: 'Linkedin',
    twitter: 'Twitter',
  },
};
