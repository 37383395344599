import { isUndefined } from 'lodash';
import { arrayToParams } from 'utils/services/api.service';

export const apiUrl = '/';

export enum ApiUrlsEnum {}

// Inject params in a request
export function injectParams(url: string, object: Record<string, unknown>): string {
  let result = `${url}?`;
  // Get all keys from object
  const keys = Object.keys(object);
  // Iterate over keys
  keys.forEach((key, index) => {
    const value = object[key];
    if (Array.isArray(value)) {
      result += arrayToParams(key, value);
    } else if (!isUndefined(value)) {
      // Adding key=value to result
      result += `${key}=${value}`;
      // Adding & if it's not the last param
      if (index < keys.length - 1) {
        result += '&';
      }
    }
  });
  // Remove the last "&"" from string
  if (result.endsWith('&')) {
    result = result.slice(0, -1);
  }
  return result;
}

// TODO: replace this function with joinPaths from path.helpers.ts
export function injectParameters(endpoints: string[]): string {
  return `${endpoints.join('/')}`;
}

export function extractXAmzExpires(url: string | null): number {
  if (!url) return 30000;

  const urlObj = new URL(url);

  // Get the search parameters from the URL
  const searchParams = urlObj.searchParams;

  // Extract the "x-amz-expires" parameter value
  const xAmzExpires = searchParams.get('X-Amz-Expires');

  return Number(xAmzExpires) || 30000;
}
