import { alpha } from '@mui/material';

export const ColorsConfig = {
  primary: {
    light: '#AC76EB',
    main: '#755BE5',
    dark: '#434265',
  },
  secondary: {
    light: '#BE4FE4',
    main: '#ED54CD',
    dark: '#66415e',
  },
  info: {
    light: '#DEE4F0',
    main: '#0787FF',
  },
  success: {
    light: '#dcfbf1',
    main: '#5AEDC4',
  },
  warning: {
    main: '#ED5454',
  },
  error: {
    light: '#FEEFEF',
    main: '#DA1414',
    dark: '#9B0000',
  },

  grey: {
    50: '#FFFFFF',
    100: '#FCFCFD',
    200: '#F5F7FB',
    300: '#ECEDF1',
    400: '#ACB3BD',
    500: '#ABB2BC',
    600: '#AAB6CE',
    800: '#252427',
    900: '#000000',
  },

  text: {
    primary: '#252427',
    disabled: alpha('#252427', 0.7),
  },

  actions: {
    disabledBackground: 'rgba(159, 165, 172, 0.5)',
    disabled: '#FFFFFF',
    hover: '#F8F9FC',
  },

  background: { paper: '#FFFFFF', default: '#FCFCFD', neutral: '#FFFFFF' },
};

export const DarkColorsConfig = {
  primary: {
    main: '#755BE4',
  },
  secondary: {
    main: '#FFFFFF',
  },
  info: {
    light: '#DEE4F0',
    main: '#2E5AAC',
    dark: '#043184',
  },
  success: {
    light: '#EDF9F0',
    main: '#287D3C',
    dark: '#085A1B',
  },
  warning: {
    light: '#f9853d',
    main: '#b95000',
    dark: '#692D00',
  },
  error: {
    light: '#FEEFEF',
    main: '#DA1414',
    dark: '#9B0000',
  },

  grey: {
    50: '#FFFFFF',
    100: '#FCFCFD',
    200: '#F5F7FB',
    300: '#ECEDF1',
    400: '#ACB3BD',
    500: '#ABB2BC',
    600: '#AAB6CE',
    800: '#252427',
    900: '#000000',
  },

  text: {
    primary: '#FFFFFF',
  },

  actions: {
    disabledBackground: 'rgba(159, 165, 172, 0.5)',
    disabled: '#FFFFFF',
  },

  background: { paper: '#211F24', default: '#201E23', neutral: '#585959' },
};

export const PublicTrainingColorsConfig = {
  primary: {
    light: '#ED54CC',
    main: '#BE4FE5',
    dark: '#66415e',
  },

  actions: {
    hover: '#F9EEFD',
  },
};

export const SportProgramColorsConfig = {
  primary: {
    light: '#4DD6F0',
    main: '#0787FF',
    dark: '#3f5263', // TODO - get from design
  },
};

export const VODColorsConfig = {
  primary: {
    light: '#FF7F7F', // TODO - get from design
    main: '#ED5454',
    dark: '#5e3a3a',
  },
};

export const RetirementColorsConfig = {
  primary: {
    light: '#88F4DB', // TODO - get from design
    main: '#5AEDC4',
    dark: '#36574e', // TODO - get from design
  },
};

export const colorsApexChartConfig = [
  ColorsConfig.primary.light,
  ColorsConfig.secondary.light,
  SportProgramColorsConfig.primary.main,
  VODColorsConfig.primary.light,
  ColorsConfig.grey[600],
  RetirementColorsConfig.primary.light,
];

export const OverViewBlocConfig = [
  ColorsConfig.primary.light,
  ColorsConfig.secondary.main,
  ColorsConfig.success.main,
  ColorsConfig.secondary.light,
];
