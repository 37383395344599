import { MediaEnum } from 'config/enums/media.enum';

export const fileSizeToMb = (size: number, fixedNumber?: number) => {
  const sizeInMb = size / 1024 / 1024;
  return sizeInMb > 0.01 ? sizeInMb.toFixed(fixedNumber || 0) : 0.01;
};

export const toBase64 = async (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string).split(',')[1]);
    reader.onerror = (error) => reject(error);
  });
};

export const toBase64Array = async (files: File[]): Promise<string[]> => {
  return Promise.all(files.map((file) => toBase64(file)));
};

export const acceptedTypesToString = (acceptedTypes: string[]) => {
  return acceptedTypes.map((type) => type).join(', ');
};
export const getMediaType = (file: File) => {
  if (file?.type?.startsWith('image/')) {
    return MediaEnum.Image;
  } else if (file.type.startsWith('video/')) {
    return MediaEnum.Video;
  } else if (file.type.startsWith('application/pdf')) {
    return MediaEnum.Pdf;
  } else {
    return MediaEnum.Other;
  }
};
export const mimeTypeToMediaEnum = (mimeType: string): MediaEnum => {
  switch (true) {
    case mimeType.startsWith('image/'):
      return MediaEnum.Image;
    case mimeType.startsWith('video/'):
      return MediaEnum.Video;
    case mimeType.startsWith('application/pdf'):
      return MediaEnum.Pdf;
    default:
      return MediaEnum.Other;
  }
};
export const fileToUrl = (file: File | Blob | MediaSource) => {
  return URL.createObjectURL(file);
};
