import { Box, styled } from '@mui/material';

export const LanguageFlagBoxRoot = styled(Box)(({ flagUrl }: { flagUrl?: string }) => ({
  backgroundImage: `url(${flagUrl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: 25,
  height: 25,
  borderRadius: '50%',
}));
