import { Theme, alpha } from '@mui/material';

const infoStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.info.light,
  color: theme.palette.info.dark,
  border: `1px solid ${theme.palette.info.dark}`,
  button: {
    borderColor: theme.palette.info.dark,
    color: theme.palette.info.dark,
    ':hover': {
      borderColor: theme.palette.info.dark,
      backgroundColor: alpha(theme.palette.info.dark, 0.5),
    },
  },
  svg: {
    color: theme.palette.info.dark,
  },
});

const successStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.dark,
  border: `1px solid ${theme.palette.success.dark}`,
  svg: {
    color: theme.palette.success.dark,
  },
});

const errorStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.dark,
  border: `1px solid ${theme.palette.error.dark}`,
  svg: {
    color: theme.palette.error.dark,
  },
});

export default function Alert(theme: Theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        standardInfo: infoStyles(theme),
        standardSuccess: successStyles(theme),
        standardError: errorStyles(theme),
        icon: {
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
  };
}
