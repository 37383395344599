export const filters = {
  categories: 'Catégories',
  all_categories: 'Toutes les catégories',
  body_areas: 'Zones de corps',
  upper_body: 'Haut du corps',
  lower_body: 'Bas du corps',
  abdominal_belt: 'Ceinture abdominale',
  muscle_group: 'Groupe musculaire',
  shoulders: 'Épaules',
  biceps: 'Biceps',
  triceps: 'Triceps',
  pecs: 'Pectoraux',
  back: 'Dorsaux',
  abs: 'Abdominaux',
  buttocks: 'Fessiers',
  quadriceps: 'Quadriceps',
  hamstrings: 'Ischio-jambes',
  calves: 'Mollets',
  equipment: 'Équipement',
  bars: 'Barres',
  pulleys: 'Poulies',
  machinery: 'Machines',
  dumbbells: 'Haltères',
  to_body_weight: 'Au poids de corps',
  kettle_bell: 'Cloche de bouilloire',
  resistance_bands: 'Bandes de résistance',
  landmine: 'Landmine',
  machine_smith: 'Machine smith',
  hanging_straps: 'Sangles de suspension',
  battle_rope: 'Corde de combat',
  power_sled: 'Power sled',
  rower: 'Rameur',
  spinning_bike: 'Vélo spinning',
  jumping_rope: 'Corde à sauter',
  assault_bike: 'Assault bike',
  elliptical_trainer: 'Vélo elliptique',
  treadmill: 'Tapis de course',
  assault_runner: 'Assault runner',
  stair_machine: 'Stair machine',
  stepper: 'Stepper',
  box: 'Boxe',
  wall_ball: 'Wall ball',
  skierg: 'Skierg',
  other_categories: 'Autres catégories',
  cardio_yoga: 'Cardio Yoga',
  posture_exercises: 'Posture de yoga',
};
