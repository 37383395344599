import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { BestProductsApi, BestProductsOptions } from './bestProducts.type';
import { transformApiResponse } from 'redux/api/BaseApiTransform';
import { BestProductsDetails } from 'features/common/BestProductsList/BestProductsList.type';

export const transformBestProductsApiOptions = (options?: BestProductsOptions) => {
  return {
    coach_id: options?.coachId,
    months: options?.months,
  };
};

export const transformBestProductsResponse = (
  response: ApiArrayDataResponse<BestProductsApi>,
): ArrayDataResponse<BestProductsDetails> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((bestProduct) => {
      return {
        id: bestProduct.id,
        coachId: bestProduct.coach_id,
        productId: bestProduct.product_id,
        type: bestProduct.type,
        ordersNumber: bestProduct.orders_number,
        quantitySold: bestProduct.quantity_sold,
        turnover: bestProduct.turnover,

        coach: {
          id: bestProduct.coach?.id,
          firstName: bestProduct.coach?.first_name,
          lastName: bestProduct.coach?.last_name,
          restDays: bestProduct.coach?.rest_days,
          profilePicture: bestProduct.coach?.profile_picture,
        },

        product: {
          id: bestProduct.product?.id,
          name: bestProduct.product?.name,
        },
      };
    }),
  };
};
