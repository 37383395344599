import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { transformMediaBody } from './mediaApi.transform';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import { ApiMediaType } from 'redux/api/commonTypes/file';

export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: fetchBaseQuery(baseQueryConfig),
  tagTypes: ['medias'],
  endpoints: (builder) => ({
    //  return media without the 4 variants
    uploadMedia: builder.mutation({
      query: (file: File) => ({
        url: `${ENDPOINTS.MEDIA}`,
        method: 'POST',
        body: transformMediaBody(file),
      }),
      transformResponse: (response: { data: string }) => response,
      invalidatesTags: ['medias'],
    }),
    //  return media with the 4 variants
    importMedia: builder.mutation({
      query: (file: File) => ({
        url: `${ENDPOINTS.UPLOAD_MEDIA}`,
        method: 'POST',
        body: transformMediaBody(file),
      }),
      transformResponse: (response: { data: ApiMediaType }) => response.data.id,
    }),
  }),
});

export const { useUploadMediaMutation, useImportMediaMutation } = mediaApi;
