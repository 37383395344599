export const sports = {
  box: 'Boxe',
  hiit: 'Hiit',
  yoga: 'Yoga',
  athletics: 'Athlétisme',
  judo: 'Judo',
  barre: 'Barre',
  body_building: 'Musculation',
  bootcamp: 'Bootcamp',
  cross_training: 'Entraînement croisé',
  cycling: 'Cyclisme',
  dance: 'Danse',
  football: 'Football',
  golf: 'Golf',
  jbb: 'JBB',
  karate: 'Karaté',
  lutte: 'Lutte',
  martial_arts: 'Arts martiaux',
  room_course: 'Cours en salle',
  running: 'Course à pieds',
  street_workout: 'Street workout',
  streching: 'Stretching',
  tennis: 'Tennis',
  training_and_fitness: 'Training and fitness',
  volley: 'Volley',
  pilates: 'Pilates',
  post_natal: 'Pré/Post natal',
  lifting_loads: 'Lever de charges',
  an_activity: 'Une activité',
};
