import { TranslationKeyApiParams } from 'features/Translation/Types/interfaces/TranslationKeyApiParams';

export const injectGetTranslationKeysToApi = (params: TranslationKeyApiParams) => {
  return {
    section_id: params.selectedItem.sectionId,
    group_id: params.selectedItem.groupId,
    platform_id: params.platformId,
    keyword: encodeURIComponent(
      params.queryParams.globalSearchText ? params.queryParams.globalSearchText : '',
    ),
    order_by: params.queryParams.orderBy,
    direction: params.queryParams.order,
    page: params.queryParams.page,
    per_page: params.queryParams.perPage,
  };
};
