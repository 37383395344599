import { Theme, alpha } from '@mui/material';
import { GlobalFonts } from 'config/constant/fonts.config';

export default function Pagination(theme: Theme) {
  return {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          padding: 0,
          width: 36,
          height: 36,
          minWidth: 36,
          color: theme.palette.text.primary,
          borderColor: 'transparent',
          backgroundColor: alpha(theme.palette.grey[300], 0.6),
          fontSize: '0.875rem',
          fontFamily: GlobalFonts.FONT_PRIMARY_500,
          fontWeight: 400,
          lineHeight: '1.25rem',
          borderRadius: 5,
          borderStyle: 'solid',
          borderWidth: 1,
          '&:hover, &.Mui-selected': {
            backgroundColor: alpha(theme.palette.primary.main, 0.09),
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
            opacity: 0.7,
          },
        },
      },
    },
  };
}
