import { Box, Stack } from '@mui/material';
import NavSection from 'layouts/DashboardLayout/SideBar/NavSection/NavSection';
import { navConfig } from 'layouts/DashboardLayout/SideBar/SideBar.constants';
import SwitchPaletteMode from 'layouts/DashboardLayout/SideBar/SwitchPaletteMode/SwitchPaletteMode';
import MobileNavbar from '../MobileNavbar';
import { DrawerHeader, DrawerStyle } from './DrawerMobile.style';
import { DrawerMobileProps } from './DrawerMobile.type';

function DrawerMobile({ open, handleDrawerClose }: DrawerMobileProps) {
  return (
    <DrawerStyle sx={{ display: { lg: 'none', md: 'none', xl: 'none' } }} anchor="top" open={open}>
      <DrawerHeader direction="row" justifyContent={'space-between'}>
        <MobileNavbar onClose={handleDrawerClose} />
      </DrawerHeader>

      <Stack justifyContent="space-between" height={'100%'} alignItems={'center'} overflow="auto">
        <Box width={260}>
          <NavSection navConfig={navConfig} isMini={false} />
        </Box>

        <Box justifyContent={'center'} alignItems={'center'} width="100%">
          <SwitchPaletteMode />
        </Box>
      </Stack>
    </DrawerStyle>
  );
}
export default DrawerMobile;
