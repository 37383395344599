export enum ReservationStatusEnum {
  NOT_CONFIRMED = 1,
  CONFIRMED = 2,
  CANCELED = 3,
  IN_CANCELATION = 4,
  CHANGE_DATE = 5,
  WAITING_FOR_DATE_VALIDATION = 6,
  WAITING_FOR_PAYMENT = 7,
}

export enum ReservationTypeEnum {
  EPR_RESERVATION = 1,
  PUBLIC_RESERVATION = 2,
}

export enum ReservationPaidStatusEnum {
  NOT_PAYED = 0,
  PAYED = 1,
}

export enum ReservationFormMode {
  CREATE = 'create',
  UPDATE = 'update',
  VIEW = 'view',
}

export enum EquipmentEnum {
  TOWEL = 1,
  SHOWER = 2,
  HAMAM = 3,
  LOCKER = 4,
  BODYBUILDING_EQUIPMENT = 5,
  BOX_EQUIPMENT = 6,
  YOGA_MAT = 7,
  SAUNA = 8,
  ATHLETIC_TRACK = 9,
  TREADMILL = 10,
  SKIPPING_ROPE = 11,
  POOL = 12,
}

export enum EquipmentNamesEnum {
  TOWEL = 'place.towel',
  SHOWER = 'place.shower',
  HAMAM = 'place.hamam',
  LOCKER = 'place.locker',
  BODYBUILDING_EQUIPMENT = 'place.bodybuilding_equipment',
  BOX_EQUIPMENT = 'place.box_equipment',
  YOGA_MAT = 'place.yoga_mat',
  SAUNA = 'place.sauna',
  ATHLETIC_TRACK = 'place.athletic_track',
  TREADMILL = 'place.treadmill',
  SKIPPING_ROPE = 'place.skipping_rope',
  POOL = 'place.pool',
}
