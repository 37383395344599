export const group = {
  delete_group_message: 'Vous souhaitez supprimer le group ?',
  delete_selected_groups_message: 'Vous souhaitez supprimer les groups sélectionnés',
  success_group_deleted: 'Group supprimé avec succès',
  success_group_deleted_message: 'Group a été supprimé avec succèes de la base de données',
  success_groups_deleted: 'Groups supprimés avec succès',
  success_groups_deleted_message: 'Groups ont été supprimés avec succèes de la base de données',
  create_coach_group: "Création d'un group de coach",
  update_coach_group: "Modification d'un group de coach",
  title: 'Titre du group',
  // add_group: 'Ajouter un group',
  add_group: 'Ajouter un group de coach',
  success_group_created: 'groupe a été ajouté avec succès',
  success_group_updated: 'groupe a été modifié avec succès',
  title_group: 'Les groupes',
  subTitle: 'Liste des groupes',
  search: 'Chercher un group',
};
