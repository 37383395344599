import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { OtpVerification } from 'types/models/Auth/Otp';
import { ResetPassword } from 'types/models/Auth/Password';
import { joinPaths } from 'utils';
import { transformResetPasswordBody } from './forgetPasswordApi.transform';

export const forgetPasswordApi = createApi({
  reducerPath: 'forgetPasswordApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    // send otp
    sendOtpCode: builder.mutation({
      query: (body: { email: string }) => ({
        url: joinPaths([ENDPOINTS.BO, ENDPOINTS.SEND_OTP_CODE]),
        method: 'POST',
        body,
      }),
    }),
    // verify otp code
    verifyOtpCode: builder.mutation({
      query: (body: OtpVerification) => ({
        url: joinPaths([ENDPOINTS.BO, ENDPOINTS.VERIFY_OTP_CODE]),
        method: 'POST',
        // TODO: transform data
        body,
      }),
    }),
    // Reset password
    resetPassword: builder.mutation({
      query: (body: ResetPassword) => ({
        url: joinPaths([ENDPOINTS.BO, ENDPOINTS.RESET_PASSWORD]),
        method: 'POST',
        body: transformResetPasswordBody(body),
      }),
    }),
  }),
});

export const { useSendOtpCodeMutation, useVerifyOtpCodeMutation, useResetPasswordMutation } =
  forgetPasswordApi;
