import { transformApiResponse } from 'redux/api/BaseApiTransform';
import {
  ApiLevelType,
  EncodedLevelFormObject,
  LevelFormRequest,
  LevelType,
} from 'redux/api/user/UserForm/LevelUserForm/levelFormApi.type';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { transformTranslatedKey } from '../userForm.transform';

{
  /** *** LEVELS ****/
}

export const transformLevels = (level: ApiLevelType): LevelType => {
  return {
    id: level.id,
    name: level.name,
    description: level.description,
    translatedDescription: level.translated_description,
    translatedName: level.translated_name,
    descriptionTranslationKeyId: level.description_translation_key_id,
    nameTranslationKeyId: level.name_translation_key_id,
    translationKeyName: transformTranslatedKey(level.translated_key),
    translatedKeyDescription: transformTranslatedKey(level.translated_key_description),
  };
};

export const transformLevelsListResponse = (
  response: ApiArrayDataResponse<ApiLevelType>,
): ArrayDataResponse<LevelType> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((level) => {
      return transformLevels(level);
    }),
  };
};

export const transformLevelRequest = (request: LevelFormRequest) => {
  const { values } = request;

  const languageData: EncodedLevelFormObject[] = [];
  const languageDescriptionData: EncodedLevelFormObject[] = [];

  Object.keys(values).forEach((key) => {
    if (key.startsWith('title')) {
      languageData.push({
        language_id: Number(key.split('-')[2]),
        value: values[key],
      });
    }

    if (key.startsWith('description')) {
      languageDescriptionData.push({
        language_id: Number(key.split('-')[2]),
        value: values[key],
      });
    }
  });

  return {
    language_data: languageData,
    language_description_data: languageDescriptionData,
  };
};
