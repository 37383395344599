export const helperPage = {
  // dashboard home
  why_needs_helpers: 'Pourquoi nous avons besion de ces informations ?',
  reassure_your_future_customers: 'Il est important pour nous de rassurer vos futures clients.',
  verification_of_your_information: `Nous mettons donc en place une vérification de vos informations pour vous permettre
  d'accéder à notre outils de gestion.`,
  we_therefore_ask_you: 'Nous vous demanderons donc :',
  your_siret_number: 'Votre numéro SIRET:',
  you_can_find_your_siret_number:
    'Vous pouvez retrouver votre numéro SIRET sur le site Infogreffe:',
  search_for_your_business: '1. Recherchez votre entreprise depuis la barre de recherche',
  click_on_your_company: '2. Cliquez sur votre entreprise pour voir le numéro SIRET',
  find_my_siret_number: 'Trouver mon numéro SIRET',
  your_professional_card: 'Votre carte professionnelle de coach',
  you_can_have_it_back: 'Vous pourrez la récupérer sur le site du gouvernement',
  find_my_professional_card: 'Trouvez ma carte professionnelle',
  we_send_you_mail:
    'Nous vous enverrons un e-mail pour vous envoyer le lien Calendly de votre rendez-vous.',
  you_will_have: 'Vous en aurez pour ',
  minutes_maximum: '10 minutes maximum.',
  see_you: 'A très bientôt,',
  asc_team: `L'équipe ASC`,
  reset_password_helper:
    'Envoyer à Aurlie-admin un lien pour rénitialiser son mot de passe . Cela ne changera pas son mot de passe , ni ne l`obligera à le modifier',
};
