import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP, APP_BAR_TABLET } from './DashboardLayout.constants';
import { ReactComponent as ASCLogo } from 'assets/icons/asc_logo.svg';

export const RootStyle = styled(Box)({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

export const MainStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100vh',
  paddingTop: APP_BAR_MOBILE + 15,
  paddingBottom: 15,
  paddingInline: '2.7%',
  [theme.breakpoints.up('md')]: {
    paddingTop: APP_BAR_TABLET + 15,
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 15,
  },
}));

export const ASCLogoStyle = styled(ASCLogo)(({ theme }) => ({
  fill: theme.palette.info.light,
}));

export const GridStyle = styled(Grid)(({ theme }) => ({
  width: '100%',
  paddingBottom: 5,

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const Ribbon = styled('div')({
  left: '-3px',
  top: '-3px',
  zIndex: 1,
  overflow: 'hidden',
  width: 75,
  height: 20,
  textAlign: 'right',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: '100%',
    zIndex: -1,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: '100%',
    zIndex: -1,
  },
});

export const RibbonSpan = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 'bold',
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  textAlign: 'center',
  lineHeight: '20px',
  letterSpacing: '2px',
  transform: 'rotate(45deg)',
  WebkitTransform: 'rotate(45deg)',
  width: 100,
  display: 'block',
  boxShadow: `0 3px 10px -5px ${theme.palette.common.black}`,
  position: 'absolute',
  top: 19,
  right: -21,
}));
