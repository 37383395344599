import { CountryFromApi } from 'redux/api/coach/coachApi.types';
import Country from 'types/models/Countries/Countries';

export const countriesList = (country: CountryFromApi): Country => {
  return {
    id: country.id,
    code: country.code,
    translatedName: country.translated_name,
    label: `COUNTRIES.${country?.translated_key?.name}`,
    defaultCurrency: country.default_currency,
  };
};
