import { Theme } from '@mui/material';
import Autocomplete from './Autocomplete';
import Backdrop from './Backdrop';
import Button from './Button';
import Link from './Link';
import Calendar from './Calendar';
import Card from './Card';
import Input from './Input';
import Paper from './Paper';
import Tabs from './Tabs';
import Tooltip from './Tooltip';
import Alert from './Alert';
import Dialog from './Dialog';
import Skeleton from './Skeleton';
import Avatar from './Avatar';
import Pagination from './Pagination';

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Button(theme),
    Link(theme),
    Card(theme),
    Tooltip(theme),
    Backdrop(theme),
    Input(theme),
    Tabs(theme),
    Autocomplete(theme),
    Paper(),
    Calendar(theme),
    Alert(theme),
    Dialog(),
    Skeleton(theme),
    Avatar(theme),
    Pagination(theme),
  );
}
