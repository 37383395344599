import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { BestProductsApi, BestProductsOptions } from './bestProducts.type';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import {
  transformBestProductsApiOptions,
  transformBestProductsResponse,
} from './bestProducts.transform';

export const bestProductsApi = createApi({
  reducerPath: 'bestProductsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['bestProducts'],
  endpoints: (builder) => ({
    getBestProducts: builder.query({
      query: (params: { paginator?: Paginator; options?: BestProductsOptions }) => {
        return injectParams(ENDPOINTS.BEST_PRODUCTS, {
          ...transformBestProductsApiOptions(params?.options),
          ...paginatorToApiPagination(params.paginator),
        });
      },
      transformResponse: (response: ApiArrayDataResponse<BestProductsApi>) => {
        return transformBestProductsResponse(response);
      },
      providesTags: ['bestProducts'],
    }),
  }),
});

export const { useGetBestProductsQuery } = bestProductsApi;
