import { ApiArrayDataResponse } from 'types/models/ApiResponse';

export function transformApiResponse<DataType>(apiResponse: ApiArrayDataResponse<DataType>) {
  const { current_page, first_page_url, from, last_page, last_page_url, per_page, to, total } =
    apiResponse;
  return {
    to,
    total,
    from,
    currentPage: current_page,
    firstPageUrl: first_page_url,
    lastPage: last_page,
    lastPageUrl: last_page_url,
    perPage: per_page,
  };
}
