import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import {
  TrackingListApi,
  TrackingOptions,
  trackingByIdFromApi,
} from 'redux/api/marketing/tracking/tracking.type';
import {
  transformTrackingLinkResponse,
  transformTrackingListOptions,
  transformTrackingListResponse,
} from 'redux/api/marketing/tracking/trackingApi.transform';

export const trackingApi = createApi({
  reducerPath: 'trackingApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['tracking'],
  endpoints: (builder) => ({
    getTracking: builder.query({
      query: (params: { paginator?: Paginator; options?: TrackingOptions }) => {
        return injectParams(ENDPOINTS.TRACKING, {
          ...transformTrackingListOptions(params.options),
          ...paginatorToApiPagination(params.paginator),
        });
      },
      transformResponse: (response: ApiArrayDataResponse<TrackingListApi>) => {
        return transformTrackingListResponse(response);
      },
      providesTags: ['tracking'],
    }),

    deleteTracking: builder.mutation({
      query: (body) => {
        return {
          url: ENDPOINTS.DELETE_TRACKING_LINK,
          method: 'DELETE',
          body,
        };
      },

      invalidatesTags: ['tracking'],
    }),
    createTrackingLink: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.TRACKING,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['tracking'],
    }),
    getTrackingLinkDetails: builder.query({
      query: (id: string) => `${ENDPOINTS.TRACKING}/${id}`,
      transformResponse: (response: { data: trackingByIdFromApi }) => {
        return transformTrackingLinkResponse(response.data);
      },
      providesTags: ['tracking'],
    }),
  }),
});

export const {
  useGetTrackingQuery,
  useDeleteTrackingMutation,
  useCreateTrackingLinkMutation,
  useGetTrackingLinkDetailsQuery,
} = trackingApi;
