import { LoginResponse, LoginResponseData } from 'types/models/Coach/coach';
import { toCamelCase } from 'utils/helpers/object.helpers';

export const transformLoginResponse = (data: LoginResponseData): LoginResponse => {
  const loginData = data.data;
  return {
    ...loginData,
    user: toCamelCase(loginData.user),
    token: loginData.token,
    refreshToken: loginData.refresh_token,
  };
};
