export const success = {
  user_deleted: "L'utilisateur a été supprimé avec succès.",
  users_deleted: 'Les utilisateurs ont été supprimés avec succès.',
  user_deleted_message: "L'utilisateur a été supprimé avec succès de la base de données.",
  users_deleted_message:
    'Les utilsateurs sélectionnés ont été supprimés avec succès de la base de données.',

  user_suspended: "L'utilisateur a été suspendu avec succès.",
  users_suspended: 'Les utilisateurs ont été suspendus avec succès.',
  user_suspended_message: "L'utilisateur a été suspendu avec succès de la base de données.",
  users_suspended_message:
    'Les utilsateurs sélectionnés ont été suspendus avec succès de la base de données.',

  user_reactivated: "L'utilisateur a été réactivé avec succès.",
  users_reactivated: 'Les utilisateurs ont été réactivés avec succès.',
  user_reactivated_message: "L'utilisateur a été réactivés avec succès de la base de données.",
  users_reactivated_message:
    'Les utilsateurs sélectionnés ont été réactivés avec succès de la base de données.',

  account_deleted: 'Le compte a été supprimé avec succès.',
  accounts_deleted: 'Les comptes ont été supprimés avec succès.',
  account_deleted_message: 'Le compte a été supprimé avec succès de la base de données.',
  accounts_deleted_message:
    'Les comptes sélectionnés ont été supprimés avec succès de la base de données.',

  account_created: 'Compte a été créé avec succès',
  account_updated: 'Compte a été modifié avec succès',

  place_created: 'La salle a été créée avec succès.',
  place_updated: 'La salle a été mises à jour avec succès.',
  place_deleted: 'La salle  a été supprimée avec succès.',

  exercise_deleted: "L'exercice a été supprimé avec succès.",

  pop_up_added: 'Pop-up a été ajoutée avec succés',
  pop_up_updated: 'Pop-up a été modifiée avec succés',

  pop_up_deleted: 'Popup a été supprimée avec succès.',
  pop_ups_deleted: 'Les popups ont été supprimées avec succès.',
  tracking_links_deleted: 'Les liens de tracking ont été supprimées avec succès.',
  pop_up_deleted_message: 'Popup a été supprimée avec succès de la base de données.',
  pop_ups_deleted_message:
    'Les popups sélectionnées ont été supprimées avec succès de la base de données.',
  tracking_links_deleted_message:
    'Les liens de tracking sélectionnés ont été supprimés avec succès de la base de données.',
  tracking_link_deleted: 'Lien a été supprimé avec succès.',
  tracking_link_deleted_message: 'Lien a été supprimé avec succès de la base de données.',
  campaign_added: 'Campagne a été ajoutée avec succés',
  campaign_updated: 'Campagne a été modifiée avec succés',

  campaign_deleted: 'Campagne a été supprimée avec succès.',
  campaigns_deleted: 'Les campagnes ont été supprimées avec succès.',
  campaign_deleted_message: 'Campagne a été supprimée avec succès de la base de données.',
  campaigns_deleted_message:
    'Les campagnes sélectionnées ont été supprimées avec succès de la base de données.',

  notification_deleted: 'Notification a été supprimée avec succès.',
  notifications_deleted: 'Les notifcations ont été supprimées avec succès.',
  notification_deleted_message: 'Notification a été supprimée avec succès de la base de données.',
  notifications_deleted_message:
    'Les notifications sélectionnées ont été supprimées avec succès de la base de données.',

  notification_added: 'Notification a été ajoutée avec succés',
  notification_updated: 'Notification a été modifiée avec succés',
};
