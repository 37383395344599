import { ENDPOINTS } from 'config/constant/endpoints.config';
import {
  transformAvailabilitiesResponse,
  transformAvailabilityToApiBody,
  transformExpertAvailabilitiesResponse,
} from './availabilityApi.transform';
import {
  ApiAvailability,
  ApiExpertAvailability,
  AvailabilitiesOptions,
} from './availabilityApi.type';
import { CoachAvailability } from 'types/models/Availability/Availability';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { coachApi } from 'redux/api/coach/coachApi';

export const availabilityApi = coachApi.injectEndpoints({
  endpoints: (builder) => ({
    getCoachAvailabilities: builder.query({
      query: () => ENDPOINTS.AVAILABILITIES,
      transformResponse: (response: { data: ApiAvailability }) => {
        return transformAvailabilitiesResponse(response);
      },
      providesTags: ['Availabilities'],
    }),
    // Create client by a coach
    updateCoachAvailabilities: builder.mutation({
      query: (availability: CoachAvailability) => ({
        url: ENDPOINTS.AVAILABILITIES,
        method: 'POST',
        body: transformAvailabilityToApiBody(availability),
      }),
      invalidatesTags: ['Availabilities'],
    }),
    // remaining availabilities
    getExpertAvailabilities: builder.query({
      query: (params: { paginator?: Paginator; options: AvailabilitiesOptions }) => {
        return injectParams(`${ENDPOINTS.EXPERT_AVAILIBILITIES}`, {
          ...paginatorToApiPagination(params.paginator),
          bo_user_id: params.options.id,
          start_date: params.options.startDate,
          end_date: params.options.endDate,
          timezone: params.options.timezone,
        });
      },
      transformResponse: (res: ApiArrayDataResponse<ApiExpertAvailability>) =>
        transformExpertAvailabilitiesResponse(res),
      providesTags: ['Availabilities'],
    }),
    rescheduleVisio: builder.mutation({
      query: (availability) => ({
        url: ENDPOINTS.APPOINTMENTS,
        method: 'POST',
        body: availability,
      }),
      invalidatesTags: ['Availabilities'],
    }),
  }),
});

export const {
  useGetCoachAvailabilitiesQuery,
  useUpdateCoachAvailabilitiesMutation,
  useGetExpertAvailabilitiesQuery,
  useRescheduleVisioMutation,
} = availabilityApi;
