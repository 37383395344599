import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { baseQueryWithReAuth } from '../BaseQueryConfig';
import {
  transformExpensesApiOptions,
  transformExpensesResponse,
  transformNextCourseResponse,
  transformUserResponse,
  transformUsersApiOptions,
  transformUsersResponse,
} from './userApi.transform';
import { ApiNextCourse, ApiUser, ApiUserDetails, UsersOptions } from './userApi.type';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { ApiExpenses } from '../commonTypes/expenses';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['users', 'user'],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params: { paginator?: Paginator; options: UsersOptions }) =>
        injectParams(ENDPOINTS.USERS, {
          ...transformUsersApiOptions(params.options),
          ...paginatorToApiPagination(params.paginator),
        }),

      transformResponse: (response: ApiArrayDataResponse<ApiUser>) => {
        return transformUsersResponse(response);
      },
      providesTags: ['users'],
    }),
    getUser: builder.query({
      query: ({ id }) => `${ENDPOINTS.USERS}/${id}`,
      transformResponse: (response: { data: ApiUserDetails }) => {
        return transformUserResponse(response.data);
      },
      providesTags: ['users'],
    }),

    deleteUsers: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.DELETE_USERS,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['users'],
    }),

    reactivateUsers: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.REACTIVATE_USERS,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['users'],
    }),
    suspendUsers: builder.mutation({
      query: (body) => {
        return {
          url: ENDPOINTS.SUSPEND_USERS,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['users'],
    }),

    getUserNextReservationById: builder.query({
      query: ({ id }) => `${ENDPOINTS.USERS}/${id}/${ENDPOINTS.NEXT_RESERVATION}`,
      transformResponse: (response: { data: ApiNextCourse }) => {
        return transformNextCourseResponse(response.data);
      },
      providesTags: ['users'],
    }),

    getUserExpenses: builder.query({
      query: (params: { id?: string; months: string }) =>
        injectParams(`${ENDPOINTS.USERS}/${params.id}/${ENDPOINTS.EXPENSES}`, {
          ...transformExpensesApiOptions(params.months),
        }),
      transformResponse: (response: { data: ApiExpenses }) => {
        return transformExpensesResponse(response.data);
      },
      providesTags: ['users'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useDeleteUsersMutation,
  useReactivateUsersMutation,
  useSuspendUsersMutation,
  useGetUserNextReservationByIdQuery,
  useGetUserExpensesQuery,
} = userApi;
