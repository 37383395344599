import { Box, styled } from '@mui/material';

export const SVGContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  ':hover': {
    svg: { fill: theme.palette.primary.main },
  },
}));
