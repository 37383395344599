export const product = {
  products: 'Produits',
  type: 'Type',
  no_products_yet: 'Aucun produit enregistré',
  name: 'Nom du produit',
  product_type: 'Type du produit',
  description: 'Description',
  // types
  types: {
    all_types: 'Tous les types',
    shop: 'Boutique',
    private: 'Entrainement privé',
    public: 'Cours collectif',
    sport_program: 'Programme sportif',
    VOD: 'VOD',
  },
  title: 'Les produits',
  subTitle: 'Liste des produits',
  search: 'Chercher un produit',
};
