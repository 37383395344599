import { Box, Drawer, Stack, useTheme } from '@mui/material';
import useResponsive from 'hooks/useResponsive';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ResponsiveQueryEnum } from 'types/interfaces/ResponsiveQuery';
import LogoSection from './LogoSection/LogoSection';
import NavSection from './NavSection/NavSection';
import { DRAWER_WIDTH, navConfig } from './SideBar.constants';
import { BoxStyle, RootStyle, getDrawerPaperStyle } from './SideBar.style';
import { SidebarProps } from './SideBar.type';
import SwitchPaletteMode from './SwitchPaletteMode/SwitchPaletteMode';
import { Ribbon, RibbonSpan } from 'layouts/DashboardLayout/DashboardLayout.style';
import { ColorsConfig } from 'config/constant/colors.config';
import { useTranslation } from 'react-i18next';

export default function SideBar({
  isOpenSidebar,
  onCloseSidebar,
  isMini,
  setIsMini,
}: SidebarProps) {
  const { pathname } = useLocation();
  const theme = useTheme();

  const isDesktop = useResponsive(ResponsiveQueryEnum.UP, 'lg');
  const isMobile = useResponsive(ResponsiveQueryEnum.UP, 'md');
  const { t } = useTranslation();
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Stack height={'100vh'} overflow="hidden" position="relative">
      <Ribbon>
        <RibbonSpan
          sx={{
            backgroundColor: ColorsConfig.primary.light,
          }}
        >
          {t('common.ribon_menu_title')}
        </RibbonSpan>
      </Ribbon>
      <BoxStyle height={'40vh'} width="100%" />
      <Box maxHeight={'calc(100vh - 220px)'}>
        <LogoSection isMini={isMini} />
        <NavSection isMini={isMini} navConfig={navConfig} />
      </Box>

      <Box
        justifyContent={'center'}
        alignContent={'center'}
        width="100%"
        position={'absolute'}
        bottom={0}
      >
        <SwitchPaletteMode />
      </Box>
    </Stack>
  );

  return (
    <RootStyle open={!isMini}>
      {!isDesktop && isMobile && (
        <Drawer
          onMouseEnter={() => setIsMini(false)}
          onMouseLeave={() => setIsMini(true)}
          open
          onClose={onCloseSidebar}
          variant="persistent"
          PaperProps={{
            style: isMini ? { ...getDrawerPaperStyle(theme) } : undefined,
            sx: {
              width: isMini ? 75 : DRAWER_WIDTH,
              transition: 'width 0.3s linear',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            style: {
              ...getDrawerPaperStyle(theme),
              width: DRAWER_WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
