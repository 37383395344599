import { Fade } from '@mui/material';
import useLanguage from 'hooks/useLanguage';
import { useCallback, useEffect } from 'react';
import { MenuStyle } from './LanguagesMenu.style';
import { LanguagesMenuProps } from './LanguagesMenu.type';
import LanguageMenuItem from '../LanguageMenuItem/LanguageMenuItem';
import { useAppSelector } from 'redux/hooks';

function LanguagesMenu({
  anchorEl,
  open,
  anchorOrigin,
  onChangeSelectedLanguage,
  handleClose,
}: LanguagesMenuProps) {
  const { languages } = useAppSelector((state) => state.translationReducer);

  const { language, handleChangeLanguage } = useLanguage();

  useEffect(() => {
    const selected = languages.find((l) => l.countryCode === language);
    if (selected) {
      onChangeSelectedLanguage?.(selected);
    }
  }, [language, languages]);

  const onMenuItemClick = useCallback((countryCode: string) => {
    handleChangeLanguage(countryCode);
    handleClose();
  }, []);

  return (
    <MenuStyle
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
      anchorOrigin={
        anchorOrigin ?? {
          vertical: 'bottom',
          horizontal: 'left',
        }
      }
    >
      {languages?.map(
        (lng, index) =>
          language !== lng.countryCode && (
            <LanguageMenuItem
              key={lng.id + lng.countryCode}
              languageCode={lng.countryCode}
              onClick={() => onMenuItemClick(lng.countryCode)}
              isNotLast={languages.length !== index + 1}
            />
          ),
      )}
    </MenuStyle>
  );
}

export default LanguagesMenu;
