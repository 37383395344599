import { Grid, Stack, Typography } from '@mui/material';
import { GlobalVariables } from 'config/constant';
import { GlobalFonts } from 'config/constant/fonts.config';
import useDialog from 'hooks/useDialog';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { dateToTimestamp, formatDate } from 'utils';
import { ASCLogoStyle, GridStyle, MainStyle, RootStyle } from './DashboardLayout.style';
import DashboardNavbar from './Navbar/Navbar';
import PageTitle from './Navbar/PageTitle/PageTitle';
import SearchBox from './Navbar/SearchBox/SearchBox';
import DashboardSidebar from './SideBar/SideBar';
import { useGetAccountByIdQuery } from 'redux/api/accounts/accountsApi';
import CustomBackdrop from 'components/CustomLoader/CustomBackdrop/CustomBackdrop';
import { useGetGoalsQuery } from 'redux/api/user/UserForm/GoalsUserForm/goalsFormApi';
import { useGetActivitiesQuery } from 'redux/api/user/UserForm/ActivityUserForm/activityUserFormApi';
import { useGetLevelsQuery } from 'redux/api/user/UserForm/LevelUserForm/levelFormApi';

export default function DashboardLayout() {
  const { isOpen, closeDialog } = useDialog();
  const { t } = useTranslation();
  const config = useAppSelector((state) => state.appReducer.navBarConfig);
  const [isMini, setIsMini] = useState(true);

  const expiresAfter = useAppSelector((state) => state.authReducer.profilePicture?.expiresAfter);
  const user = useAppSelector((state) => state.authReducer.user);

  useGetAccountByIdQuery(String(user?.id), {
    pollingInterval: expiresAfter,
  });
  useGetGoalsQuery(null);
  useGetActivitiesQuery(null);
  useGetLevelsQuery(null);

  return (
    <RootStyle>
      <DashboardNavbar isMini={isMini} />
      <DashboardSidebar
        isOpenSidebar={isOpen}
        isMini={isMini}
        setIsMini={setIsMini}
        onCloseSidebar={closeDialog}
      />
      <MainStyle>
        <GridStyle alignItems="center" spacing={2.5} container>
          <Grid item xs={12}>
            <PageTitle title={config.title} subtitle={config.subtitle} />
          </Grid>
          <Grid item xs={12}>
            <SearchBox
              searchPlaceholder={config.searchPlaceholder}
              searchText={config.searchText}
            />
          </Grid>
        </GridStyle>

        <Suspense fallback={<CustomBackdrop />}>
          <Outlet />
        </Suspense>

        <Stack alignItems="center" paddingBottom={7} paddingTop={8} spacing={1.5}>
          <ASCLogoStyle />
          <Typography variant="body2" color="info.light" fontFamily={GlobalFonts.FONT_PRIMARY_500}>
            {`©${formatDate(dateToTimestamp(), GlobalVariables.Date.YearFormat)} - ${t(
              'common.all_rights_reserved',
            )}`}
          </Typography>
        </Stack>
      </MainStyle>
    </RootStyle>
  );
}
