import { ExerciseCategoryEnum, ExerciseCategoryLabelsEnum } from 'config/enums/exercise.enum';
import { isUndefined } from 'lodash';

export const getFiltersParams = (filters?: Record<string, (string | number)[]>) => {
  if (!filters || isUndefined(filters) || !Object.keys(filters).length) return [];

  const result: number[] = [];
  const keys = Object.keys(filters);

  keys.forEach((key) => {
    const value = filters[key];
    value.forEach((v) => result.push(Number(v)));
  });

  return result;
};

export const getCategoryParams = (types?: ExerciseCategoryLabelsEnum[]) => {
  if (!types) {
    return {
      [ExerciseCategoryLabelsEnum.Category]: ExerciseCategoryEnum.Category,
    };
  }

  let params = {};
  types.forEach((type) => {
    switch (type) {
      case ExerciseCategoryLabelsEnum.BodyArea:
        params = {
          ...params,
          [ExerciseCategoryLabelsEnum.BodyArea]: ExerciseCategoryEnum.BodyArea,
        };
        break;
      case ExerciseCategoryLabelsEnum.Equipment:
        params = {
          ...params,
          [ExerciseCategoryLabelsEnum.Equipment]: ExerciseCategoryEnum.Equipment,
        };
        break;
      case ExerciseCategoryLabelsEnum.MuscleGroup:
        params = {
          ...params,
          [ExerciseCategoryLabelsEnum.MuscleGroup]: ExerciseCategoryEnum.MuscleGroup,
        };
        break;
      case ExerciseCategoryLabelsEnum.OtherCategories:
        params = {
          ...params,
          [ExerciseCategoryLabelsEnum.OtherCategories]: ExerciseCategoryEnum.OtherCategories,
        };
        break;
      case ExerciseCategoryLabelsEnum.Category:
        params = {
          ...params,
          [ExerciseCategoryLabelsEnum.Category]: ExerciseCategoryEnum.Category,
        };
        break;
      default:
        break;
    }
  });
  return params;
};
