import { Box, List, styled, Divider } from '@mui/material';

export const BorderStyle = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  height: 0,
}));
export const StyledList = styled(List)(({ theme }) => ({
  paddingInline: 15,
  maxHeight: '100%',
  overflow: 'auto',
}));
export const GroupDivider = styled(Divider)(({ theme }) => ({
  marginTop: '2vh',
  marginBottom: '2vh',
}));
