export const equipments = {
  shower: 'Douche',
  lockers: 'Casiers',
  body_building_equipment: 'Matériel de musculation',
  sauna: 'Sauna',
  towels: 'Serviettes',
  cardio_training_equipment: 'Matériel de cardio training',
  swimming_pool: 'Piscine',
  street_workout_area: 'Espace street workout',
  pilates_equipment: 'Matériel de pilâtes',
  hammam: 'Hammam',
  boxing_equipment: 'Matériel de boxe',
  measuring_tools: 'Outils de mesure',
  yoga_equipment: 'Matériel de yoga',
  body_building_machine: 'Machine de musculation',
};
