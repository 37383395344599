import TimeZone from 'types/models/Calendar/TimeZone';

export const TIME_ZONES: TimeZone[] = [
  { id: 1, group: 'Europe/Paris (offset+02:00)', timezone: 'Europe/Paris', utcOffset: 2 },
  { id: 2, group: 'Europe/Amsterdam (offset+02:00)', timezone: 'Europe/Amsterdam', utcOffset: 2 },
  { id: 3, group: 'Europe/Berlin (offset+02:00)', timezone: 'Europe/Berlin', utcOffset: 2 },
  { id: 4, group: 'Europe/Rome (offset+02:00)', timezone: 'Europe/Rome', utcOffset: 2 },
  { id: 5, group: 'Europe/Copenhagen (offset+02:00)', timezone: 'Europe/Copenhagen', utcOffset: 2 },
  { id: 6, group: 'Europe/Busingen (offset+02:00)', timezone: 'Europe/Busingen', utcOffset: 2 },
  { id: 7, group: 'Europe/Brussels (offset+02:00)', timezone: 'Europe/Brussels', utcOffset: 2 },
  { id: 8, group: 'Europe/London (offset 00:00)', timezone: 'Europe/London', utcOffset: 0 },
  { id: 9, group: 'Europe/Monaco (offset+01:00)', timezone: 'Europe/Monaco', utcOffset: 1 },
  { id: 10, group: 'Europe/Moscow (offset+03:00)', timezone: 'Europe/Moscow', utcOffset: 3 },
  { id: 11, group: 'Europe/Oslo (offset+03:00)', timezone: 'Europe/Oslo', utcOffset: 3 },
  { id: 12, group: 'America/Adak (offset-09:00)', timezone: 'America/Adak', utcOffset: -9 },
  {
    id: 13,
    group: 'America/Anchorage (offset-08:00)',
    timezone: 'America/Anchorage',
    utcOffset: -8,
  },
  {
    id: 14,
    group: 'America/Cambridge_Bay (offset-06:00)',
    timezone: 'America/Cambridge_Bay',
    utcOffset: -6,
  },
  { id: 15, group: 'America/Chicago (offset-05:00)', timezone: 'America/Chicago', utcOffset: -5 },
  {
    id: 16,
    group: 'America/Los_Angeles (offset-07:00)',
    timezone: 'America/Los_Angeles',
    utcOffset: -7,
  },
  { id: 17, group: 'America/Moncton (offset-03:00)', timezone: 'America/Moncton', utcOffset: -3 },
  { id: 18, group: 'America/New_York (offset-04:00)', timezone: 'America/New_York', utcOffset: -4 },
  {
    id: 19,
    group: 'America/St_Johns (offset-03:30)',
    timezone: 'America/St_Johns',
    utcOffset: -3.5,
  },
  { id: 20, group: 'America/Toronto (offset-04:00)', timezone: 'America/Toronto', utcOffset: -4 },
  {
    id: 21,
    group: 'America/Vancouver (offset-07:00)',
    timezone: 'America/Vancouver',
    utcOffset: -7,
  },
];

export const DEFAULT_TIME_ZONE = {
  id: 1,
  group: 'Europe/Paris (offset+02:00)',
  timezone: 'Europe/Paris',
  utcOffset: 2,
};
