export enum TrainingPlaceType {
  ASC = 1,
  Personal = 2,
  PersonalAndASC = 3,
}

export enum PlaceTypologyEnum {
  Parc = 2,
  Room = 1,
}
