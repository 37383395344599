export const translation = {
  title: 'Traduction',
  subtitle: 'Gérer la traduction des plateformes',
  search_placeholder: 'Chercher une clé de traduction...',
  keys: 'Clés',
  description: 'Description',
  actions: 'Actions',
  edit: 'Éditer',
  delete: 'Supprimer',
  something_went_wrong: 'Une erreur est survenue',
  translation_files: 'Fichiers de Translation',
  translation_sub_files: 'Sous-fichiers de Translation',
  translation_table_keys: 'Clés de Translation',
  files: 'Sections',
  sub_files: 'Sous-Objets',
  no_sub_files_found: 'Aucun sous-objet trouvé',
  no_sections_found: 'Aucune section trouvée',
  no_keys_found: 'Aucune clé trouvée. Veuillez ajouter une nouvelle.',
  group_placeholder: 'Ajouter un nouvel objet',
  add_new_group_placeholder: 'Ajouter un objet',
  group_required: "Nom de l'objet est requis",
  group_too_long: 'Nom de l’objet trop long',
  group_too_short: 'Nom de l’objet trop court',
  group_invalid: 'Nom de l’objet invalide',
  group_created: "L'objet a été creé avec succès.",
  manage_languages: 'Gérer les langues',
  confirmation_text_placeholder: 'Texte de confirmation',
  confirmation_text_required: 'Champ est requis',
  add_sub_file_to_file: 'Ajouter un objet',
  save: 'Enregistrer',
  cancel: 'Annuler',
  delete_platform_title: "Confirmation de suppression d'une plateforme",
  delete_platform_description:
    "Vous souhaitez de supprimer cette plateforme. Si oui, confirmez l'action ci-dessous",
  delete_confirm_text: 'Confirmez en écrivant: {{text}}',
  confirm_message: 'Supprimer',
  field_required: 'Champ requis',
  key: 'Clé',
  french: 'Français',
  english: 'English',
  spanish: 'Española',
  elements_per_page: 'Éléments par page',
  delete_section_title: "Confirmation de suppression d'une section",
  delete_section_description:
    "Vous souhaitez de supprimer cette section. Si oui, confirmez l'action ci-dessous",
  add_section_placeholder: 'Ajouter une nouvelle section',
  section_required: 'Section requise',
  section_invalid: 'Section invalide',
  section_too_short: 'Section trop courte',
  section_too_long: 'Section trop longue',
  add_new_platform: 'Ajouter une nouvelle plateforme',
  update_platform: 'Modifier la plateforme',
  add: 'Ajouter',
  name: 'Nom',
  platform_placeholder: 'Plateforme XXX',
  platform_required: 'Plateforme requise',
  platform_invalid: 'Plateforme invalide',
  platform_too_short: 'Plateforme trop courte',
  platform_too_long: 'Plateforme trop longue',
  language_code: 'Code de langue (ISO 639-1) ',
  language: 'Langue',
  platform_code_placeholder: 'XXX',
  platform_code_required: 'Code requis',
  platform_code_invalid: 'Code invalide',
  platform_code_too_short: 'Code trop court',
  platform_code_too_long: 'Code trop long',
  platform_created: 'Plateforme a été crée avec succès',
  platform_updated: 'Plateforme a été modifiée avec succès ',
  platform_deleted: 'Plateforme a été supprimée avec succès',
  confirmation_text_invalid: 'Texte de confirmation invalide',
  section_created: 'Section a été crée avec succès',
  section_deleted: 'La section a été supprimée avec succès.',
  section_updated: 'La section a été modifiée avec succès.',
  update_section: 'Modifier la section',
  delete_group_title: "Confirmation de suppression d'un objet",
  delete_group_description:
    "Vous souhaitez supprimer cet objet. Si c'est le cas, veuillez confirmer l'action ci-dessous.",
  group_deleted: "L'objet a été supprimé avec succès.",
  update_group: "Modifier l'objet",
  group_updated: 'L’objet a été modifié avec succès.',
  delete_key_title: "Confirmation de suppression d'une clé",
  delete_key_description:
    "Vous souhaitez de supprimer cette clé. Si oui, confirmez l'action ci-dessous",
  key_deleted: 'La clé a été supprimée avec succès.',
  language_key_placeholder: 'Valeur de la clé',
  key_created: 'La clé a été crée avec succès.',
  key_updated: 'La clé a été modifiée avec succès.',
  field_invalid: 'Nom de la clé invalid',
  add_sections_before: 'Ajouter au moins une section. Puis ajouter les clés correspondantes.',
  languages_management: 'Gestion de Langues',
  delete_language_title: 'Confirmation de suppression de la langue',
  delete_language_description:
    "Vous souhaitez de supprimer cette langue. Si oui, confirmez l'action ci-dessous",
  language_created: 'La langue a été crée avec succès.',
  language_updated: 'La langue a été modifiée avec succès.',
  language_deleted: 'La langue a été supprimée avec succès.',
  sections_management: 'Gestion de Sections',
  manage_sections: 'Gérer les sections',
  no_value: 'Aucune valeur',
  flag: 'Drapeau',
  upload: 'Upload',
  file_type_not_supported: 'Type de fichier non supporté',
  search_for_sections: 'Rechercher une section',
  search_for_groups: 'Rechercher un objet',
  groups: 'Objets',
  no_groups_found: 'Aucun objet trouvé',
  search_for_sub_files: 'Rechercher un sous-objet',
  add_new_section: 'Ajouter une Section',
};
