export const body = {
  front: 'Avant du corps',
  back: 'Arrière du corps',

  upper_body: 'Haut du corps',
  upper_glutes: 'Haut des fessiers',
  upper_chest: 'Haut de la poitrine',
  upper_thighs: 'Haut des cuisses',
  lower_body: 'Bas du corps',
  lower_glutes: 'Bas des fessiers',
  lower_chest: 'Bas de la poitrine',
  lower_thighs: 'Bas des cuisses',

  biceps: 'Biceps',

  trapezius: 'Trapèzes',
  deltoids: 'Deltoïdes',
  pectorals: 'Pectoraux',
  triceps: 'Triceps',

  forearms: 'Avant-bras',
  lats: 'Dorsaux',
  abdominals: 'Abdomen',
  lower_back: 'Lombaires',
  glutes: 'Fessiers',
  quadriceps: 'Quadriceps',
  hamstrings: 'Ischios-jambiers',
  adductors: 'Adducteurs',
  calves: 'Mollets',

  brachialis: 'Brachialis',
};
