import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { baseQueryConfig } from '../BaseQueryConfig';
import {
  transformDetailProductResponse,
  transformProductsApiOptions,
  transformProductsResponse,
} from './productsApi.transform';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { ApiProduct, ProductsOptions } from 'redux/api/products/productsApi.type';

export const productsApi = createApi({
  baseQuery: fetchBaseQuery(baseQueryConfig),
  tagTypes: ['Products'],
  reducerPath: 'productsApi',
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: (params: { paginator?: Paginator; options?: ProductsOptions }) =>
        injectParams(ENDPOINTS.PRODUCTS, {
          ...transformProductsApiOptions(params.options),
          ...paginatorToApiPagination(params.paginator),
        }),
      transformResponse: (response: ApiArrayDataResponse<ApiProduct>) => {
        return transformProductsResponse(response);
      },
      providesTags: ['Products'],
    }),
    getProduct: builder.query({
      query: ({ id, type }) =>
        `${ENDPOINTS.GET_PRODUCT_BY_ID_TYPE.replace(':id', id).replace(':type', type)}`,
      transformResponse: (response: { data: ApiProduct }) =>
        transformDetailProductResponse(response.data),
      providesTags: ['Products'],
    }),
  }),
});

export const { useGetAllProductsQuery, useGetProductQuery } = productsApi;
