export const CoachConfig = {
  disposability: {
    minDaySlots: 1,
    maxDaySlots: 4,
  },
};
export enum CoachAccountStatusEnum {
  STATUS_ACCOUNT_PENDING = 1,
  STATUS_ACCOUNT_ACCEPTED = 2,
  STATUS_ACCOUNT_SUSPENDED = 3,
  STATUS_ACCOUNT_REFUSED = 4,
}
