import { country } from 'locales/fr/country';
import { form } from 'locales/fr/form';
import { auth } from './auth';
import { calendar } from './calendar';
import { client } from './client';
import { coaches } from './coaches';
import { coaching } from './coaching';
import { common } from './common';
import { course } from './course';
import { dashboard } from './dashboard';
import { error } from './error';
import { gender } from './gender';
import { helperPage } from './helperPage';
import { inputs } from './inputs';
import { inscription } from './inscription';
import { page } from './page';
import { place } from './place';
import { product } from './product';
import { reservation } from './reservation';
import { session } from './session';
import { shop } from './shop';
import { snack } from './snack';
import { success } from './success';
import { table } from './table';
import { user } from './user';
import { command } from './command';
import { account } from './account';
import { visio } from './visio';
import { exercise } from './exercise';
import { notices } from 'locales/fr/notices';
import { expenses } from './expenses';
import { body } from './body';
import { group } from 'locales/fr/group';
import { availabilities } from 'locales/fr/availabilities';
import { reschedule } from 'locales/fr/reschedule';
import { userForm } from 'locales/fr/userForm';
import { marketing } from './marketing';
import { trackingLink } from './trackingLink';
import { stripe } from './stripe';
import { reassign } from './reassign';
import { translation } from 'features/Translation/locales/TranslationFR';
import { sports } from './sports';
import { filters } from './filters';
import { equipments } from './equipments';
import { placesFilters } from './placesFilters';

const fr = {
  lng: 'fr',
  common,
  error,
  user,
  page,
  coaches,
  client,
  auth,
  inscription,
  course,
  product,
  calendar,
  place,
  coaching,
  session,
  dashboard,
  helperPage,
  gender,
  shop,
  reservation,
  table,
  inputs,
  snack,
  success,
  country,
  form,
  command,
  account,
  visio,
  notices,
  exercise,
  expenses,
  body,
  group,
  availabilities,
  reschedule,
  userForm,
  marketing,
  trackingLink,
  stripe,
  reassign,
  translation,
  sports,
  filters,
  equipments,
  placesFilters,
};
export default fr;
