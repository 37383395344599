import { STORAGE_KEYS } from 'config/constant/storage.config';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Account } from 'redux/api/accounts/accountsApi.type';
import { getPersistData, persistData, removePersistData } from 'utils/helpers/storage.helpers';
export const clearLocalStorage = () => {
  localStorage.clear();
};

export const getUserFromLocalStorage = (): Account | null => {
  try {
    const user = getPersistData(STORAGE_KEYS.USER, true) as Account;
    if (user) {
      return user;
    } else {
      clearLocalStorage();
      return null;
    }
  } catch (error) {
    clearLocalStorage();
    return null;
  }
};

// return the token from local storage if exist
export const getTokenFromLocalStorage = (): string | null => {
  try {
    const token = getPersistData(STORAGE_KEYS.TOKEN, false) as string;
    if (token) {
      return token;
    } else {
      clearLocalStorage();
      return null;
    }
  } catch (error) {
    clearLocalStorage();
    return null;
  }
};

// return the refresh token from local storage if exist
export const getRefreshTokenFromLocalStorage = (): string | null => {
  try {
    const refreshToken = getPersistData(STORAGE_KEYS.REFRESH_TOKEN, false) as string;
    return refreshToken ?? null;
  } catch (error) {
    clearLocalStorage();
    return null;
  }
};

// save a user in the local storage
export const saveUserToLocalStorage = (user: Account) => {
  persistData(STORAGE_KEYS.USER, user);
};

// save a token in the local storage
export const saveTokenToLocalStorage = (token: string) => {
  persistData(STORAGE_KEYS.TOKEN, token);
};
// save a refresh token in the local storage
export const saveRefreshTokenToLocalStorage = (refreshToken: string) => {
  persistData(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
};

// remove the user json object from local storage
export const removeUserFromLocalStorage = () => {
  removePersistData(STORAGE_KEYS.USER);
};

// remove the use token from local storage
export const removeTokenFromLocalStorage = () => {
  removePersistData(STORAGE_KEYS.TOKEN);
};

// decode a JWT token
export const parseJwt = (token: string) => {
  return jwt_decode<JwtPayload>(token);
};
