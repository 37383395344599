export const auth = {
  // login
  connection: 'Se connecter',
  logout: 'Déconnexion',
  email: 'Adresse e-mail',
  password: 'Mot de passe',
  invalid_email: 'Email invalide',
  password_must_be: `Votre mot de passe doit contenir au minimum 8 caractères.`,
  stay_connected: 'Rester connecté(e)',
  forget_password: 'Mot de passe oublié ?',
  Privacy_Policy: 'Politique de confidentialité',
  cgv: 'CGV',
  language: 'Langue',

  you_have_no_account: `Vous n'avez pas de compte ?`,
  register_me: 'Inscrivez-vous !',
  password_required: 'Mot de passe est requis.',
  confirm_password_required: 'Le mot de passe doit être confirmé.',
  email_required: 'E-mail est requis',
  email_invalid: 'E-mail invalide',
  french: 'Français',
  english: 'Anglais',
  email_too_short: 'E-mail trop court',
  email_too_long: 'E-mail trop long',
  invalid_email_or_password: 'Adresse e-mail ou mot de passe invalide.',
  // reset password
  reset_password: 'Réinitialiser le mot de passe',
  forget_password_description: `Saisissez votre adresse e-mail pour recevoir le lien de réinitialisation du mot de
  passe.`,
  reset_password_description: `Merci de saisir votre nouveau mot de passe`,
  verification_code_description: `Merci d’utiliser le code de vérification afin de réinitialiser votre mot de passe`,
  your_new_password: 'Votre nouveau mot de passe',
  confirm_your_new_password: 'Confirmer votre mot de passe',
  resend_code: 'Renvoyer le code',
  verification_code_invalid: 'Code de vérification est invalide.',
  verification_code_required: 'Le code de vérification est requis.',
  invalid_code: 'Code invalide.',
  Passwords_not_match: 'Les mots de passe ne correspondent pas.',

  confirm_required: 'La confirmation est requise.',
  identifier_required: "L'identifiant est requis",
};
