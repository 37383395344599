import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryWithReAuth } from '../BaseQueryConfig';
import {
  DetailsFromApi,
  NoticeStatisticsType,
  NoticesOptions,
  ReviewsFromApi,
} from 'redux/api/notice/noticeApi.type';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import {
  transformNoticeDetailsResponse,
  transformReviewResponse,
  transformReviewsApiOptions,
} from 'redux/api/notice/noticeApi.transform';
import { injectParams } from 'utils/helpers';
import Paginator from 'types/interfaces/Paginator';
import { paginatorToApiPagination } from 'utils/services/api.service';

export const noticeApi = createApi({
  reducerPath: 'noticeApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['notices'],
  endpoints: (builder) => ({
    // get notices statistics
    getNoticesStatistics: builder.query({
      query: () => `${ENDPOINTS.REVIEW_STATISTIC}`,
      transformResponse: (response: { data: NoticeStatisticsType }) => response.data,
      providesTags: ['notices'],
    }),
    // get all notices
    getNotices: builder.query({
      query: (params: { paginator?: Paginator; options?: NoticesOptions }) => {
        return injectParams(ENDPOINTS.REVIEWS, {
          ...transformReviewsApiOptions(params?.options),
          ...paginatorToApiPagination(params.paginator),
        });
      },
      transformResponse: (response: ApiArrayDataResponse<ReviewsFromApi>) => {
        return transformReviewResponse(response);
      },
      providesTags: ['notices'],
    }),
    deleteNotices: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.DELETE_REVIEWS,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['notices'],
    }),
    getNoticeDetails: builder.query({
      query: ({ id }) => `${ENDPOINTS.REVIEW_DETAILS}/${id}`,
      transformResponse: (response: { data: DetailsFromApi }) =>
        transformNoticeDetailsResponse(response.data),
      providesTags: ['notices'],
    }),
  }),
});
export const {
  useGetNoticesStatisticsQuery,
  useGetNoticesQuery,
  useDeleteNoticesMutation,
  useGetNoticeDetailsQuery,
} = noticeApi;
