import { objectIsEmpty } from 'utils/helpers/object.helpers';
import { ApiNextCourseDetails } from './nextCourseApi.type';

export const transformNextCourseResponse = (response: ApiNextCourseDetails) => {
  const coaches =
    response?.coaches &&
    response?.coaches?.length > 0 &&
    response?.coaches.map((coach) => {
      return {
        firstName: coach?.first_name,
        lastName: coach?.last_name,
      };
    });
  if (objectIsEmpty(response)) return {};
  return {
    createdAt: response.created_at,
    date: response.date,
    dateTimestamp: response.date_timestamp,
    endTime: response.end_time,
    startTime: response.start_time,
    id: parseInt(response.id),
    location: {
      id: response?.location?.id,
      address: response?.location?.address,
      lat: response?.location?.latitude,
      lng: response?.location?.longitude,
      name: response?.location?.name,
      picture: response?.location?.picture,
      amountPerHour: response?.location?.amount_per_hour,
      services: response?.location?.services,
    },
    locationId: response.location_id,
    reservation: {
      id: response?.reservation?.id,
      amount: response?.reservation?.amount,
      isPaid: response?.reservation?.is_paid,
      paidAt: response?.reservation?.paid_at,
      qrCode: response?.reservation?.qr_code,
      createdAt: response?.reservation?.created_at,
      productId: response?.reservation?.product_id,
      sendMethod: response?.reservation?.send_method,
      paymentLink: response?.reservation?.payment_link,
      coachingType: response?.reservation?.coaching_type,
      product: response?.reservation.product,
    },
    coach: coaches ? coaches : [],
    users: response?.users,
    reservationId: response.reservation_id,
  };
};
