export enum BORouteIdEnum {
  Root = '',
  Any = '*',
  id = ':id',
  Home = 'home',

  SignIn = 'sign-in',
  Register = 'register',
  ForgotPassword = 'forgot-password',

  BO = 'bo',
  Dashboard = 'dashboard',

  Coaches = 'coaches',
  allCoaches = 'all-coaches',
  waitingCoaches = 'waiting-coaches',
  refusalCoach = 'refusal-coach',
  allProducts = 'all-products',
  groupOfCoaches = 'group-of-coaches',
  schedule = 'schedule',
  availabilities = 'availabilities',
  redirectAllCoaches = '/bo/coaches/all-coaches',
  redirectToRefusedCoaches = '/bo/coaches/refusal-coach',
  Users = 'users',
  all = 'all',
  User = 'user',
  UserId = 'user/:id',
  Reviews = 'reviews',
  Accounts = 'accounts',
  Gym = 'gym',
  Exercises = 'exercises',
  Categories = 'categories',
  Statistics = 'statistics',
  Translations = 'translations',
  Marketing = 'marketing',
  Information = 'information',
  Supporting = 'supporting',
  Activity = 'activity',
  Agenda = 'agenda',
  Messenger = 'messenger',
  Revenues = 'revenues',
  AscLabs = 'asc-labs',
  Finalization = 'finalization',
  ThankPage = 'finalization-thanks',
  Edit = 'edit',
  EditId = 'edit/:id',
  TypeId = 'edit/:id/:type',
  Add = 'add',
  ViewId = 'view/:id',
  View = 'view',
  userCommands = ':id/commands',
  UserCourseId = 'course/:courseId',

  // MARKETING
  TrackingLink = 'tracking-link',
  PromoCodes = 'promos-codes',
  CoachAffiliation = 'coach-affiliation',
  SendNotification = 'send-notification',
  HomeUser = 'home-user',
  PopUp = 'pop-up',

  // NOT FOUND page
  NotFound = 'not-found',

  // TO FIX:  Verify and Delete unused routes after refactoring the account module!
  // I only keep them to avoid any regression.!
  Products = 'products',
  Commands = 'commands',
  Form = 'form',
  PrivateTraining = 'private-training',
  PrivateTrainingCourse = 'courses',
  PrivateTrainingReservation = 'reservations',
  PrivateTrainingAgenda = 'coaching-agenda',
  PrivateTrainingWaiting = 'waiting',
  PrivateTrainingClients = 'clients',
  PrivateTrainingAvailability = 'availabilities',
  Coaching = 'coaching',
  GroupCourse = 'group-course',
  SportProgram = 'sport-program',
  Vod = 'vod',
  SportsRetirement = 'sports-retirement',
  trainingRooms = 'training-rooms',
  ascTrainingRooms = 'asc',
  ascLibrary = 'asc-library',
  coachesTrainingRooms = 'coaches-rooms',
  Clients = 'clients',
  Notice = 'notice',
  Shop = 'shop',

  // Auth routes
  Auth = 'auth',
  Login = 'login',
  ForgetPassword = 'forget-password',
  ResetPassword = 'reset-password',
  VerificationCode = 'verification-code',

  // Personal Account
  PersonalAccount = 'personal-account',
}
