import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { injectParams } from 'utils/helpers/api-url';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { transformCountriesResponse } from 'redux/api/countries/countriesApi.transform';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { CountryFromApi } from 'redux/api/coach/coachApi.types';

export const countriesApi = createApi({
  reducerPath: 'countriesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['countries'],
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: (params) =>
        injectParams(ENDPOINTS.COUNTRIES, { ...paginatorToApiPagination(params.paginator) }),
      transformResponse: (response: ApiArrayDataResponse<CountryFromApi>) => {
        return transformCountriesResponse(response);
      },
    }),
  }),
});

export const { useGetCountriesQuery } = countriesApi;
