export const inputs = {
  button: {
    add_account: 'Ajouter le compte',
    update_account: 'Modifier le compte',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    load_more: 'Voir plus de salles',
    add_room: 'Ajouter une salle',
    valider: 'Valider',
  },
  placeholder: {
    enter_email: 'Saisir votre email',
    enter_password: 'Saisir votre mot de passe',
    enter_confirm_password: 'Confirmer votre mot de passe',
    enter_first_name: 'Saisir votre nom',
    enter_last_name: 'Saisir votre prènom',
    enter_identifier: 'Saisir votre identifiant',
    enter_phone_number: 'Saisir votre numéro de téléphone',
    enter_address: 'Saisir votre adresse',
    enter_confirm: 'Saisir la confirmation',
    enter_place_name: 'Saisir le nom du lieu',
    enter_explication: "Saisir l'explication",
    search: {
      search_account: 'Rechercher un compte...',
    },
  },
  confirm_delete: 'Confirmer la suppression',
  price: 'Montant par heure ?',
  firstName: 'Prénom',
  lastName: 'Nom',
  identifier: 'Identifiant',
  email: 'Email',
  password: 'Mot de passe',
  confirmPassword: 'Confirmer le mot de passe',
  reset_password: 'Réinitialiser mot de passe',
  send_password: 'Envoyer le lien de réinitialisation',
  send_notification: 'Envoyer une notification au compte',
  generate_password: 'Générer un mot de passe',
  role: 'Rôle',
  pdp: 'Photo de profil',
  address: 'Adresse',
  place_name: 'Nom du lieu',
  type_account: 'Type de compte',
  label_notification: 'Envoyer un email à la personne à props de son compte',
  room_capacity: 'Capacité de la salle',
  rulesMessage: {
    required: 'Ce champ est requis',
    price_invalid: 'Le prix est invalide',
  },
  room_type: {
    salle: 'Salle',
    parc: 'Parc',
    type: 'Type de salle',
  },
  all_access: 'Tout le monde peut accéder à cette salle ?',
  explain_modality: 'Expliquer la modalité',
};
