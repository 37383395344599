import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { ApiNextCourse, ApiUser, ApiUserDetails, UsersOptions } from './userApi.type';
import { transformApiResponse } from '../BaseApiTransform';
import { User, UserDetails } from 'features/Users/UsersTable/UserTable.type';
import { objectIsEmpty } from 'utils/helpers/object.helpers';
import { ApiExpenses } from '../commonTypes/expenses';
import { ExpensesDetails } from 'types/models/common/ExpenseType/ExepenseType';
import { transformApiMediaType } from 'redux/api/commonTypes/file';
import { LevelType } from 'types/models/common/LevelType/LevelType';

export const transformUsersResponse = (
  response: ApiArrayDataResponse<ApiUser>,
): ArrayDataResponse<User> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((apiUser) => {
      return {
        id: apiUser.id,
        firstName: apiUser.first_name,
        lastName: apiUser.last_name,
        gender: apiUser.gender,
        photo: apiUser.photo,
        status: apiUser.status,
        isActive: true,
        createdAt: apiUser.created_at,
        lastConnectionAt: apiUser.last_connection_at,
      };
    }),
  };
};

export const transformExpensesApiOptions = (months: string) => {
  return {
    months: months,
  };
};

export const transformUserResponse = (response: ApiUserDetails): UserDetails => {
  const goals = response.goals.map((goal) => {
    return {
      id: goal.id,
      translatedName: goal.translated_name ?? `goals.${goal.translated_key?.name}`,
      isAsc: goal.is_asc,
    };
  });
  const sports = response.sports.map((sport) => {
    return {
      id: sport.id,
      translatedName: sport.translated_key
        ? `sports.${sport.translated_key.name}`
        : sport.translated_name ?? sport.name,
      isAsc: sport.is_asc,
      icon: sport.icon,
      image: transformApiMediaType(sport.icon),
    };
  });

  const level: LevelType = {
    id: response?.training_level?.id,
    name: response?.training_level?.name,
    description: response?.training_level?.description,
    translatedDescription: response?.training_level?.translated_description,
    translatedName: response?.training_level?.translated_key?.name
      ? `training_levels.${response?.training_level?.translated_key?.name}`
      : `user.user_details.unknown`,
  };

  return {
    id: response.id,
    firstName: response.first_name,
    lastName: response.last_name,
    photo: response.photo,
    gender: response.gender,
    isActive: false,
    createdAt: response.created_at,
    lastConnectionAt: response.last_connection_at,
    email: response.email,
    phone: response.phone,
    goals: goals?.length > 0 ? goals : [],
    level,
    height: response.height,
    points: response.points,
    sports: sports?.length > 0 ? sports : [],
    status: response.status,
    weight: response.weight,
    address: response.address,
    longitude: response.longitude,
    latitude: response.latitude,
    birthDate: response.birth_date ? response.birth_date : 0,
    payment_provider: response.paymentProvider,
    payment_provider_id: response.paymentProviderId,
  };
};
export const transformUsersApiOptions = (options: UsersOptions) => {
  return {
    alphabet: options.alphabet,
    syllable: options.syllable,
  };
};

export const transformNextCourseResponse = (response: ApiNextCourse) => {
  if (objectIsEmpty(response)) return {};
  return {
    createdAt: response.created_at,
    date: response.date,
    dateTimestamp: response.date_timestamp,
    endTime: response.end_time,
    startTime: response.start_time,
    id: parseInt(response.id),
    location: {
      id: response?.location?.id?.toString(),
      address: response?.location?.address,
      lat: response?.location?.latitude,
      lng: response?.location?.longitude,
      name: response?.location?.name,
    },
    locationId: response.location_id,
    reservation: {
      id: response?.reservation?.id,
      amount: response?.reservation?.amount,
      isPaid: response?.reservation?.is_paid,
      paidAt: response?.reservation?.paid_at,
      qrCode: response?.reservation?.qr_code,
      createdAt: response?.reservation?.created_at,
      productId: response?.reservation?.product_id,
      sendMethod: response?.reservation?.send_method,
      paymentLink: response?.reservation?.payment_link,
      coachingType: response?.reservation?.coaching_type,
    },
    users: response?.users,
    reservationId: response.reservation_id,
  };
};

export const transformExpensesResponse = (response: ApiExpenses): ExpensesDetails => {
  const expensesByTypes = response.expenses_by_type.map((expense) => {
    return {
      type: expense.type,
      expenses: expense.expenses,
    };
  });
  return {
    globalExpenses: {
      ordersNumber: response.global_expenses.orders_number,
      totalAscFees: response.global_expenses.total_asc_fees,
      totalExpenses: response.global_expenses.total_expenses,
    },
    expensesByType: expensesByTypes,
  };
};
