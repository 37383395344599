import {
  GoalFormEncodedObject,
  GoalFormEncodedRequest,
  GoalFormRequest,
} from './goalsFormApi.type';
import { transformApiResponse } from 'redux/api/BaseApiTransform';
import { ApiGoalsType, GoalType } from 'redux/api/user/UserForm/GoalsUserForm/goalsFormApi.type';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { transformTranslatedKey } from '../userForm.transform';

{
  /** *** GOALS ****/
}

export const transformGoal = (goal: ApiGoalsType): GoalType => {
  return {
    id: goal.id,
    isAsc: goal.is_asc,
    translatedName: goal.translated_name,
    translationKeyId: goal.translation_key_id,
    translatedKey: transformTranslatedKey(goal.translated_key),
  };
};

export const transformGoalsListResponse = (
  response: ApiArrayDataResponse<ApiGoalsType>,
): ArrayDataResponse<GoalType> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((goal) => {
      return transformGoal(goal);
    }),
  };
};

export const encodeGoalFormRequest = (request: GoalFormRequest): GoalFormEncodedRequest => {
  const { values } = request;

  const data: GoalFormEncodedObject[] = [];

  Object.keys(values).forEach((key) => {
    if (key.startsWith('goal-')) {
      const languageId = parseInt(key.split('-')[1]);
      const value = values[key];
      // push a new object into the languagesValue Array
      data.push({ language_id: languageId, value });
    }
  });

  return { data };
};
