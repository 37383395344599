import { useTranslation } from 'react-i18next';
import { LanguageMenuItemProps } from './LanguageMenuItem.type';
import { DividerStyle, LanguageMenuItemRoot } from './LanguageMenuItem.style';
import { Stack, Typography } from '@mui/material';

function LanguageMenuItem({ isNotLast, languageCode, onClick }: LanguageMenuItemProps) {
  const { t } = useTranslation();
  return (
    <Stack spacing={0} alignItems="center">
      <LanguageMenuItemRoot onClick={onClick}>
        <Typography variant="body1">{t(`common.languages.${languageCode}`)}</Typography>
      </LanguageMenuItemRoot>
      {isNotLast && <DividerStyle />}
    </Stack>
  );
}

export default LanguageMenuItem;
