export const session = {
  no_sessions_on_this_date: "Vous n'avez pas encore de séances à cette date",
  no_sessions: "Vous n'avez pas encore de séances",
  session: 'Séance',
  demands_date_change: '{{name}} vous demande un changement de date :',
  no_waiting_list: 'Pas de séance en attente à afficher',
  your_possibilities: 'Vos possibilités :',
  other_date: 'Autre date',

  create_session: 'Créer la séance',
  add_room: 'Renseignez une salle',
};
