import { ENDPOINTS } from 'config/constant/endpoints.config';
import { ResponseData, transformedRequiredFields } from 'redux/api/stripe/stripeApi.type';
import { coachApi } from 'redux/api/coach/coachApi';
import { transformStripeRequiredFields } from 'redux/api/stripe/stripeApi.transform';

export const stripeApi = coachApi.injectEndpoints({
  endpoints: (builder) => ({
    getStripeRequiredFields: builder.query({
      query: ({ id }) => `${ENDPOINTS.GET_STRIPE_REQUIRED_FIELDS}/${id}`,
      transformResponse: (response: ResponseData): transformedRequiredFields => {
        return transformStripeRequiredFields(response);
      },
      providesTags: ['Stripe'],
    }),
    createCoachExternalAccount: builder.mutation({
      query: ({ token, id }) => ({
        url: `${ENDPOINTS.CREATE_EXTERNAL_ACCOUNT}/${id}`,
        method: 'POST',
        body: token,
      }),
      invalidatesTags: ['Coaches', 'Stripe'],
    }),
    getCurrency: builder.query<{ data: string[] }, { id?: number }>({
      query: ({ id }) => `${ENDPOINTS.CURRENCIES}/${id}`,
    }),
  }),
});

export const {
  useGetStripeRequiredFieldsQuery,
  useCreateCoachExternalAccountMutation,
  useGetCurrencyQuery,
} = stripeApi;
