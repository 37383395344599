export const reservation = {
  status: {
    in_cancelation: 'Annulation',
    change_date: 'Changement de date',
    waiting_for_date_validation: 'Attente validation date',
    waiting_for_date_validation_title: 'En attente de réponse du client pour le changement de date',
    waiting_for_date_validation_description:
      'Votre proposition de date a été envoyé à votre client. S’il en choisit une vous serez prévenu, si cela ne lui convient pas, le cours sera annulé et votre client sera invité à choisir parmi vos disponibilité.',

    waiting_for_payment: 'En attente de paiement',
  },
  command_id: 'Id commande',
};
