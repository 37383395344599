import { ApiArrayDataResponse, ArrayDataResponse, ClientResponse } from 'types/models/ApiResponse';
import Client, { MyClients } from 'types/models/Client/client';
import CoachClient from 'types/models/CoachClient/CoachClient';
import { toCamelCase } from 'utils/helpers/object.helpers';

export const transformClientsResponse = (data: ApiArrayDataResponse) => {
  return {
    ...toCamelCase(data),
    data: toCamelCase(data.data),
  } as unknown as ArrayDataResponse<CoachClient>;
};

export const transformClientsCoachResponse = (response: ApiArrayDataResponse) => {
  return {
    ...toCamelCase(response),
    data: toCamelCase(response.data),
  } as unknown as ArrayDataResponse<MyClients>;
};
export const transformClientResponse = (data: ClientResponse) => {
  return toCamelCase(data.data) as unknown as Client;
};
