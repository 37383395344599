import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalVariables } from 'config/constant';
import { accountsApi } from 'redux/api/accounts/accountsApi';
import { Account } from 'redux/api/accounts/accountsApi.type';
import { authApi } from 'redux/api/auth/login/loginApi';
import { RootState } from 'redux/store';
import { extractXAmzExpires } from 'utils/helpers';
import {
  clearLocalStorage,
  getTokenFromLocalStorage,
  getUserFromLocalStorage,
  saveTokenToLocalStorage,
  saveUserToLocalStorage,
  removeUserFromLocalStorage,
  getRefreshTokenFromLocalStorage,
  saveRefreshTokenToLocalStorage,
} from 'utils/services/storage.service';

interface IUserState {
  user: Account | null;
  token: string | null;
  refreshToken: string | null;
  isLoggedIn: boolean;
  profilePicture?: {
    url: string;
    expiresAfter: number;
  };
}

const initialLoggedIn: boolean =
  getUserFromLocalStorage()?.email && getTokenFromLocalStorage()?.length ? true : false;

const initialState: IUserState = {
  user: getUserFromLocalStorage() as Account,
  token: getTokenFromLocalStorage(),
  refreshToken: getRefreshTokenFromLocalStorage(),
  isLoggedIn: initialLoggedIn,
};

export const authSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ user: Account; token: string; refreshToken: string }>,
    ) => {
      const { user, token, refreshToken } = action.payload;
      state.user = user;
      state.token = token;
      state.refreshToken = refreshToken;
      saveUserToLocalStorage(user);
    },
    resetUser: (state) => {
      state.user = null;
      state.token = null;
      state.isLoggedIn = false;
      clearLocalStorage();
    },
    setToken: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token;
    },
    setRefreshToken: (state, action: PayloadAction<{ refreshToken: string }>) => {
      state.refreshToken = action.payload.refreshToken;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      const { user, token, refreshToken } = payload;
      if (user && token && refreshToken) {
        state.user = user;
        state.token = token;
        state.refreshToken = refreshToken;
        state.isLoggedIn = true;
        state.profilePicture = {
          expiresAfter: GlobalVariables.X_AMZ_EXPIRES,
          url: user.profilePicture,
        };
        saveUserToLocalStorage(user);
        saveTokenToLocalStorage(token);
        saveRefreshTokenToLocalStorage(refreshToken);
      }
    });

    builder.addMatcher(authApi.endpoints.logout.matchPending, (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.token = null;
      state.profilePicture = undefined;
      clearLocalStorage();
    });
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      state.token = null;
    });
    builder.addMatcher(authApi.endpoints.logout.matchRejected, (state) => {
      state.token = null;
    });

    // Update the state when the connected user changes his credentials
    builder.addMatcher(accountsApi.endpoints.updateAccount.matchFulfilled, (state, { payload }) => {
      const { id, firstName, lastName, profilePicture } = payload;

      if (id === state.user?.id) {
        state = {
          ...state,
          profilePicture: {
            expiresAfter: GlobalVariables.X_AMZ_EXPIRES,
            url: profilePicture,
          },
          user: {
            ...state.user,
            firstName,
            lastName,
            profilePicture,
          },
        };
        removeUserFromLocalStorage();
        if (state.user !== null) {
          saveUserToLocalStorage(state.user);
        }
      }
    });

    builder.addMatcher(
      accountsApi.endpoints.getAccountById.matchFulfilled,
      (state, { payload }) => {
        if (payload.id === state.user?.id) {
          const xAmzExpiresAfter = extractXAmzExpires(payload.profilePicture);
          state.profilePicture = {
            ...state.profilePicture,
            url: payload.profilePicture,
            expiresAfter: xAmzExpiresAfter,
          };
        }
      },
    );
  },
});

export default authSlice.reducer;

export const { setUser, resetUser, setToken, setRefreshToken } = authSlice.actions;
export const GetUser = (state: RootState) => state.authReducer.user;
