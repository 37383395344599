import { GlobalVariables } from 'config/constant/global.variables';
import { GenderNumbers } from 'config/enums/common.enums';
import { TypeEnum, TypesLabelsEnum } from 'config/enums/types.enum';
import { OrderLabelsEnum, OrderTypeEnum } from 'config/enums/order.enum';
import { t } from 'i18next';
export const concatenateTimeWithMinutes = (time: string) => `${time}${GlobalVariables.MIN}`;
export const getFirstLetter = (str: string) => str.charAt(0).toUpperCase();

export const trimString = (str: string) => str.trim();

export const getNameAndPrenameFromText = (text: string) => {
  const fullName = trimString(text);
  const nameArr = fullName.split(' ');
  const name = nameArr.slice(0, -1).join(' ');
  const prename = nameArr.pop() || '';
  return { name, prename };
};

export const namePrenameToString = (name?: string, prename?: string) => {
  if (!name && !prename) {
    return '';
  }
  return `${name} ${prename}`;
};

export const namePrenameToFirstLetter = (name: string, prename: string) =>
  `${getFirstLetter(name)}${getFirstLetter(prename)}`;

export const stringToFirstLetters = (str: string): string => {
  const words = str.trim().split(' ');
  // Return an empty string if there are fewer than two words
  if (words.length < 2) {
    return '';
  }
  const [firstWord, secondWord] = words;
  return `${firstWord.charAt(0)}${secondWord.charAt(0)}`;
};

export const numberToGender = (n: GenderNumbers) => {
  switch (n) {
    case 1:
      return 'gender.F';
    case 2:
      return 'gender.M';
    default:
      return 'gender.O';
  }
};
export const numberToRole = (n: number) => {
  switch (n) {
    case 1:
      return t('common.role.admin');
    case 2:
      return t('common.role.expert');
    default:
      return null;
  }
};

export const generatePassword = () => {
  const length = GlobalVariables.Inputs.shortPassword.minLength;
  const charactersMaj = GlobalVariables.ALL_CHARACTERS_MAJ;
  const charactersMin = GlobalVariables.ALL_CHARACTERS_MIN;
  const numbers = GlobalVariables.ALL_NUMBERS;
  const specialCharacters = GlobalVariables.ALL_SPECIAL_CHARACTERS;
  const totalCharacterSets = 4;

  let password = '';
  for (let i = 0; i < length; i++) {
    const characterSetIndex = i % totalCharacterSets;

    let selectedCharacterSet = '';
    switch (characterSetIndex) {
      case 0:
        selectedCharacterSet = charactersMaj;
        break;
      case 1:
        selectedCharacterSet = charactersMin;
        break;
      case 2:
        selectedCharacterSet = numbers;
        break;
      case 3:
        selectedCharacterSet = specialCharacters;
        break;
      default:
        break;
    }

    const randomIndex = Math.floor(Math.random() * selectedCharacterSet.length);
    password += selectedCharacterSet.charAt(randomIndex);
  }

  return password;
};
export const cleanGoogleMapAddress = (address?: string): string | undefined => {
  const prefix = 'Address: ';
  if (address?.startsWith(prefix)) {
    return address.slice(prefix.length);
  }
  return address;
};

export const extractNumericValue = (value: string) => {
  return value.replace(/[^0-9]/g, '');
};

export const replaceDotWithComma = (input: string) => {
  return input.replace(/\./g, ',');
};
export const numberToOrderType = (n: OrderTypeEnum) => {
  switch (n) {
    case OrderTypeEnum.Shop:
      return OrderLabelsEnum.Shop;
    case OrderTypeEnum.Private:
      return OrderLabelsEnum.Private;
    case OrderTypeEnum.Public:
      return OrderLabelsEnum.Public;
    case OrderTypeEnum.SportProgram:
      return OrderLabelsEnum.SportProgram;
    case OrderTypeEnum.ProgramGeneral:
      return OrderLabelsEnum.ProgramGeneral;
    case OrderTypeEnum.ProgramCustom:
      return OrderLabelsEnum.ProgramCustom;
    default:
      return '';
  }
};

export const numberToType = (n: TypeEnum) => {
  switch (n) {
    case TypeEnum.Shop:
      return TypesLabelsEnum.Shop;
    case TypeEnum.Private:
      return TypesLabelsEnum.Private;
    case TypeEnum.Public:
      return TypesLabelsEnum.Public;
    case TypeEnum.SportProgram:
      return TypesLabelsEnum.SportProgram;
    case TypeEnum.VOD:
      return TypesLabelsEnum.VOD;
    case TypeEnum.SportRetreat:
      return TypesLabelsEnum.SportRetreat;
    default:
      return TypesLabelsEnum.All;
  }
};

export const extractNumberFromString = (str: string): number | null => {
  const regex = /\d+/;

  const match = str.match(regex);

  if (match) {
    return parseInt(match[0], 10);
  }

  return null; // Return null if no number is found
};

export const convertTextToHtmlFile = (htmlText: string): Blob =>
  new Blob([htmlText], { type: 'text/html' });

export const convertHtmlFileToText = async (url: string): Promise<string> =>
  (await (await fetch(url)).blob()).text();
export const isUrl = (urlString: string): boolean => {
  const protocolPattern = /^(https?:\/\/)?/i;
  const domainPattern = /(([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3})/;
  const portPattern = /:\d+/;
  const pathPattern = /\/[-a-z\d%_.~+]*/;
  const queryPattern = /\?[;&a-z\d%_.~+=-]*/;
  const fragmentPattern = /#[-a-z\d_]*/;

  const urlPattern = new RegExp(
    `^${protocolPattern.source}${domainPattern.source}${portPattern.source}?${pathPattern.source}${queryPattern.source}${fragmentPattern.source}$`,
    'i',
  );

  return urlPattern.test(urlString);
};

export const isValidFiled = (value?: string) => {
  return value != '' && value != null;
};
