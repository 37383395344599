import { Stack } from '@mui/material';
import { ASCLogoStyle } from 'layouts/DashboardLayout/DashboardLayout.style';
import CustomLoader from '../CustomLoader';

function CustomAscLoader() {
  return (
    <Stack
      spacing={6}
      height="100vh"
      textAlign={'center'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <ASCLogoStyle width="119px" height="66px" />
      <CustomLoader size={5} />
    </Stack>
  );
}

export default CustomAscLoader;
