import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { GroupedByCalendarEvent } from 'types/models/Calendar/CalendarEvent/CalendarEvent';
import { namePrenameToString } from 'utils';
import { toCamelCase } from 'utils/helpers/object.helpers';
import { ApiEvent, ApiReservationUser } from './eventApi.type';

export const generateTitle = (payingClient?: ApiReservationUser) => {
  return namePrenameToString(payingClient?.first_name ?? '-', payingClient?.last_name ?? '-');
};
// TODO transform missing data
export const transformEvents = (response: ApiArrayDataResponse<ApiEvent> | ApiEvent[]) => {
  if (Array.isArray(response)) {
    // Type is ApiEvent[]
    return {
      ...toCamelCase(response),
      data: response.map((element) => ({
        group: element.date,
        data: element?.events
          ? element?.events?.map((event) => {
              return {
                id: event.id,
                date: { date: element.date, start: event.start_time, end: event.end_time },
                interviewLink: event.interview_link,
                title: event.title,
                users: event.coaches.map((user) => ({
                  firstName: user.first_name,
                  lastName: user.last_name,
                  coachId: user.id,
                  accountStatus: user.account_status,
                })),
                duration: 0,
              };
            })
          : [],
      })),
    } as ArrayDataResponse<GroupedByCalendarEvent>;
  } else {
    // Type is ApiArrayDataResponse<ApiEvent>[]
    return {
      ...toCamelCase(response),
      data: response?.data
        ? response.data.map((element) => ({
            group: element.date,
            data: element?.events?.map((event) => {
              return {
                id: event.id,
                date: { date: element.date, start: event.start_time, end: event.end_time },
                interviewLink: event.interview_link,
                title: event.title,
                users: event.coaches.map((user) => ({
                  firstName: user.first_name,
                  lastName: user.last_name,
                  coachId: user.id,
                  accountStatus: user.account_status,
                })),
                duration: 0,
              };
            }),
          }))
        : [],
    } as ArrayDataResponse<GroupedByCalendarEvent>;
  }
};
