import { Box, ListItemText, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as ArrowDow } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ItemIcon } from 'assets/icons/item.svg';
import { GlobalVariables } from 'config/constant';

type ActiveType = {
  active: string;
  open?: boolean;
  color?: string;
};

export const ITEM_BORDER_RADIUS = '53px';
export const LIST_ITEM_BORDER_RADIUS = '24px';

export const ListItemStyle = styled(Stack)(({ active, open }: ActiveType) => ({ theme }) => ({
  cursor: 'pointer',
  color: active === GlobalVariables.TRUE ? theme.palette.grey[800] : theme.palette.grey[500],
  fill: active === GlobalVariables.TRUE ? theme.palette.grey[800] : theme.palette.grey[500],
  backgroundColor: active === GlobalVariables.TRUE ? theme.palette.grey[200] : 'transparent',
  fontSize: '0.938rem',
  textDecoration: 'none',
  textTransform: 'none',
  borderRadius: active === GlobalVariables.TRUE ? LIST_ITEM_BORDER_RADIUS : ITEM_BORDER_RADIUS,
  '&:hover': {
    color: theme.palette.grey[800],
    fill: theme.palette.grey[800],
    backgroundColor: open === false ? theme.palette.grey[200] : undefined,
  },
}));

export const ItemStyle = styled((props: Record<string, unknown> & ActiveType) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" {...props} />
))((props) => ({ theme }) => ({
  padding: 10,
  paddingInline: 15,
  cursor: 'pointer',
  color: props.active === GlobalVariables.TRUE ? theme.palette.grey[800] : theme.palette.grey[500],
  fill: props.active === GlobalVariables.TRUE ? theme.palette.grey[800] : theme.palette.grey[500],
  backgroundColor: props.active === GlobalVariables.TRUE ? theme.palette.grey[200] : 'transparent',
  fontSize: '0.938rem',
  textDecoration: 'none',
  textTransform: 'none',
  borderRadius: ITEM_BORDER_RADIUS,
  '&:hover': {
    color: theme.palette.grey[800],
    fill: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[200],
  },
}));

export const SubItemStyle = styled((props: Record<string, unknown> & ActiveType) => (
  <Stack direction="row" {...props} />
))((props) => ({ theme }) => ({
  cursor: 'pointer',
  color:
    props.active === GlobalVariables.TRUE
      ? props.color || theme.palette.primary.light
      : theme.palette.grey[500],
  fontSize: '0.938rem',
  textDecoration: 'none',
  textTransform: 'none',
  '&:hover': {
    color: props.color || theme.palette.primary.light,
  },
}));

export const SubItemBoxStyle = styled(Box)(({ color }: { color?: string }) => ({ theme }) => ({
  cursor: 'pointer',
  width: '6px',
  height: '6px',
  background: color || theme.palette.primary.light,
  borderRadius: '50%',
}));

export const ItemIconStyle = styled(ItemIcon)({
  width: 15,
  height: 15,
});

export const ArrowDowIconStyle = styled(ArrowDow)(({ down }: { down: string }) => ({ theme }) => ({
  transition: '0.5s',
  transform: down === GlobalVariables.TRUE ? 'rotate(180deg)' : 'rotate(0deg)',

  path: {
    fill: down === GlobalVariables.TRUE ? theme.palette.primary.light : theme.palette.grey[500],
  },
}));

export const ListItemTextStyle = styled(ListItemText)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});
