import { lazy } from 'react';
import { BORouteIdEnum } from 'config/enums';

const NotFoundPage = lazy(() => import('pages/NotFoundPage'));

const NotFoundRoute = {
  path: BORouteIdEnum.Any,
  element: <NotFoundPage />,
};

export default NotFoundRoute;
