import { AppBar, Grid, Stack, Toolbar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { ReactComponent as MenuIcon } from 'assets/icons/menu-icon.svg';
import {
  APP_DESKTOP,
  APP_MOBILE,
  APP_TABLET,
  DRAWER_WIDTH,
  DRAWER_WIDTH_TABLET,
} from './Navbar.constants';

export const RootStyle = styled(AppBar)(({ open }: { open: boolean }) => ({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(15px)',
  WebkitBackdropFilter: 'blur(15px)',
  backgroundColor: alpha(theme.palette.background.default, 0.9),

  [theme.breakpoints.down('md')]: {
    boxShadow: `0px 0px 89px ${theme.palette.grey[200]}`,
    background: `linear-gradient(${theme.palette.grey[50]}, ${theme.palette.grey[50]})`,
  },
  [theme.breakpoints.up('md')]: {
    width: `calc(100% - ${open ? DRAWER_WIDTH : DRAWER_WIDTH_TABLET + 1}px)`,
    transition: 'width 0.3s linear',
  },
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

export const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  padding: '0 !important',
  minHeight: APP_DESKTOP,
  [theme.breakpoints.up('lg')]: {
    minHeight: APP_DESKTOP,
  },
  [theme.breakpoints.down('lg')]: {
    minHeight: APP_TABLET,
  },
  [theme.breakpoints.down('md')]: {
    minHeight: APP_MOBILE,
  },
}));

export const MenuIconStyle = styled(MenuIcon)(({ theme }) => ({
  width: 30,
  height: 30,
  fill: theme.palette.primary.dark,
}));

export const ContentStyle = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: 38,
  paddingInline: '2.8%',
  paddingBottom: 33,
  [theme.breakpoints.down('md')]: {
    padding: 0,
    paddingBottom: 33,
  },
}));

export const MobileNavbarStyle = styled(Stack)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const DesktopNavbarStyle = styled(Stack)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
