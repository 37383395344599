export const form = {
  profile: 'Profile',

  name: 'Nom',
  name_required: 'Le nom est requis',
  name_too_short: 'Le nom est trop court',
  name_too_long: 'Le nom est trop long',
  name_placeholder: 'Amélie',
  name_invalid: 'Le nom invalide',

  prename: 'Prénom',
  prename_required: 'Le prénom est requis',
  prename_too_short: 'Le prénom est trop court',
  prename_too_long: 'Le prénom est trop long',
  prename_placeholder: 'Dupont',
  prename_invalid: 'Le prénom invalide',

  email: 'Email',
  mail_address: 'Adresse e-mail',
  email_required: "L'email est requis",
  email_invalid: "L'email est invalide",
  email_too_short: "L'email est trop court",
  email_too_long: "L'email est trop long",

  phone: 'Téléphone',
  phoneNumber_placeholder: '00 33 1 40 00 00 00',
  phone_required: 'Le téléphone est requis',
  phone_too_long: 'Le téléphone est trop long',
  phone_invalid: 'Le téléphone est invalide',

  number_siret: 'Numéro de SIRET',
  number_siret_placeholder: '••• ••• ••• •••••',
  number_siret_required: 'Le numéro de siret est requis.',
  number_siret_invalid: 'Le numéro de siret est invalide.',

  number_professional_carte: 'Numéro carte professionnel',
  number_professional_carte_placeholder: '#####XX####',
  number_professional_card_required: 'Numéro carte professionnel est requis',
  number_professional_card_invalid: 'Le numéro de la carte professionnel est invalide.',
  title_placeholder: 'titre',
  title_required: ' Le titre est requis',
  title_invalid: 'Le titre est invalide.',
  title_too_short: 'Le titre est trop court',
  title_too_long: 'Le titre est trop long',

  banking_details: ' Vos coordonnées bancaires',

  iban: 'IBAN (International Bank Account Number):',
  iban_placeholder: '••• ••• ••• ••• •••••',
  number_iban_required: 'IBAN est requis',
  number_iban_long: 'IBAN trop long',
  number_iban_short: 'IBAN trop court',

  bic: 'BIC  (Bank Identifier Code):',
  bic_placeholder: '••• ••• ••• ••• •••••',
  number_bic_required: 'BIC est requis',

  identifant: 'Identifiant',
  password: 'Mot de passe',

  number_bic_long: 'BIC trop long',
  number_bic_short: 'BIC trop court',

  specify_reason_placeholder: 'Saisir la raison',
  reason_too_short: 'La raison est trop courte',
  reason_too_long: 'La raison est trop long',
  reason_invalid: 'La raison est invalide',
  reason_required: 'La raison est obligatoire !',
  one_reason_required: 'Veuillez sélectionner un choix ',

  reject_coach_description_placeholder: "Fournir plus d'informations ici...",
  add_more_information: 'Ajouter des informations complémentaires',

  description_too_short: 'description est trop courte',
  description_too_long: 'description est trop long',
  password_invalid:
    'Un mot de passe doit contenir au minimum 8 caractères, à savoir : au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre',

  search_placeholder: 'Rechercher...',

  goal_fr: 'Objectif (Fr)',
  goal_en: 'Objectif (En)',
  goal_fr_placeholder: "Saisissez l'objectif à ajouter (FR)...",
  goal_en_placeholder: "Saisissez l'objectif à ajouter (EN)...",

  title_fr: 'Titre (Fr)',
  title_en: 'Titre (En)',
  description_fr: 'Description (Fr)',
  description_en: 'Description (En)',
  title_fr_placeholder: 'Saisissez le titre (Fr)',
  title_en_placeholder: 'Saisissez le titre (En)',
  description_fr_placeholder: 'Saisissez la description (Fr)',
  description_en_placeholder: 'Saisissez la description (En)',
  description_required: 'La description est requis',
  goal_too_short: "l'objectif est trop court",
  goal_required: 'objectif est requis',

  activity_add_icon: 'Ajouter svg',
  activity_fr: 'Activité (Fr)',
  activity_en: 'Activité (En)',
  activity_fr_placeholder: 'Activité(Fr)',
  activity_en_placeholder: 'Activité(En)',
  activity_too_short: "L'activité est trop court",
  activity_required: "L'activité est requis",

  currencies: 'Monnaies',
  currency_placeholder: 'Choisissez la monnaie',
  currency_required: 'La monnaie est requise',
};
