import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { BORouteIdEnum } from 'config/enums';

// lazy loaded pages
const TrainingPlacesPage = lazy(() => import('pages/RoomsPage/TrainingPlacesPage'));
const AscTrainingPlaces = lazy(() => import('pages/RoomsPage/AscTrainingPlaces/AscTrainingPlaces'));
const AddRoomsPage = lazy(() =>
  import('pages/RoomsPage/AddRoomsPage/AddRoomsPage').then((module) => ({
    default: module.AddRoomsPage,
  })),
);
const ViewTrainingPlace = lazy(() => import('pages/RoomsPage/ViewTrainingPlace/ViewTrainingPlace'));
const CoachesTrainingPlaces = lazy(
  () => import('pages/RoomsPage/CoachesTrainingPlaces/CoachesTrainingPlaces'),
);

const TrainingPlaceRoutes = {
  path: BORouteIdEnum.Gym,
  children: [
    {
      path: BORouteIdEnum.Root,
      element: <TrainingPlacesPage />,
      children: [
        {
          path: BORouteIdEnum.Root,
          element: <Navigate to={BORouteIdEnum.ascTrainingRooms} />,
        },
        {
          path: BORouteIdEnum.ascTrainingRooms,
          children: [
            { path: BORouteIdEnum.Root, element: <AscTrainingPlaces /> },
            {
              path: BORouteIdEnum.Add,
              element: <AddRoomsPage />,
            },
            { path: BORouteIdEnum.id, element: <ViewTrainingPlace isAsc /> },
          ],
        },
        {
          path: BORouteIdEnum.coachesTrainingRooms,
          children: [
            { path: BORouteIdEnum.Root, element: <CoachesTrainingPlaces /> },
            { path: BORouteIdEnum.id, element: <ViewTrainingPlace /> },
          ],
        },
      ],
    },
  ],
};

export default TrainingPlaceRoutes;
