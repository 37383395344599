export const table = {
  delete: 'Supprimer',
  name: 'Nom',
  identifier: 'Identifiant',
  email: 'Email',
  role: 'Rôle',
  action: 'Action',
  add: 'Ajouter',
  groupe_actions: 'Actions groupées',
  no_accounts_yet: 'Vous n‘avez pas encore des comptes',
};
