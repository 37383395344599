export const expenses = {
  expenses: 'Dépenses',
  all: 'Tous les types',
  shop: 'Boutique',
  private: 'Entrainement privé',
  public: 'Cours collectif',
  sport_program: 'Programme sportif',
  VOD: 'VOD',
  sport_retreat: 'Séjours sportif',

  all_months: 'Tous les mois',
  current_month: 'Le mois courant',
  last_month_3: '3 derniers mois',
  last_month_6: '6 derniers mois',
  last_month_9: '9 derniers mois',
  last_month_12: '12 derniers mois',
  last_month_24: '24 derniers mois',
};
