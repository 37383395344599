import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { ApiNextCourseDetails } from './nextCourseApi.type';
import { transformNextCourseResponse } from './nextCourseApi.transform';

export const nextCourseApi = createApi({
  reducerPath: 'nextCourseApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['nextCourse'],
  endpoints: (builder) => ({
    getNextCourse: builder.query({
      query: ({ id }) => `${ENDPOINTS.RESERVATIONS_TIME_SLOTS}/${id}`,
      transformResponse: (response: { data: ApiNextCourseDetails }) => {
        return transformNextCourseResponse(response.data);
      },
      providesTags: ['nextCourse'],
    }),
  }),
});

export const { useGetNextCourseQuery } = nextCourseApi;
