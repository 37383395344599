export const inscription = {
  // inscription information
  first_name: 'Nom',
  last_name: 'Prénom',
  mail_address: 'Adresse e-mail',
  phone_number: 'Téléphone',
  promo_code: 'Un coach vous à parrainer ? Ajouter son code promo',
  question: 'Un coach vous à parrainer ?',
  about_you: 'Parlons de vous !',
  inscription_phrase: 'Nous avons besoin de ces éléments pour vous recontacter et vous découvrir',
  need_help: `Besoin d'aide ?`,
  see_FAQ: 'Consultez nos FAQ',
  or_contact: 'ou contacter',
  via_chat: 'nous directement via le chat',
  phone_invalid: 'Numéro doit être de 10 caractères',
  have_account: 'Vous déjà un compte? ',
  sign_in: 'Connectez-vous!',

  name_invalid: 'Le nom est invalide',
  prename_invalid: 'Le prénom est invalide',

  number_siret_required: 'Le numéro de SIRET est requis',
  number_siret_invalid: 'Le numéro de SIRET est invalide',
  number_siret_short: 'Le numéro de SIRET est trop court.',
  number_siret_long: 'Le numéro de SIRET est trop long.',
  number_professional_card_required: 'Le numéro de carte professionnel est requis',
  number_professional_card_invalid: 'Le numéro de carte professionnel est invalide',
  number_professional_card_short: 'Le numéro de carte professionnel est trop court.',
  number_professional_card_long: 'Le numéro de carte professionnel est trop long.',

  code_promo_required: 'Code promo est requis',
  code_promo_invalid: 'Code promo est invalide ',
  code_promo_too_short: 'Le code promo est trop court',
  code_promo_too_long: 'Le code promo est trop long',

  // inscription supporting
  number_siret: 'Numéro de SIRET',
  have_pro_card: 'Le coach possède-t-il un numéro de carte professionnelle ?',
  documents: `Vos documents: piéces d'identité`,
  documents_type: '(pdf | jpg png)',
  carte_progress: `Carte pro en cours d'obtention`,
  your_information: 'Vos informations de société',
  supporting_phrase: `Vos Futures clients auront la certitude d'être en de bonnes mains !`,
  documents_required: 'Vos documents doivent être téléversés.',

  // inscription activity
  inscription_activity_title: 'Sélectionnez les activités que vous faites déjâ',
  your_activity_title: 'Quels types activitéés faites vous ?',
  activity_phrase: `Nous pourrons échanger sur ces points lors de votre entretien avec l'expert ASC `,
  private_course: 'Cours privée',
  group_courses: 'Cours collectifs',
  vod: 'VOD',
  sports_programme: 'Programme sportif',
  sports_stays: 'Séjours sportifs',
  activities_required: 'Veuillez cocher au moins une activité.',

  // inscription finalization
  inscription_finalization_title: 'Donnez-nous disponibilités',
  your_finalization_title: 'Nous pourrons échanger sur ces points lors de notre entretiens',
  selection_date: `Sélectionnez une date et un horaire`,
  week_from: 'Semaine du',
  to: 'au',
  error_invalid: `Quelque chose s'est mal passé`,
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  confirm: 'Confirmer',

  // thank page
  wanting_join_ASC: 'Merci de vouloir rejoindre ASC',
  back_to_site: 'Retour sur le site',
  pick_date: 'Il est obligé de choisir un rendez-vous.',
  with_our_expert: 'avec notre expert ASC',
  appointment_confirmed_on: 'Votre rendez-vous est confirmé le',
  thank: 'Merci !',

  // file
  file: {
    drag_drop: 'Glisser votre piéce jointe ',
    or: 'Ou',
    browse_file: 'Rechercher là',
    upload_file: 'Télécharger le fichier',
    accepted_file_types: 'Types de fichiers acceptés',
    max_size: 'Taille maximale',
    mb: 'Mb',
    invalid: 'Fichier non valide, réessayer',
  },

  // Navbar inscription
  information: '1. Vos informations',
  supporting_documents: '2. Vos Justificatifs',
  activity: '3. Vos activités',
  finalization: '4. Finalisation',

  back: 'Retour',
  continue: 'Continuer',
};
