export const ENDPOINTS = {
  TRANSLATIONS: 'translations',
  PLATFORMS: 'platforms',
  SECTIONS: 'sections',
  GROUPS: 'groups',
  LANGUAGES: 'languages',
  KEYS: 'keys',
  EXPORT_DATA: 'export-data',
  UPLOAD_DATA: 'upload-data',
  SYNC_DATA: 'sync-data',
  BY_ID: (id?: number | string) => `${id}`,
  STATUS: 'status',
};
