import { BORouteIdEnum } from 'config/enums';
import { Navigate } from 'react-router-dom';
import RouteObject from 'types/interfaces/RouteObject';

import DashboardRoutes from './dashboardRoutes/DashboardRoutes';
import NotFoundRoute from './notFoundRoute/NotFoundRoute';
import AuthRoutes from './authRoutes/AuthRoutes';

const RoutesConfig: RouteObject[] = [
  {
    path: BORouteIdEnum.Root,
    element: <Navigate to={BORouteIdEnum.BO} />,
  },
  AuthRoutes,
  DashboardRoutes,
  NotFoundRoute,
];
export default RoutesConfig;
