import { RegisterData, RegisterDataRequest, RegisterResponse } from 'types/models/Coach/coach';

export const transformRegisterResponse = (data: RegisterResponse) => {
  return {
    ...data,
    firsName: data.first_name,
    lastName: data.last_name,
    professionalCardStatus: data.professional_cardStatus,
    professionalCard: data.professional_card,
    interviewDate: data.interview_date,
    referralCode: data.referral_code,
    updatedAt: data.updated_at,
    createdAt: data.created_at,
    recommendedBy: data.recommended_by,
  };
};

export const transformRegisterRequest = (data: RegisterData): RegisterDataRequest => {
  return {
    email: data.email,
    first_name: data.firstName,
    last_name: data.lastName,
    professional_card_status: data.professionalCardStatus,
    professional_card: data.professionalCard,
    interview_date: data.interviewDate,
    referral_code: data.referralCode,
    phone: data.phone,
    siret: data.siret,
    activities: data.activities,
    documents: data.documents,
  };
};
