import { Drawer, Stack, styled } from '@mui/material';

export const DrawerStyle = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',
    height: '100%',
    background: `linear-gradient(${theme.palette.grey[50]} 50%, ${theme.palette.grey[200]})`,
    display: { xs: 'block', sm: 'none' },
  },
}));

export const DrawerHeader = styled(Stack)(({ theme }) => ({
  paddingTop: '8px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const LogoStyle = styled(Stack)({
  width: '100%',
});
