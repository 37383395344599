import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { BORouteIdEnum } from 'config/enums';

// lazy loaded pages
const ExercisesPage = lazy(() => import('pages/ExercisesPage/ExercisesPage'));
const ASCLibrarySection = lazy(
  () => import('pages/ExercisesPage/ASCLibrarySection/ASCLibrarySection'),
);
const AddASCExerciseSection = lazy(
  () => import('pages/ExercisesPage/ASCLibrarySection/AddASCExerciseSection/AddASCExerciseSection'),
);
const EditASCExerciseSection = lazy(
  () =>
    import('pages/ExercisesPage/ASCLibrarySection/EditASCExerciseSection/EditASCExerciseSection'),
);

const ExercisesRoutes = {
  path: BORouteIdEnum.Exercises,
  children: [
    {
      path: BORouteIdEnum.Root,
      element: <ExercisesPage />,
      children: [
        {
          path: BORouteIdEnum.Root,
          element: <Navigate to={BORouteIdEnum.ascLibrary} />,
        },
        {
          path: BORouteIdEnum.ascLibrary,

          children: [
            { path: BORouteIdEnum.Root, element: <ASCLibrarySection /> },
            { path: BORouteIdEnum.Add, element: <AddASCExerciseSection /> },

            { path: BORouteIdEnum.EditId, element: <EditASCExerciseSection /> },
          ],
        },
      ],
    },
  ],
};

export default ExercisesRoutes;
