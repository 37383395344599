import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { LoginData, LoginResponse, LoginResponseData } from 'types/models/Coach/coach';
import { transformLoginResponse } from './loginApi.transform';
import { joinPaths } from 'utils/helpers/path.helpers';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: (args, api, extraOptions) => baseQueryWithReAuth(args, api, extraOptions),
  endpoints: (builder) => ({
    // login api
    login: builder.mutation<LoginResponse, LoginData>({
      query: (loginData) => ({
        url: joinPaths([ENDPOINTS.BO, ENDPOINTS.LOGIN]),
        method: 'POST',
        body: loginData,
      }),
      transformResponse: (response: LoginResponseData) => transformLoginResponse(response),
    }),

    // logout api
    logout: builder.mutation({
      query: () => ({
        url: joinPaths([ENDPOINTS.BO, ENDPOINTS.LOGOUT]),
        method: 'POST',
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
