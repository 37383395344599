export enum GenderNumbers {
  M = 1,
  F = 2,
  O = 3,
}

export enum AffirmativeResponse {
  Yes = 1,
  No = 0,
}

export enum PaginationEnum {
  Yes = 1,
  No = 0,
}

export enum ONLINE_STATUS_ENUM {
  ONLINE = 1,
  OFFLINE = 2,
  DRAFT = 3,
}

export enum TaskStatusEnum {
  DONE = 1,
  IN_PROGRESS = 2,
  TODO = 3,
}
