import { Box, InputAdornment } from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { useTranslation } from 'react-i18next';
import { RootStyle } from './SearchBox.style';
import { GetSearchText, setSearchText } from 'redux/slices/app/appSlice';
import { SearchBoxProps } from './SearchBox.type';
import { useEffect, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import { GlobalVariables } from 'config/constant';
import { useDispatch, useSelector } from 'react-redux';

function SearchBox({ searchPlaceholder, searchText }: SearchBoxProps) {
  const [text, setText] = useState(searchText || '');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const globalSearchText = useSelector(GetSearchText);
  const debouncedText = useDebounce(text, GlobalVariables.DebounceTime.Short);

  useEffect(() => {
    dispatch(setSearchText(text));
  }, [debouncedText]);

  useEffect(() => {
    if (searchPlaceholder) {
      dispatch(setSearchText(text));
    }
  }, [searchPlaceholder]);

  useEffect(() => {
    if (globalSearchText?.length >= 0 && globalSearchText !== text) {
      setText(globalSearchText);
    }
  }, [globalSearchText]);

  return (
    <Box width="90%">
      {searchPlaceholder && (
        <RootStyle
          variant="standard"
          placeholder={t(searchPlaceholder)}
          value={text}
          onChange={(e) => setText(e.target.value)}
          InputProps={{
            disableUnderline: true,
            sx: {
              height: 50,
            },
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  marginLeft: 1,
                }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    </Box>
  );
}

export default SearchBox;
