import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { injectParams } from 'utils/helpers/api-url';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { activityApi } from 'redux/api/activities/activityApi.type';
import { transformActivitiesResponse } from 'redux/api/activities/activitiesApi.transform';

export const activitiesApi = createApi({
  reducerPath: 'activitiesApi',
  baseQuery: fetchBaseQuery(baseQueryConfig),
  tagTypes: ['activities'],
  endpoints: (builder) => ({
    getActivities: builder.query({
      query: () => injectParams(ENDPOINTS.ACTIVITIES, {}),

      transformResponse: (response: ApiArrayDataResponse<activityApi>) => {
        return transformActivitiesResponse(response);
      },
    }),
  }),
});

export const { useGetActivitiesQuery } = activitiesApi;
