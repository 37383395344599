import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { ApiInvoices, InvoicesOptions } from './invoices.type';
import { transformApiResponse } from 'redux/api/BaseApiTransform';

import { InvoicesType } from 'types/models/Invoices/Invoices.type';

export const transformInvoicesApiOptions = (options?: InvoicesOptions) => {
  return {
    id: options?.id,
  };
};

export const transformCoachInvoicesResponse = (
  response: ApiArrayDataResponse<ApiInvoices>,
): ArrayDataResponse<InvoicesType> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((invoice) => {
      return {
        id: invoice.id,
        date: invoice.created_at,
        invoices: {
          amount: invoice.total_ttc,
          mat: invoice.invoice_number,
          name: invoice.name,
          productName: invoice.transaction?.order?.product_name ?? invoice.name,
        },
      };
    }),
  };
};
