import { transformApiResponse } from 'redux/api/BaseApiTransform';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { BoostingModel } from 'types/models/Marketing/HomeUser.ts/HomeUser';
import { BoostingAPI, BoostingOptions } from './boostingApi.type';

export const transformBoostingApiOptions = (options?: BoostingOptions) => {
  return {
    start_date: options?.startDate,
    end_date: options?.endDate,
  };
};

export const transformBoostingListResponse = (
  response: ApiArrayDataResponse<BoostingAPI>,
): ArrayDataResponse<BoostingModel> => {
  return {
    ...transformApiResponse(response),

    data: response.data.map((boostingApi) => {
      const GroupCoaches = boostingApi.group_coaches.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      const programs = boostingApi.programs.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      const publicProducts = boostingApi.public_products.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      return {
        id: boostingApi.id,
        title: boostingApi.title,
        endDate: boostingApi.end_date,
        startDate: boostingApi.start_date,
        endTime: boostingApi.end_time,
        startTime: boostingApi.start_time,
        viewCount: boostingApi.view_count,
        groupCoaches: GroupCoaches,
        programs: programs,
        publicProducts: publicProducts,
      };
    }),
  };
};

export const transformBoostingResponse = (response: BoostingAPI): BoostingModel => {
  return {
    id: response.id,
    title: response.title,
    endDate: response.end_date,
    startDate: response.start_date,
    endTime: response.end_time,
    startTime: response.start_time,
    viewCount: response.view_count,
    groupCoaches: response.group_coaches,
    programs: response.programs,
    publicProducts: response.public_products,
  };
};
