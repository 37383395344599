import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { transformApiResponse } from 'redux/api/BaseApiTransform';
import { countriesList } from 'redux/api/countries/countries.decoder';
import Country from 'types/models/Countries/Countries';
import { CountryFromApi } from 'redux/api/coach/coachApi.types';

export const transformCountriesResponse = (
  response: ApiArrayDataResponse<CountryFromApi>,
): ArrayDataResponse<Country> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map(countriesList),
  };
};
