import { Backdrop, Stack } from '@mui/material';
import { SlideContainer } from './CustomBackdrop.style';
import { ASCLogoStyle } from 'layouts/DashboardLayout/DashboardLayout.style';

function CustomBackdrop() {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 20 }} open={true}>
      <Stack height={'100vh'} alignItems={'center'} justifyContent={'center'}>
        <SlideContainer>
          <ASCLogoStyle width="119px" height="66px" />
        </SlideContainer>
      </Stack>
    </Backdrop>
  );
}

export default CustomBackdrop;
