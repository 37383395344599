export const common = {
  app_name: 'Asc - App',
  not_found_page_title: '404 Page not found',
  all_rights_reserved: 'Tous droits réservés',

  search: 'Rechercher',
  back: 'Retour',
  next: 'Suivant',

  home: 'Home',
  settings: 'Settings',

  add: 'Ajouter',
  edit: 'Éditer',
  contact: 'Contacter',
  view: 'Consulter',
  delete: 'Supprimer',
  suspend: 'Suspendre',
  reactivate: 'Réactiver',
  share: 'Partager',
  duplicate: 'Dupliquer',
  cancel: 'Annuler',
  close: 'Fermer',
  remove: 'Enlever',
  see: 'Voir',
  accept: 'Accepter',
  refuse: 'Refuser',
  discuss: 'Discuter',
  update: 'Modifier',
  refund: 'Rembourser',
  invoice: 'Facture',
  state: 'Statut',
  required: 'Requis',
  optional: 'Optionnel',
  between: 'Entre ',
  etH: 'h et ',
  h: 'h',
  old: 'Ancien',
  new: 'Nouveau',

  send: 'Envoi',
  send_by_mail: 'Envoi par mail',
  send_by_sms: 'Envoi par sms',
  by_mail: 'Par e-mail',
  by_sms: 'Par SMS',
  by_mail_and_sms: 'Par e-mail et SMS',
  export: 'Exporter',
  export_pdf: 'Exporter en PDF',
  export_csv: 'Exporter en CSV',

  no_results: 'Pas de resultats',

  yes: 'Oui',
  no: 'Non',

  validate: 'Valider',
  calculate: 'Calculer',
  confirm: 'Confirmer',
  relance_client: 'Relancer le client',

  min: 'min',

  HT: 'HT',
  number_of_months: 'Nbr de mois',
  TTC: 'TTC',

  turnover: { short: 'C.A', long: "Chiffre d'affaire" },
  months: '{{number}} mois',

  grouped_action: 'Action groupée',

  elements_per_page: 'Éléments par page',
  total_price: 'Montant total',
  net_price: 'Montant net',
  excluding_service_fees: 'hors frais de service',
  excluding_service_fees_info: 'Soit {{price}} € net',

  price_and_payment: 'Montant et paiement',

  auth: {
    login: 'Login',
    logout: 'Déconnexion',
    logout_abbreviation: 'Déco.',
  },

  pay: {
    pay: 'Payer',
    pay_with_card: 'Payer avec une carte',
    free: 'Gratuit',
    starting_from_hour: 'à partir de {{price}} {{currency}}/h',
  },

  something_went_wrong: "Quelque chose s'est mal passé",

  done: 'Terminé',

  offline: {
    title: 'You are currently offline.',
    message: 'Please check your internet connection and try again',
  },
  unavailable: 'Indisponible',
  online: {
    title: 'Connected',
  },

  copy_link: 'Copier le lien',
  copied_to_clipboard: 'Lien copié',

  actions: 'Actions',
  role: {
    admin: 'Administrateur',
    expert: 'Expert-ASC',
  },
  confirm_delete: 'Prière d‘écrire le mot ‘supprimer‘ pour confirmer la suppression.',

  command: 'Commandes',
  all_command: 'Toutes les commandes',
  mail_placeholder: 'adresse@votremail.com',
  password_placeholder: '*********',
  save: 'Enregistrer',
  documents: 'Documents',
  confirmInfo: 'Confirmer en écrivant : ',

  price_: ' {{price}}€',
  redirect: 'Se connecter en tant que {{firstName}} {{lastName}}',

  categories: 'Catégories',
  all_categories: 'Toutes les catégories',
  number_percentage: '{{number}}%',
  date: 'Date',
  hours: 'Heures',
  place: 'Lieu',

  or: 'ou',
  download: 'Télécharger',

  in_progress: 'En cours',
  completed: 'Terminé',
  payment_made: 'Paiement effectué',
  payment_made_short: 'Paiement effect.',
  in_delivery: 'En livraison',
  delivery: 'Livraison',
  ended_refunded: 'Remboursement effectué',
  payment_failed: 'Paiement échoué',
  confirmation_text: 'Texte de confirmation',
  days: 'Jour(s)',
  verification: 'vérification',
  add_required_fields: 'Veuillez remplir les champs requis.',

  birth_date: 'Date de naissance',
  birth_date_required: 'La date de naissance est requise',

  postal_code: 'Code postal',
  postal_code_placeholder: '75000',
  postal_code_required: 'Le code postal est requis',
  postal_code_too_short: 'Le code postal est trop court',
  postal_code_invalid: 'Code postal invalid',

  city: 'Ville',
  city_placeholder: 'Paris',
  city_required: 'La ville est requise',
  city_too_short: 'La ville est trop courte',
  adress: 'Adresse',
  address_placeholder: 'Adresse',
  address_too_short: "L'adresse du lieu est trop courte",
  address_too_long: "L'adresse du lieu est trop longue",
  address_required: "L'adresse du lieu est requise",
  ribon_menu_title: 'ASC BO',

  to: ' à ',
  initializing_title: 'Chargement en cours, veuillez patienter...',
  initializing_app_message:
    'Nous préparons tout pour vous. Cela ne prendra pas longtemps. Merci de votre patience !',
  languages: {
    fr: 'Français',
    en: 'Anglais',
  },
  other: 'Autre',
};
