export const user = {
  your_profile: 'Your profile',

  hello_user: 'Hello, {{name}}!',

  title: 'Utilisateurs',
  subtitle: 'Créer et gérer les utilisateurs',
  search: 'Rechercher un utilisateur...',
  all_users: 'Tous les utilisateurs',
  user_form: 'Formulaire utilisateur',
  config_title: 'Configuration du formulaire utilisateur',
  no_users_yet: "Vous n'avez pas encore ajouté des utilisateurs",
  no_users_yet_with_alphabet:
    "Vous n'avez pas des noms des utilisateurs qui commencent avec la lettre alphabétique ",

  add_user: 'Ajouter un utilisateur',

  /** delete user info  */
  deleteUserTitle: "Confirmation de suppression d'un utilisateur",
  deleteAllUsersTitle: 'Confirmation de suppression de tous les utilisateurs',
  deleteSelectedUsersTitle: 'Confirmation de suppression des utilisateurs sélectionnés',

  deleteAllUsersInfo:
    'Vous souhaitez supprimer tous les utilisateurs, si oui confirmer l’action ci-dessous',
  deleteSelectedUsersInfo:
    'Vous souhaitez supprimer les utilisateurs sélectionnés, si oui confirmer l’action ci-dessous',
  deleteUserInfo: 'Vous souhaitez supprimer cet utilisateur ,si oui confirmer l’action ci-dessous ',

  /** suspend user info  */
  suspendUserTitle: "Confirmation de suspension d'un utilisateur",
  suspendAllUsersTitle: 'Confirmation de suspension de tous les utilisateurs',
  suspendSelectedUsersTitle: 'Confirmation de suspension des utilisateurs sélectionnés',

  suspendAllUsersInfo:
    'Vous souhaitez suspendre tous les utilisateurs, si oui confirmer l’action ci-dessous',
  suspendSelectedUsersInfo:
    'Vous souhaitez suspendre les utilisateurs sélectionnés, si oui confirmer l’action ci-dessous',
  suspendUserInfo:
    'Vous souhaitez suspendre cet utilisateur ,si oui confirmer l’action ci-dessous ',

  /** reactivate user info  */

  reactivateUserTitle: "Confirmation de réactivation d'un utilisateur",
  reactivateAllUsersTitle: 'Confirmation de réactivation de tous les utilisateurs',
  reactivateSelectedUsersTitle: 'Confirmation de réactivation des utilisateurs sélectionnés',

  reactivateAllUsersInfo:
    'Vous souhaitez réactiver tous les utilisateurs, si oui confirmer l’action ci-dessous',
  reactivateSelectedUsersInfo:
    'Vous souhaitez réactiver les utilisateurs sélectionnés, si oui confirmer l’action ci-dessous',
  reactivateUserInfo:
    'Vous souhaitez réactiver cet utilisateur ,si oui confirmer l’action ci-dessous ',

  text_required: 'Ce champ est requis',
  text_validation: 'Le texte de confirmation est incorrecte',

  user_details: {
    details: 'Détails',
    infoClientTitle: 'Informations client',
    noInfo: 'Aucune information disponible',
    noData: 'Aucune donnée disponible',
    no_goals_yet: "Il n'a pas encore des objectifs",
    no_sports_yet: "Il n'a pas encore des sports",
    no_expenses_yet: "Il n'a pas encore des dépenses",
    no_next_session_yet: "Il n'a pas encore des prochains cours",
    number_person_minified: '{{number}} pers.',
    goalsTitle: 'Objectifs',
    sportsTitle: 'Ses sports',
    levelTitle: 'Niveau',
    statisticTitle: 'Ses statistiques',
    physicalMeasurementTitle: 'Mesures physiques',
    all_commands: 'Toutes les commandes',
    see_details: 'Voir les détails',
    objectives: 'Ses objectifs',
    next_course: 'Prochain cours',
    level: 'Son niveau',
    user_info: {
      weight_kg: 'kg',
      height_cm: 'cm',
      years: 'ans',
    },
  },

  course: {
    course_details: "Détails d'une séance",
    course: 'Cours',
    client: 'Client',
  },
  userForm: {
    goals: 'Les objectifs',
    goals_subTitle: "Ajouter les objectifs que l'utilisateur devra choisir",
    add_new_goal: 'Ajouter un objectif',
    activities: 'Les activités',
    activities_subTitle: "Ajouter les activités que l'utilisateur devra choisir",
    add_new_activity: 'Ajouter une activité',
    levels: 'Les niveaux',
    levels_subTitle: "Ajouter les niveaux que l'utilisateur devra choisir",
    add_new_level: 'Ajouter un niveau',
    hours: 'Les heures',
    hours_subTitle: "Ajouter les heures que l'utilisateur devra choisir",
    add_new_timeSlot: 'Ajouter une plage horaire',
    goals_updateMsg: 'Modifier un objectif',
    activities_updateMsg: 'Modifier une activité',
    levels_updateMsg: 'Modifier un niveau',
  },

  personal_account_title: 'Compte personnel',
  personal_account_subtitle: 'Gérer votre compte',
  update_personal_account: 'Modifier votre compte',
};
