export const getMaxValue = (a: number, b: number) => {
  if (b > a) {
    return b;
  } else {
    return a;
  }
};

export const generateRandomNumber = (min = 100, max = 100000) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateId = () => {
  return generateRandomNumber().toString();
};

export const add = (arr: Array<number>) => arr.reduce((a: number, b: number) => a + b, 0);

export function toFixedWithoutRounding(value: number, decimalPlaces: number): string {
  const fixedValue = Number(value.toFixed(4));
  const multiplier = Math.pow(10, decimalPlaces);
  const truncatedValue = Math.floor(fixedValue * multiplier) / multiplier;
  return truncatedValue.toFixed(decimalPlaces);
}
