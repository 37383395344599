export const gender = {
  m: 'm',
  f: 'f',
  o: 'a',

  M: 'M',
  F: 'F',
  O: 'A',

  male: 'Masculin',
  female: 'Féminin',
  other: 'Autre',
};
