import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import Paginator from 'types/interfaces/Paginator';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { injectParams } from 'utils/helpers/api-url';
import { paginatorToApiPagination } from 'utils/services/api.service';
import {
  transformNotificationOptions,
  transformNotificationResponse,
  transformNotificationsResponse,
} from './notificationApi.transform';
import { NotificationAPI, NotificationOptions } from './notifiicationApi.type';
import { joinPaths } from 'utils/helpers';

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['notification'],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (params: { paginator?: Paginator; options?: NotificationOptions }) => {
        return injectParams(ENDPOINTS.NOTIFICATION, {
          ...transformNotificationOptions(params?.options),
          ...paginatorToApiPagination(params.paginator),
        });
      },
      transformResponse: (response: ApiArrayDataResponse<NotificationAPI>) => {
        return transformNotificationsResponse(response);
      },
      providesTags: ['notification'],
    }),
    createNotification: builder.mutation({
      query: (body) => ({
        url: joinPaths([ENDPOINTS.NOTIFICATION]),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['notification'],
    }),
    getNotificationById: builder.query({
      query: (id: string) => `${ENDPOINTS.NOTIFICATION}/${id}`,
      transformResponse: (response: { data: NotificationAPI }) => {
        return transformNotificationResponse(response.data);
      },
      providesTags: ['notification'],
    }),
    updateNotification: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `${ENDPOINTS.NOTIFICATION}/${id}`,
          method: 'PUT',
          body: body,
        };
      },
      invalidatesTags: ['notification'],
    }),
    deleteNotification: builder.mutation({
      query: (body) => {
        return {
          url: ENDPOINTS.DELETE_Notification,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['notification'],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useCreateNotificationMutation,
  useGetNotificationByIdQuery,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
} = notificationApi;
