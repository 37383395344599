import { BORouteIdEnum } from 'config/enums/routes.enum';
import NotFoundPage from 'pages/NotFoundPage';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { GetUser } from 'redux/slices/Auth/authSlice';
import { createPath } from 'utils/helpers/path.helpers';

interface Props {
  children: ReactNode;
}
export function AdminGuard({ children }: Props) {
  const user = useSelector(GetUser);
  if (user?.roleId === 1) return <>{children}</>;
  return <NotFoundPage />;
}
