export const account = {
  no_accounts_yet: "Vous n'avez pas encore ajouté des comptes",

  add_account: 'Ajouter un compte',
  update_account: 'Modifier un compte',
  coachs_refused: 'Coach refusé',
  coachs_waiting: 'En cours de traitement',
  total_coaches: 'Total des coach traités',
  coaches_accepted: 'Coach accepté',

  /** delete compte info  */
  deleteAccountTitle: "Confirmation de suppression d'un compte",
  deleteAllAccountsTitle: 'Confirmation de suppression de tous les comptes',
  deleteSelectedAccountsTitle: 'Confirmation de suppression des comptes sélectionnés',

  deleteAllAccountsInfo:
    'Vous souhaitez supprimer tous les comptes, si oui confirmer l’action ci-dessous',
  deleteSelectedAccountsInfo:
    'Vous souhaitez supprimer les comptes sélectionnés, si oui confirmer l’action ci-dessous',
  deleteAccountInfo: 'Vous souhaitez supprimer ce compte ,si oui confirmer l’action ci-dessous ',

  profilePictureLabel: 'Photo de profil',
  accountType: 'Type de compte',
};
