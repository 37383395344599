import { Skeleton, Stack, useTheme } from '@mui/material';
import CustomImage from 'components/Common/CustomImage/CustomImage';
import { BORouteIdEnum } from 'config/enums';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NotificationBoxStyle, RootStyle } from './ProfileBox.style';
import { ProfileBoxProps } from './ProfileBox.type';
import LanguagesMenu from 'features/Language/LanguagesMenu/LanguagesMenu';
import Flag from 'react-world-flags';
import { ReactComponent as LanguageIcon } from 'assets/icons/language.svg';
import { TypographyOverflow } from 'components/Containers/TypographyOverflow';
import { ColorsConfig } from 'config/constant/colors.config';
import LanguageFlagBox from 'features/Language/LanguageFlagBox/LanguageFlagBox';
import { Language } from 'features/Translation/Types/models/Language';
import { ImageVariantsEnum } from 'config/enums/image.enum';

function ProfileBox({ size, profilePicture, isLoading, name }: Readonly<ProfileBoxProps>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [language, setLanguage] = useState<Language>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [photo, setPhoto] = useState('');
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const onImageClick = () => navigate(`${BORouteIdEnum.PersonalAccount}`);

  useEffect(() => {
    if (profilePicture) {
      setPhoto(profilePicture);
    }
  }, [profilePicture]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={{ md: 1, xs: 0.5 }}
      width={'100%'}
      justifyContent={'flex-end'}
    >
      <TypographyOverflow variant="h6" color={ColorsConfig.primary.main} title={name}>
        {name}
      </TypographyOverflow>
      <NotificationBoxStyle onClick={handleClick}>
        {language?.flag ? (
          <LanguageFlagBox flagUrl={language?.flag} />
        ) : (
          <Flag height="20" width="20" fallback={<LanguageIcon />} />
        )}
      </NotificationBoxStyle>
      <LanguagesMenu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          horizontal: -78,
          vertical: 48,
        }}
        handleClose={handleClose}
        onChangeSelectedLanguage={setLanguage}
      />
      <RootStyle>
        {isLoading ? (
          <Skeleton
            height={size ?? 63}
            width={size ?? 63}
            variant="rectangular"
            sx={{ borderRadius: '11px' }}
          />
        ) : (
          <CustomImage
            variant={ImageVariantsEnum.MEDIUM}
            src={photo}
            alt={t('user.profile_image')}
            width={size ?? 63}
            height={size ?? 63}
            sx={{
              border: `1px solid ${theme.palette.grey[100]}`,
              borderRadius: '11px',
              overflow: 'hidden',
            }}
            imgSx={{
              cursor: 'pointer',
              objectFit: 'cover',
              borderRadius: '11px',
              transition: 'transform 1s ease',
              '&:hover': {
                transform: 'scale(1.3)',
              },
            }}
            onViewImage={onImageClick}
          />
        )}
      </RootStyle>
    </Stack>
  );
}

export default ProfileBox;
