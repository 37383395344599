import { UserFormAPITranslatedKey, UserFormTranslatedKey } from './userForm.type';

export const transformTranslatedKey = (
  translated_key: UserFormAPITranslatedKey,
): UserFormTranslatedKey => {
  return {
    id: translated_key?.id,
    name: translated_key?.name,
    description: translated_key?.description,
    parentId: translated_key?.parent_id,
    parentType: translated_key?.parent_type,
    platformId: translated_key?.platform_id,
    translations:
      translated_key?.translations.map((translation) => {
        return {
          id: translation.id,
          languageId: translation.language_id,
          keyId: translation.key_id,
          value: translation.value,
        };
      }) ?? [],
  };
};
