import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { transformApiResponse } from '../BaseApiTransform';
import { activitiesList } from 'redux/api/activities/activities.decoder';
import { activityApi, transformedActivity } from 'redux/api/activities/activityApi.type';

export const transformActivitiesResponse = (
  response: ApiArrayDataResponse<activityApi>,
): ArrayDataResponse<transformedActivity> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map(activitiesList),
  };
};
