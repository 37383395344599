import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { AffirmativeResponse } from 'config/enums/common.enums';
import { ExerciseCategoryLabelsEnum, ExerciseTypeEnum } from 'config/enums/exercise.enum';
import Paginator from 'types/interfaces/Paginator';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { injectParams, joinPaths } from 'utils/helpers';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { baseQueryWithReAuth } from '../BaseQueryConfig';
import { getCategoryParams, getFiltersParams } from './exerciseApi.helpers';
import {
  transformCategoriesResponse,
  transformCreateExerciseBody,
  transformExercise,
  transformExercisesResponse,
} from './exerciseApi.transform';
import { ApiExercise, CategoryApiResponse } from './exerciseApi.type';
import { CreateExercise, UpdateExercise } from 'types/models/Exercise/Exercise';

export const exerciseApi = createApi({
  reducerPath: 'exerciseApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['exercises', 'categories'],
  endpoints: (builder) => ({
    // exercise categories
    getExerciseCategories: builder.query({
      query: (types?: ExerciseCategoryLabelsEnum[]) =>
        injectParams(`${ENDPOINTS.EXERCISE_CATEGORIES}`, {
          pagination: AffirmativeResponse.No,
          ...getCategoryParams(types),
        }),
      transformResponse: (res: CategoryApiResponse[]) => transformCategoriesResponse(res),
      providesTags: ['categories'],
    }),
    // get exercises
    getExercises: builder.query({
      query: ({
        paginator,
        categories_filter,
      }: {
        paginator: Paginator;
        type: ExerciseTypeEnum;
        categories_filter?: Record<string, (string | number)[]>;
      }) =>
        injectParams(joinPaths([ENDPOINTS.BO_SPORT_PROG_EXERCISES]), {
          ...paginatorToApiPagination(paginator),
          categories_filter: getFiltersParams(categories_filter),
        }),
      transformResponse: (res: ApiArrayDataResponse<ApiExercise>) =>
        transformExercisesResponse(res),
      providesTags: ['exercises'],
    }),
    // get exercise by id
    getExerciseById: builder.query({
      query: ({ id }: { id: number | string }) => `${ENDPOINTS.BO_EXERCISES}/${id}`,
      transformResponse: (res: { data: ApiExercise }) => transformExercise(res.data),
      providesTags: ['exercises'],
    }),

    // create
    createExercise: builder.mutation({
      query: (body: CreateExercise) => ({
        url: ENDPOINTS.BO_SPORT_PROG_EXERCISES,
        method: 'POST',
        body: transformCreateExerciseBody(body),
      }),
      invalidatesTags: ['exercises'],
    }),

    // update
    updateExercise: builder.mutation({
      query: (body: UpdateExercise) => ({
        url: `${ENDPOINTS.BO_EXERCISES}/${body.id}`,
        method: 'PATCH',
        body: { id: body.id, ...transformCreateExerciseBody(body) },
      }),
      invalidatesTags: ['exercises'],
    }),

    // delete exercise
    deleteExercise: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.BO_EXERCISES}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['exercises'],
    }),
  }),
});

export const {
  useGetExerciseCategoriesQuery,
  useGetExercisesQuery,
  useDeleteExerciseMutation,
  useCreateExerciseMutation,
  useUpdateExerciseMutation,
  useGetExerciseByIdQuery,
} = exerciseApi;
