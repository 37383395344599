import { useEffect, useState } from 'react';
import { LanguageCodes } from 'config/constant/language.config';
import i18n from 'locales/i18n';

export default function useLanguage() {
  const [language, setLanguage] = useState<string>(i18n.language ?? LanguageCodes.FALLBACK.short);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return { language, handleChangeLanguage };
}
