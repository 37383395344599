import { Theme } from '@mui/material';

export default function Link(theme: Theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          background: theme.palette.grey[200],
          color: theme.palette.grey[800],
          textDecoration: 'underline',
          '&:hover': {
            color: theme.palette.primary.dark,
            textDecoration: 'none',
          },
        },
      },
    },
  };
}
