import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { ApiCommand, CommandOptions } from './commandApi.type';
import { CommandType } from 'types/models/Command/Command';
import { transformApiResponse } from '../BaseApiTransform';

export const transformCommandsResponse = (
  response: ApiArrayDataResponse<ApiCommand>,
): ArrayDataResponse<CommandType> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((apiCommand) => {
      return {
        id: apiCommand.id,
        type: apiCommand.type,
        hasInvoice: apiCommand.has_invoice,
        user: {
          id: apiCommand?.user?.id,
          firstName: apiCommand?.user?.first_name,
          lastName: apiCommand?.user?.last_name,
          photo: apiCommand?.user?.photo,
        },
        coach: {
          id: apiCommand?.coach?.id,
          firstName: apiCommand?.coach?.first_name,
          lastName: apiCommand?.coach?.last_name,
          photo: apiCommand?.coach?.photo,
        },
        status: apiCommand.status,
        reference: apiCommand.reference,
        createdAt: apiCommand.created_at,
        paidAt: apiCommand.paid_at,
        totalTtc: apiCommand.total_ttc,
        sessionDatetime: apiCommand.session_datetime,
        userId: apiCommand.user_id,
        coachId: apiCommand.coach_id,
      };
    }),
  };
};

export const transformCommandsApiOptions = (options?: CommandOptions) => {
  return {
    user_id: options?.userId,
    start_date: options?.startDate,
    end_date: options?.endDate,
    type: options?.type,
  };
};
