export const command = {
  search: 'Rechercher une commande...',
  no_commands_yet: "Il n'a pas encore des commandes",
  lastCommandTitle: 'Dernières Commandes',
  commands_by_user_title: 'La liste des commande de',
  command_type_title: 'Types',

  command_details: "Détails d'une commande",
  info_client: 'Information client',
  info_coach: 'Information coach',
  total_ttc: 'Total ttc',

  // types
  all_types: 'Tous les types',
  shop: 'Boutique',
  private: 'Entrainement privé',
  public: 'Cours collectif',
  sport_program: 'Programme sportif',
  VOD: 'VOD',
  general_program: ' Programme sportif - Général',
  custom_program: ' Programme sportif - Personnalisé',

  // row

  client: 'Client',
  coach: 'Coach',
  activity: 'Type de commande',
  status: 'Status',
  total: 'Total ttc',
  paid_at: 'Date de paiment',
};
