import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { CreateCampaignRequest, CreateCampaignResponse } from './campaignApi.type';
import {
  transformCreateCampaignRequest,
  transformGetCampaignDetails,
} from './campaignApi.transform';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { Campaign } from 'types/models/Marketing/Campaign/Campaign';

export const campaignApi = createApi({
  reducerPath: 'trackingLink',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Campaigns'],
  endpoints: (builder) => ({
    getCampaignsList: builder.query<Campaign[], void>({
      query: () => ENDPOINTS.TRACKING,
    }),
    getCampaignById: builder.query<Campaign, string>({
      query: (id) => `${ENDPOINTS.TRACKING}/${id}`,
      transformResponse: (response: CreateCampaignResponse) =>
        transformGetCampaignDetails(response),
    }),
    createCampaign: builder.mutation<CreateCampaignResponse, CreateCampaignRequest>({
      query: (body) => ({
        url: ENDPOINTS.TRACKING,
        method: 'POST',
        body: transformCreateCampaignRequest(body),
      }),
      invalidatesTags: ['Campaigns'],
    }),
  }),
});

export const { useGetCampaignByIdQuery, useCreateCampaignMutation } = campaignApi;
