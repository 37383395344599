import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../BaseQueryConfig';
import Paginator from 'types/interfaces/Paginator';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { transformAdditionalProducts } from './additionalProductApi.transform';
import { injectParams } from 'utils/helpers/api-url';

type AllAdditionalProductsQueryParams = {
  paginator: Paginator;
};

export const additionalProductApi = createApi({
  reducerPath: 'additionalProductApi',
  baseQuery: fetchBaseQuery(baseQueryConfig),
  tagTypes: ['AdditionalProducts'],
  endpoints: (builder) => ({
    getAllAdditionalProducts: builder.query({
      query: ({ paginator }: AllAdditionalProductsQueryParams) => {
        return injectParams(ENDPOINTS.ADDITIONAL_PRODUCTS, {
          direction: paginator.order,
          keyword: paginator.searchText,
          orderBy: paginator.orderBy,
          page: paginator.page,
          perPage: paginator.rowsPerPage,
        });
      },
      transformResponse: (response: ApiArrayDataResponse) => transformAdditionalProducts(response),
      providesTags: ['AdditionalProducts'],
    }),
  }),
});

export const { useGetAllAdditionalProductsQuery } = additionalProductApi;
