export const userForm = {
  success_goal__created: 'Objectif créé avec succès',
  success_goal__updated: 'Objectif modifié avec succèes',
  fail_goal__created: "Échec de la création d'un nouvel objectif",
  fail_goal__updated: "Échec de la modification d'un objectif",
  success_level__created: ' Le niveau est crée avec succès',
  success_level__updated: 'Le niveau est modifié avec succès',
  fail_level__created: "Échec lors de la création d'un niveau",
  fail_level__updated: "Échec lors de la modification d'un niveau",
  success_activity__created: 'Activité crée avec succès',
  success_activity__updated: 'Activité modifié avec succès',
  fail_activity__created: "L'ajout d'une icône SVG est requis",
  fail_activity__updated: "Échec lors de la modification d'une activité",
  success_hour__created: 'Plage horaire ajoutée avec succès',
  required_hours: 'Plage horaire requise',
  delete_goal_confirmation_title: "Confirmation de suppression d'un objectif",
  delete_goal_confirmation_msg:
    "Vous souhaitez Supprimer l'objectif ? si oui confirmer l’action ci-dessous",
  delete_level_confirmation_msg:
    'Vous souhaitez Supprimer ce niveau ? si oui confirmer l’action ci-dessous',
  delete_hour_confirmation_msg:
    'Vous souhaitez Supprimer cette plage horaire ? si oui confirmer l’action ci-dessous',
  delete_activity_confirmation_msg:
    'Vous souhaitez Supprimer cette activité ? si oui confirmer l’action ci-dessous',
  success_deleted: 'La suppression a été effectuée avec succès.',
  fail_deleted: 'Echec de la suppression',
  number_of_votes: 'Nombre de votes',
  Distributions: 'Répartitions',
  total: 'TOTAL',
};
