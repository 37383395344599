export const shop = {
  my_products: 'Mes produits',
  my_commands: 'Mes commandes',
  add_product: 'Ajouter un produit',
  my_shop: 'Ma boutique',
  name: 'Nom',
  session: 'Séances',
  stock: 'Stock',
  price: 'Prix',
  status: 'Status',

  product: {
    photos: 'Photos de mise en avant du produit',
    product_name: 'Nom du produit',
    product_name_required: 'Le nom du produit est requis',
    product_name_invalid: 'Le nom du produit est invalide',
    product_name_short: 'Le nom du produit est trop court.',
    product_name_long: 'Le nom du produit est trop long.',
  },
};
