import { AdditionalProduct } from 'types/models/AdditionalProduct/AdditionalProducts';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { toCamelCase } from 'utils/helpers/object.helpers';

export const transformAdditionalProducts = (data: ApiArrayDataResponse) => {
  return {
    ...toCamelCase(data),
    data: toCamelCase(data.data),
  } as unknown as ArrayDataResponse<AdditionalProduct>;
};
