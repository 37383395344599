import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { BORouteIdEnum } from 'config/enums';

const MarketingPage = lazy(() => import('pages/MarketingPage/MarketingPage'));
const TrackingLinkSection = lazy(
  () => import('pages/MarketingPage/TrackingLinkSection/TrackingLinkSection'),
);
const PromoCodeSection = lazy(
  () => import('pages/MarketingPage/PromoCodesSection/PromoCodeSection'),
);
const CoachAffiliationSection = lazy(
  () => import('pages/MarketingPage/CoachAffiliationSection/CoachAffiliationSection'),
);
const SendNotificationSection = lazy(
  () => import('pages/MarketingPage/SendNotificationSection/SendNotificationSection'),
);
const HomeUserSection = lazy(() => import('pages/MarketingPage/HomeUserSection/HomeUserSection'));
const PopUpSection = lazy(() => import('pages/MarketingPage/PopUpSection/PopUpSection'));
const AddPopUp = lazy(() => import('pages/MarketingPage/PopUpSection/AddPopUp/AddPopUp'));
const UpdatePopUp = lazy(() => import('pages/MarketingPage/PopUpSection/UpdatePopUp/UpdatePopUp'));
const AddBoosting = lazy(
  () => import('pages/MarketingPage/HomeUserSection/AddBoosting/AddBoosting'),
);
const UpdateBoosting = lazy(
  () => import('pages/MarketingPage/HomeUserSection/UpdateBoosting/UpdateBoosting'),
);
const ShowDetailsTrackingLinkForm = lazy(
  () =>
    import(
      'pages/MarketingPage/TrackingLinkSection/ShowDetailsTrackingLinkForm/ShowDetailsTrackingLinkForm'
    ),
);
const AddNotification = lazy(
  () => import('pages/MarketingPage/SendNotificationSection/AddNotification/AddNotification'),
);
const UpdateNotification = lazy(
  () => import('pages/MarketingPage/SendNotificationSection/UpdateNotification/UpdateNotification'),
);
const AddTrackingLink = lazy(
  () => import('pages/MarketingPage/TrackingLinkSection/AddTrackingLink/AddTrackingLink'),
);

const MarketingRoutes = {
  path: BORouteIdEnum.Marketing,
  element: <MarketingPage />,
  children: [
    {
      path: BORouteIdEnum.Root,
      element: <Navigate to={BORouteIdEnum.TrackingLink} />,
    },
    {
      path: BORouteIdEnum.TrackingLink,
      children: [
        {
          path: BORouteIdEnum.Root,
          element: <TrackingLinkSection />,
        },
        {
          path: BORouteIdEnum.Add,
          element: <AddTrackingLink />,
        },
        {
          path: BORouteIdEnum.EditId,
          element: <ShowDetailsTrackingLinkForm />,
        },
      ],
    },
    {
      path: BORouteIdEnum.PromoCodes,
      element: <PromoCodeSection />,
    },
    {
      path: BORouteIdEnum.CoachAffiliation,
      element: <CoachAffiliationSection />,
    },
    {
      path: BORouteIdEnum.SendNotification,
      children: [
        {
          path: BORouteIdEnum.Root,
          element: <SendNotificationSection />,
        },
        {
          path: BORouteIdEnum.Add,
          element: <AddNotification />,
        },
        {
          path: BORouteIdEnum.EditId,
          element: <UpdateNotification />,
        },
      ],
    },
    {
      path: BORouteIdEnum.HomeUser,
      children: [
        {
          path: BORouteIdEnum.Root,
          element: <HomeUserSection />,
        },
        {
          path: BORouteIdEnum.Add,
          element: <AddBoosting />,
        },
        {
          path: BORouteIdEnum.EditId,
          element: <UpdateBoosting />,
        },
      ],
    },
    {
      path: BORouteIdEnum.PopUp,
      children: [
        {
          path: BORouteIdEnum.Root,
          element: <PopUpSection />,
        },
        {
          path: BORouteIdEnum.Add,
          element: <AddPopUp />,
        },
        {
          path: BORouteIdEnum.EditId,
          element: <UpdatePopUp />,
        },
      ],
    },
  ],
};

export default MarketingRoutes;
