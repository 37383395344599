export enum ProductTypeEnum {
  Private = 1,
  Public = 2,
}
export enum VisibilityEnum {
  VisibleOnAsc = 1,
  HiddenFromAsc = 0,
}

export enum PaymentPeriodEnum {
  PaymentInTwoMonths = 2,
  PaymentInThreeMonths = 3,
}

export enum ModeEnum {
  ModeVision = 1,
  ModePhysics = 2,
}

export enum PackEnum {
  IsPack = 1,
  NotPack = 0,
}
