export const dashboard = {
  // dashboard home
  search: 'Chercher un coaching, clients, etc...',
  welcome_to_dashboard: 'Bienvenue sur votre tableau de bord ASC',
  goodMorning: 'Bonjour',

  // Last commands
  last_commands: 'Dernières commandes',

  // next coaching
  next_coaching: 'Mes prochaines séances',
  programmed_sessions: 'Séances programmées',
  tomorrow: 'Demain',
  see_all: 'Voir tout',
  Hide: 'Ne pas afficher',

  // discussion
  discussion: 'Discussion',

  // notifications
  notification: 'Notifications',
  see_your_notifications: 'Voir vos notifications',

  // feedback
  feedback: 'Vos avis',
  see_your_feedback: 'Voir vos avis',
  on: 'Sur',
  opinions: 'avis',

  // revenues
  revenues: 'Revenues',
  private_training: 'Entrainement Privée',
  public_training: 'Cours collectifs',
  sport_program: 'Programme sportif',
  vod: 'VOD',
  sportsRetirement: 'Séjours sportifs',
  last_month_3: '3 derniers mois',
  last_month_6: '6 derniers mois',
  last_month_9: '9 derniers mois',
  last_month_12: '12 derniers mois',
  last_month_24: '24 derniers mois',
  all_activities: 'Toutes activités',
  box: 'Boxe',
  hiit: 'Hiit',
  yoga: 'Yoga',

  coachs_number: 'Total Coach',
  users_number: 'Total utilisateurs',
  products_number: 'Nbre produits créés',
  orders_number: 'Nbre commandes',
  week: ' + {{value}} cette semaine',

  turnover: 'CA',
  fees: 'Marge',
  fees_asc: 'Marge ASC',
  unusubscribe: 'Désabonnement et App',
  no_fees_yet: "Il n'a pas encore des CA",

  unsubscribe_coach_number: 'Taux désabonnement Coach',
  delete_account_number: 'Suppression compte Utilisateurs',
  rate_coachs_on_free_trial_period: "Taux Coachs en période d'essai",
  nb_installation_app_client_coach: 'NB installation app client et coach',

  coach_division: 'Répartitions H / F Coach',
  user_division: 'Répartitions H / F Utilisateurs',

  device_used: 'Devices utilisés coach',
  os_used: "Système d'exploitation utilisé",
};
