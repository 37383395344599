import { accountsApi } from 'redux/api/accounts/accountsApi';
import { activitiesApi } from 'redux/api/activities/activitiesApi';
import { additionalProductApi } from 'redux/api/additionalProduct/additionalProductApi';
import { forgetPasswordApi } from 'redux/api/auth/forgetPassword/forgetPasswordApi';
import { authApi } from 'redux/api/auth/login/loginApi';
import { registerApi } from 'redux/api/auth/register/registerApi';
import { availabilityApi } from 'redux/api/availability/availabilityApi';
import { citiesApi } from 'redux/api/cities/citiesApi';
import { clientApi } from 'redux/api/client/clientApi';
import { bestProductsApi } from 'redux/api/coach/bestProducts/bestProducts';
import { coachApi } from 'redux/api/coach/coachApi';
import { commandApi } from 'redux/api/command/commandApi';
import { countriesApi } from 'redux/api/countries/countriesApi';
import { dashboardApi } from 'redux/api/dashboard/dashboardApi';
import { eventApi } from 'redux/api/event/eventApi';
import { boostingApi } from 'redux/api/marketing/boosting/boostingApi';
import { popupApi } from 'redux/api/marketing/popup/popupApi';
import { mediaApi } from 'redux/api/media/mediaApi';
import { placeApi } from 'redux/api/place/placeApi';
import { userApi } from 'redux/api/user/userApi';
import { useAppDispatch } from 'redux/hooks';

export default function useClearApiCache() {
  const dispatch = useAppDispatch();
  const onClearApiCachedData = () => {
    dispatch(additionalProductApi.util.resetApiState());
    dispatch(forgetPasswordApi.util.resetApiState());
    dispatch(authApi.util.resetApiState());
    dispatch(registerApi.util.resetApiState());
    dispatch(clientApi.util.resetApiState());
    dispatch(eventApi.util.resetApiState());
    dispatch(placeApi.util.resetApiState());
    dispatch(userApi.util.resetApiState());
    dispatch(coachApi.util.resetApiState());
    dispatch(activitiesApi.util.resetApiState());
    dispatch(countriesApi.util.resetApiState());
    dispatch(commandApi.util.resetApiState());
    dispatch(accountsApi.util.resetApiState());
    dispatch(availabilityApi.util.resetApiState());
    dispatch(citiesApi.util.resetApiState());
    dispatch(mediaApi.util.resetApiState());
    dispatch(bestProductsApi.util.resetApiState());
    dispatch(dashboardApi.util.resetApiState());
    dispatch(popupApi.util.resetApiState());
    dispatch(boostingApi.util.resetApiState());
  };
  return { onClearApiCachedData };
}
