import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { CoachAccountStatusEnum } from 'config/constant/coach.config';
import { BORouteIdEnum } from 'config/enums';

// Lazy loaded pages
const CoachesPage = lazy(() => import('pages/CoachesPage/CoachesListPage/CoachesPage'));
const CoachesSection = lazy(() => import('pages/CoachesPage/CoachesSection/CoachesSection'));
const UpdateCoach = lazy(() => import('pages/CoachesPage/CoachesListPage/UpdateCoach/updateCoach'));
const WaitingCoaches = lazy(() => import('pages/CoachesPage/WaitingCoachesPage/WaitingCoaches'));
const WaitingCoachesSection = lazy(
  () => import('pages/CoachesPage/WaitingCoachesPage/WaitingCoachesSection/WaitingCoachesSection'),
);
const AddCoach = lazy(() => import('pages/CoachesPage/CoachesListPage/AddCoach/AddCoach'));
const ScheduleSection = lazy(
  () => import('pages/CoachesPage/WaitingCoachesPage/ScheduleSection/ScheduleSection'),
);
const AvailabilitiesSection = lazy(
  () => import('pages/CoachesPage/WaitingCoachesPage/AvailabilitiesSection/AvailabilitiesSection'),
);
const RefusedCoachesPage = lazy(
  () => import('pages/CoachesPage/RefusedCoachesPage/RefusedCoachesPage'),
);
const CoachesProductsList = lazy(
  () => import('pages/CoachesPage/CoachesProductsList/CoachesProductsList'),
);
const DetailsProduct = lazy(
  () => import('pages/CoachesPage/CoachesProductsList/DetailsProduct/DetailsProduct'),
);
const GroupOfCoachesList = lazy(
  () => import('pages/CoachesPage/GroupOfCoachList/GroupOfCoachList'),
);
const GroupForm = lazy(() => import('pages/CoachesPage/GroupOfCoachList/GroupForm/GroupForm'));

const CoachesRoutes = {
  path: BORouteIdEnum.Coaches,
  element: <CoachesPage />,
  children: [
    { path: BORouteIdEnum.Root, element: <Navigate to={BORouteIdEnum.allCoaches} /> },
    {
      path: BORouteIdEnum.allCoaches,
      children: [
        { path: BORouteIdEnum.Root, element: <CoachesSection /> },
        {
          path: BORouteIdEnum.EditId,
          element: <UpdateCoach accountStatus={CoachAccountStatusEnum.STATUS_ACCOUNT_ACCEPTED} />,
        },
      ],
    },
    {
      path: BORouteIdEnum.waitingCoaches,
      element: <WaitingCoaches />,
      children: [
        {
          path: BORouteIdEnum.Root,
          element: <Navigate to={BORouteIdEnum.all} />,
        },
        {
          path: BORouteIdEnum.all,
          children: [
            { path: BORouteIdEnum.Root, element: <WaitingCoachesSection /> },
            { path: BORouteIdEnum.Add, element: <AddCoach /> },
            {
              path: BORouteIdEnum.EditId,
              element: (
                <UpdateCoach accountStatus={CoachAccountStatusEnum.STATUS_ACCOUNT_PENDING} />
              ),
            },
          ],
        },
        {
          path: BORouteIdEnum.schedule,
          element: <ScheduleSection />,
        },
        {
          path: BORouteIdEnum.availabilities,
          element: <AvailabilitiesSection />,
        },
      ],
    },
    {
      path: BORouteIdEnum.refusalCoach,
      children: [
        { path: BORouteIdEnum.Root, element: <RefusedCoachesPage /> },
        {
          path: BORouteIdEnum.EditId,
          element: <UpdateCoach accountStatus={CoachAccountStatusEnum.STATUS_ACCOUNT_REFUSED} />,
        },
      ],
    },
    {
      path: BORouteIdEnum.allProducts,
      children: [
        { path: BORouteIdEnum.Root, element: <CoachesProductsList /> },
        {
          path: BORouteIdEnum.TypeId,
          element: <DetailsProduct />,
        },
      ],
    },
    {
      path: BORouteIdEnum.groupOfCoaches,
      children: [
        {
          path: BORouteIdEnum.Root,
          element: <GroupOfCoachesList />,
        },
        {
          path: BORouteIdEnum.Add,
          element: <GroupForm />,
        },
        {
          path: BORouteIdEnum.EditId,
          element: <GroupForm />,
        },
      ],
    },
  ],
};

export default CoachesRoutes;
