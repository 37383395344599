export const course = {
  course: 'Cours',
  create_course: 'Créer une séance',
  add_new_course: 'Ajouter un nouveau cours',
  search_or_add_course: 'Rechercher ou ajouter un cours',

  session_date_and_time: 'Date et heure de la séance',
  session_date_and_time_select_time_first: "Veuillez d'abord sélectionner une date",
  session_date_and_time_select_client_and_course_first:
    "Veuillez d'abord sélectionner un client et un cours",
  select_session_place: 'Sélectionnez le lieu de la séance',
  duplicated_slot_message: 'Attention vous avez un doublon de date et heure, merci de les modfier',

  buy_date: "Date d'achat",
  course_date: 'Date séance',
  persons_number: 'Nombre de personnes',
  persons_number_minified: 'Nb. de Pers.',

  cancel_courses: 'Annuler séance(s)',
  export_invoices: 'Exporter facture(s)',
  form_inputs: {
    name_too_short: 'Le nom est trop court. Veuillez entrer un nom plus long.',
  },
};
