import {
  ASCTurnoverFees,
  CoachRevenues,
  DashboardStatistics,
} from 'types/models/Dashboard/Dashboard';
import { ASCTurnoverFeesApi, CoachRevenuesApi, DashboardStatisticsApi } from './dashboardApi.type';
import { devicesEnum } from 'config/enums/statistic.enum';
import { PersonEnum } from 'config/enums/coaches.enum';

export const transformMonthsApiOptions = (months?: string) => {
  return {
    months: months,
  };
};

export const transformDashboardStatisticsResponse = (
  response: DashboardStatisticsApi,
): DashboardStatistics => {
  const { devices, gender } = response.coaches_statistics;
  const totalPhoneDevices = Number(devices[devicesEnum.IOS]) + Number(devices[devicesEnum.ANDROID]);
  const usersStatistics = {
    gender: {
      man: Number(response.users_statistics.gender[PersonEnum.man]),
      woman: Number(response.users_statistics.gender[PersonEnum.woman]),
      other: Number(response.users_statistics.gender[PersonEnum.other]),
    },
  };
  return {
    globalStatistics: {
      totalCoachesNumber: response.global_statistics.total_coaches_number,
      totalUsersNumber: response.global_statistics.total_users_number,
      totalProductsNumber: response.global_statistics.total_products_number,
      totalOrdersNumber: response.global_statistics.total_orders_number,
    },
    thisWeekStatistics: {
      totalCoachesNumbers: response.this_week_statistics.total_coaches_numbers,
      totalUsersNumbers: response.this_week_statistics.total_users_numbers,
      totalProductsNumbers: response.this_week_statistics.total_products_numbers,
      totalOrdersNumbers: response.this_week_statistics.total_orders_numbers,
    },
    accountsStatistics: {
      coachesTryingPeriodRate:
        response.coaches_users_accounts_statistics.coaches_trying_period_rate,
      coachesUnsubscriptionRate:
        response.coaches_users_accounts_statistics.coaches_unsubscription_rate,
      usersDeletionsRate: response.coaches_users_accounts_statistics.users_deletions_rate,
    },
    coachsStatistics: {
      devices: {
        tablette: Number(devices[devicesEnum.TABLET]),
        phone: totalPhoneDevices,
        pc: Number(devices[devicesEnum.PC]),
      },
      gender: {
        man: Number(gender[PersonEnum.man]),
        woman: Number(gender[PersonEnum.woman]),
        other: Number(gender[PersonEnum.other]),
      },
      os: {
        ios: Number(devices[devicesEnum.IOS]),
        android: Number(devices[devicesEnum.ANDROID]),
        other: Number(devices[devicesEnum.TABLET]) + Number(devices[devicesEnum.PC]),
      },
    },
    usersStatistics,
  };
};

export const transformCoachRevenuesResponse = (response: CoachRevenuesApi): CoachRevenues => {
  return response.map((revenue) => {
    return {
      type: revenue.type,
      revenues: revenue.revenues,
    };
  });
};

export const transformASCTurnoverFeesResponse = (response: ASCTurnoverFeesApi): ASCTurnoverFees => {
  return response.map((item) => {
    return {
      year: item.year,
      month: item.month,
      ascFees: item.asc_fees,
      turnover: item.turnover,
    };
  });
};
