export const place = {
  place: 'Lieu',
  title: 'Liste des salles',
  subtitle: 'Nos salles',
  search: 'Chercher une salle...',
  asc_training_places: 'Salles ASC',
  coaches_training_places: 'Salles des coachs',
  director_info: 'Informations du dirigeant',
  country: 'Pays',
  country_placeholder: 'Sélectionnez un pays',
  city: 'Ville',
  city_placeholder: 'Sélectionnez une ville',
  city_required: 'La ville est requise',

  create: 'Ajouter la salle',
  update: 'Mettre à jour la salle',

  name: 'Nom du lieu',
  training_place_name: 'Nom de la salle',
  name_placeholder: 'Donnez un nom à votre salle…',
  name_required: 'Le nom du lieu est requis',
  name_too_short: 'Le nom du lieu est trop court',
  name_too_long: 'Le nom du lieu est trop long',

  address: 'Adresse',
  address_placeholder: 'Ex: 30 avenue de la Paix, Paris, France',
  address_required: "L'adresse du lieu est requise",
  address_too_short: "L'adresse du lieu est trop courte",
  address_too_long: "L'adresse du lieu est trop longue",

  address_room_placeholder: 'Adresse de salle',

  price: 'Prix',
  price_label: 'Ajouter un montant supplémentaire pour ce lieu ?',
  price_placeholder: '30',
  price_unit_text: '{{unit}} / heure',
  price_min_error: 'Veuillez entrer un prix supérieur ou égal à {{minPrice}}.',
  price_max_error: 'Veuillez entrer un prix inférieur ou égal à {{maxPrice}}.',

  capacity_label: 'Combien de personne maximum par heure ?',
  capacity_required: 'Veuillez indiquer la capacité requise.',
  capacity_placeholder: '5',
  capacity_unit_text: 'pers. max / heure',
  capacity_min_error: 'Le nombre minimum de la capacité par heure est de {{minCapacity}} pers.',
  capacity_max_error: 'Le nombre maximum de la capacité par heure est de {{maxCapacity}} pers.',

  select_place: 'Sélectionnez un lieu',
  search_place: 'Rechercher un lieu...',

  course_type: 'Type de cours',
  course_type_required: 'Le type de cours est requis',

  add_place: 'Ajouter lieu',
  add_the_place: 'Ajouter le lieu',
  add_a_place: 'Ajouter un lieu',
  add_place_message: 'Ajoutez ce lieu à votre liste pour vos cours à présence physique',
  add_this_place: 'Ajouter ce lieu',
  add_new_place: 'Ajouter un nouveau lieu',
  add_a_training_place: 'Ajouter une salle',
  create_a_place: 'Renseignez une salle',
  add_extra_price_question: 'Ajouter un montant supplémentaire à ce lieu ?',

  image_visual: 'Visuel de la salle',

  your_availabilities: 'Vos disponibilités dans cette salle',
  your_availabilities_description:
    'Déterminez les plages sur lesquelles vous serez disponibles pour cette salle uniquement',
  all_people_can_access: 'Tout le monde peut accéder à la salle ?',
  how_to_access_label: 'Si non, expliquez les modalités :',
  how_to_access: 'Modalités d’accès à la salle',

  location: 'Lieu',
  localization: 'Localisation',
  region: 'Arrondissement',
  owner_city: 'Ville',
  region_placeholder: 'Sélectionnez un arrondissement',
  owner_city_placeholder: 'Sélectionnez une ville',

  typology: 'Typologie',
  parc: 'Parc',
  room: 'Salle',

  available_on_place: 'Disponible sur place',
  price_per_hour: 'Montant par heure',
  towel: 'Serviettes',
  shower: 'Douches',
  hamam: 'Hamam',
  locker: 'Casiers',
  bodybuilding_equipment: 'Matériel de musculation',
  box_equipment: 'Matériel de Boxe',
  yoga_mat: 'Tapis de Yoga',
  sauna: 'Sauna',
  athletic_track: 'Piste d’athlétisme',
  treadmill: 'Tapis de course',
  skipping_rope: 'Cordes à sauter',
  pool: 'Piscine',

  error_loading_map: 'Erreur lors du chargement de la carte',

  no_places_yet: 'Aucune salle enregistré',
  view_more_places: 'Voir plus de salles',

  delete_confirmation: 'Voulez-vous vraiment supprimer la salle {{placeName}} ?',

  information_and_availabilities: 'Informations et disponibilités',
  detailed_information: 'Informations détailleés de la salle',
  stripe_and_bank_account: 'Stripe et compte bancaire',

  siren_number: 'Numéro de SIRET',
  siren_number_placeholder: '123456789',
  siren_number_required: 'Le numéro de SIRET est requis',
  siren_number_invalid: 'Le numéro de SIRET est invalide',

  room_mail_placeholder: 'adresse@sallemail.com',

  url: 'Url',
  url_placeholder: 'https://www.salledesport.com',
  url_required: 'L’url est requise',
  url_invalid: 'L’url est invalide',
  url_too_short: 'L’url est trop court',

  owner_mail_placeholder: 'addresse@dirigeantmail.com',
  owner_name_placeholder: 'Nom du dirigeant',
  owner_prename_placeholder: 'Prénom du dirigeant',

  owner_job: 'Poste',
  owner_job_placeholder: 'Manager, Partenaire, etc...',
  owner_job_required: 'La poste est requise',
  owner_job_invalid: 'La poste est invalide',
  owner_job_too_short: 'La poste est trop courte',
  owner_job_too_long: 'La poste est trop longue',

  owner_address_required: 'L’adresse est requise',
  owner_address_invalid: 'L’adresse est invalide',
  owner_address_too_short: 'L’adresse est trop courte',
  owner_address_too_long: 'L’adresse est trop longue',

  owner_first_address: 'Première adresse',
  owner_first_address_placeholder: '30 rue de la paix',

  owner_second_address: 'Deuxième adresse',
  owner_second_address_placeholder: '30 rue de la république',

  owner_country: 'Pays du dirigeant',
  owner_country_required: 'Le pays est requis',

  stripe_required_actions_to_fill:
    'Avant de finaliser la configuration de la salle, vous devez compléter les étapes suivantes',
  stripe_account_waiting_for_validation:
    "Le compte bancaire de la salle {{placeName}} est en train d'être validé par Stripe.",
  stripe_account_is_validating: 'Le compte bancaire est en cours de validation...',
  stripe_account_is_accepted: 'Le compte bancaire a été accepté.',
  stripe_account_is_rejected: 'Le compte bancaire a été refusé.',
  accepted: 'Confirmée',
  rejected: 'Refusée',
  loading: 'Inachevée',

  external_account_created: 'Compte bancaire a été ajoutée avec succés',

  owner_city_too_short: 'La ville est trop courte',

  region_required: 'La région est requise',
  access_description_required: 'La description est requise',
};
