import { transformApiResponse } from 'redux/api/BaseApiTransform';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { Notification } from 'types/models/Marketing/Notification/Notification';
import { NotificationAPI, NotificationOptions } from './notifiicationApi.type';

export const transformNotificationOptions = (options?: NotificationOptions) => {
  return {
    start_date: options?.startDate,
    end_date: options?.endDate,
  };
};

export const transformNotificationsResponse = (
  response: ApiArrayDataResponse<NotificationAPI>,
): ArrayDataResponse<Notification> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((notificationApi) => {
      return {
        id: notificationApi.id,
        title: notificationApi.title,
        target: notificationApi.target,
        content: notificationApi.content,
        triggerDate: notificationApi.trigger_date,
        sendCount: notificationApi.send_count,
        readCount: notificationApi.read_count,
        readRate: notificationApi.read_rate,
        image: notificationApi.image,
      };
    }),
  };
};

export const transformNotificationResponse = (response: NotificationAPI): Notification => {
  const translations =
    response && response.translations && response?.translations?.length > 0
      ? response.translations.map((el) => {
          return {
            id: el.id,
            languageId: el.language_id,
            notificationId: el.notification_id,
            title: el.title,
            content: el.content,
          };
        })
      : [];
  return {
    id: response.id,
    title: response.title,
    target: response.target,
    content: response.content,
    triggerDate: response.trigger_date,
    sendCount: response.send_count,
    readCount: response.read_count,
    readRate: response.read_rate,
    image: response.image,
    translations: translations,
  };
};
