import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { BORouteIdEnum } from 'config/enums';

// Layouts and Guards
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import AuthGuard from 'shared/guards/AuthGuard';

// Pages
import CoachesRoutes from './CoachesRoutes';
import UserRoutes from './UsersRoutes';
import ReviewsRoutes from './ReviewsRoutes';
import AccountsRoutes from './AccountsRoutes';
import ExercisesRoutes from './ExercisesRoutes';
import TrainingPlaceRoutes from './TrainingPlaceRoutes';
import MarketingRoutes from './MarketingRoutes';

// Lazy loaded pages
const ConnectedUserAccount = lazy(
  () => import('pages/AccountsPage/ConnectedUserAccount/ConnectedUserAccount'),
);
const TranslationPage = lazy(
  () => import('features/Translation/pages/TranslationPage/TranslationPage'),
);
const DashboardPage = lazy(() => import('pages/DashboardPage/DashboardPage'));

const DashboardRoutes = {
  path: BORouteIdEnum.BO,
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: BORouteIdEnum.Root,
      element: <Navigate to={BORouteIdEnum.Dashboard} />,
    },
    {
      path: BORouteIdEnum.Dashboard,
      element: <DashboardPage />,
    },
    CoachesRoutes,
    UserRoutes,
    ReviewsRoutes,
    AccountsRoutes,
    ExercisesRoutes,
    TrainingPlaceRoutes,
    MarketingRoutes,
    { path: BORouteIdEnum.Statistics, element: <>Statistics</> },
    { path: BORouteIdEnum.Translations, element: <TranslationPage /> },
    { path: BORouteIdEnum.PersonalAccount, element: <ConnectedUserAccount /> },
  ],
};

export default DashboardRoutes;
