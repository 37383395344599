export const visio = {
  no_visioLink: "Vous n'avez pas encore de lien de visioconférence.",
  visio_date: 'Date et horaire de la visio',
  visioLink: 'Lien de la visio',
  reschedule: 'Réplanifier',
  title: 'Retour Visio',
  coach_absent: "Le coach ne s'est pas présenté",
  expert_absent: "Je n'étais pas présent",
  other_reason: 'Autre',
  reason_didnt_happen: "Pourquoi le rendez-vous n'a pas eu lieu?",
  throw_stripe_discription:
    'La visio a bien été validé, Souhaitez-vous poursuivre avec le coach ? Si OUI cliquez sur lancer création stripe, si non sur refuser',
  throw_stripe: 'Lancer la création stripe',
  create_stripe_account_success: 'Le compte stripe a été créé avec succès',
  finish_last_step: 'Veuillez suivre les actions ci-dessous pour finaliser la validation',
  not_authorized_to_finish_last_step:
    "Vous n'avez pas le droit de Valider le compte d'un coach qui est assigné à un autre expert!",
  specify: 'Préciser',
  refuse_coach: `Vous avez refusé le coach {{fullName}}, merci d'expliquer pourquoi`,
  date_has_passed: 'La date de la visio est passé(e), Confirmez-vous le rendez-vous ?',
  visio_confirm: 'Je confirme',
  didnt_happen: "Il n'a pas eu lieu",
};
