import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import {
  ApiGroupResponse,
  GetGroupOfCoachResponse,
  GroupOptions,
} from 'redux/api/coach/groupOfCoaches/groupOfCoachesApi.types';
import {
  transformGroupOfCoaches,
  transformGroupResponse,
  transformGroupsApiOptions,
} from 'redux/api/coach/groupOfCoaches/groupOfCoachesApi.transform';

export const groupApi = createApi({
  reducerPath: 'groupApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Groups'],
  endpoints: (builder) => ({
    getCoachesGroup: builder.query({
      query: (params: { paginator?: Paginator; options: GroupOptions }) =>
        injectParams(ENDPOINTS.GROUP_OF_COACHES, {
          ...transformGroupsApiOptions(params.options),
          ...paginatorToApiPagination(params.paginator),
        }),
      transformResponse: (response: ApiArrayDataResponse<ApiGroupResponse>) => {
        return transformGroupResponse(response);
      },
      providesTags: ['Groups'],
    }),
    deleteCoachesGroup: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.DELETE_GROUP_OF_COACHES,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Groups'],
    }),
    createGroup: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.CREATE_GROUP,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Groups'],
    }),
    getDetailsGroup: builder.query({
      query: ({ id }) => `${ENDPOINTS.CREATE_GROUP}/${id}`,
      transformResponse: (response: { data: GetGroupOfCoachResponse }) =>
        transformGroupOfCoaches(response.data),
      providesTags: ['Groups'],
    }),
    updateGroup: builder.mutation({
      query: ({ body, SelectedGroupId }) => ({
        url: `${ENDPOINTS.CREATE_GROUP}/${SelectedGroupId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Groups'],
    }),
  }),
});
export const {
  useGetCoachesGroupQuery,
  useDeleteCoachesGroupMutation,
  useCreateGroupMutation,
  useGetDetailsGroupQuery,
  useUpdateGroupMutation,
} = groupApi;
