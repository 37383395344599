import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { injectParams } from 'utils/helpers';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import {
  HoursTypesFromApi,
  TrainingHourFromApi,
} from 'redux/api/user/UserForm/HoursUserForm/hoursFormApi.type';
import {
  transformHoursListResponse,
  transformStatisticResponse,
} from 'redux/api/user/UserForm/HoursUserForm/hoursFormApi.transform';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { AffirmativeResponse } from 'config/enums/common.enums';

export const hoursFormApi = createApi({
  reducerPath: 'hoursFormApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['hoursFormApi'],
  endpoints: (builder) => ({
    getHours: builder.query({
      query: () =>
        injectParams(ENDPOINTS.LISTE_OF_HOURS, {
          pagination: AffirmativeResponse.No,
        }),

      transformResponse: (response: ApiArrayDataResponse<HoursTypesFromApi>) => {
        return transformHoursListResponse(response);
      },
      providesTags: ['hoursFormApi'],
    }),
    addHour: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.ADD_HOUR,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['hoursFormApi'],
    }),
    deleteHour: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS.DELETE_HOUR.replace(':id', id)}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['hoursFormApi'],
    }),
    getHoursStatistic: builder.query({
      query: () => ({
        url: ENDPOINTS.STATISTIC_HOUR,
        method: 'GET',
      }),

      transformResponse: (response: TrainingHourFromApi) => {
        return transformStatisticResponse(response);
      },
      providesTags: ['hoursFormApi'],
    }),
  }),
});

export const {
  useGetHoursQuery,
  useAddHourMutation,
  useDeleteHourMutation,
  useGetHoursStatisticQuery,
} = hoursFormApi;
