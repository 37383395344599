import { Box, MenuItem, styled } from '@mui/material';
import { GlobalVariables } from 'config/constant';

export const LanguageMenuItemRoot = styled(MenuItem)(
  ({ theme }) =>
    ({ selected }: { selected?: string }) => ({
      padding: 9,
      paddingInline: 16,
      width: '100%',
      color:
        selected === GlobalVariables.TRUE ? theme.palette.text.primary : theme.palette.grey[500],
      whiteSpace: 'normal',
      ':hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
      },
      minWidth: 120,
    }),
);

export const DividerStyle = styled(Box)(({ theme }) => ({
  margin: '0px !important',
  width: '90%',
  height: 0,
  border: `1px solid ${theme.palette.grey[200]}`,
}));
