import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { AffirmativeResponse } from 'config/enums/common.enums';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import {
  encodeGoalFormRequest,
  transformGoalsListResponse,
} from 'redux/api/user/UserForm/GoalsUserForm/goalsFormApi.transform';
import {
  ApiGoalsType,
  GoalFormRequest,
} from 'redux/api/user/UserForm/GoalsUserForm/goalsFormApi.type';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { injectParams } from 'utils/helpers';

export const GoalsFormApi = createApi({
  reducerPath: 'GoalsFormApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['GoalsFormApi'],
  endpoints: (builder) => ({
    getGoals: builder.query({
      query: () =>
        injectParams(ENDPOINTS.LIST_OF_GOALS, {
          pagination: AffirmativeResponse.No,
        }),

      transformResponse: (response: ApiArrayDataResponse<ApiGoalsType>) => {
        return transformGoalsListResponse(response);
      },
      providesTags: ['GoalsFormApi'],
    }),
    createGoal: builder.mutation({
      query: (body: GoalFormRequest) => ({
        url: ENDPOINTS.ADD_GOAL,
        method: 'POST',
        body: encodeGoalFormRequest(body),
      }),
      invalidatesTags: ['GoalsFormApi'],
    }),
    deleteGoal: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS.DELETE_GOAL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GoalsFormApi'],
    }),
    updateGoal: builder.mutation({
      query: (body: GoalFormRequest) => ({
        url: `${ENDPOINTS.UPDATE_GOAL.replace(':id', String(body?.id))}`,
        method: 'PATCH',
        body: encodeGoalFormRequest(body),
      }),
      invalidatesTags: ['GoalsFormApi'],
    }),
  }),
});

export const {
  useGetGoalsQuery,
  useCreateGoalMutation,
  useDeleteGoalMutation,
  useUpdateGoalMutation,
} = GoalsFormApi;
