import { DEFAULT_TIME_ZONE } from 'config/constant/timezone.constant';

export const defaultTrainingRoomAvailabilities = {
  time_zone: DEFAULT_TIME_ZONE.id,
  availability_count: 30,
  availability_unit: 2,
  days: [
    { day: 0, slots: [] },
    { day: 1, slots: [] },
    { day: 2, slots: [] },
    { day: 3, slots: [] },
    { day: 4, slots: [] },
    { day: 5, slots: [] },
    { day: 6, slots: [] },
  ],
  accessibility_terms: '',
  is_accessible: 0,
};
