export const notices = {
  all_notices: 'Tous les avis des coachs',
  stars: '{{number}} étoiles',
  star: '{{number}} étoile',
  notice_of_user: 'avis de',
  notice_for_coach: 'Pour coach',
  product_name: 'Activités',
  rating: 'Notation',
  no_notices_yet: 'Aucun avis.',
  title: 'Les avis',
  subtitle: 'Statistiques et Liste des avis',
  search: 'Rechercher un avis...',
  delete_notice_message: "Vous souhaitez supprimer l'avis de  {{firstName}} {{lastName }} ?",
  success_notice_deleted: 'Avis supprimé avec succès',
  success_notice_deleted_message: "L'Avis a été supprimé avec succès de la base de données.",
  delete_notices_message: 'Vous souhaitez supprimer les avis sélectionnés ?',
  success_notices_deleted: 'Les avis ont été supprimés avec succès',
  success_notices_deleted_message: 'Les avis ont été supprimés avec succès de la base de données',
  contact_client: 'Contacter le client',
  contact_coach: 'Contacter le coach',
  nbrs_sessions: ' {{number}} séances effectués avec {{firstName}} {{lastName}} ',
  client_notice: "L'avis du client",
  activities: "Activités liés à l'avis",
  session_date: 'Date séance',
  course_provided_by: 'Cours assuré par',
};
