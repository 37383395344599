import { Box, List, Stack } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';
import { BorderStyle } from './NavSection.style';
import { NavSectionProps } from './NavSection.type';
import NavSectionItem from './NavSectionItem/NavSectionItem';
import { useSelector } from 'react-redux';
import { GetUser } from 'redux/slices/Auth/authSlice';

export default function NavSection({ navConfig, isMini }: NavSectionProps) {
  const { pathname } = useLocation();

  const checkIsActive = (path: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;
  const user = useSelector(GetUser);
  return (
    <List disablePadding style={{ paddingInline: 15, maxHeight: '100%', overflow: 'auto' }}>
      {navConfig.map((group, i) => {
        const isLast = i === navConfig.length - 1;
        return (
          <Box key={group.order}>
            <Stack paddingY={2} spacing={0.5}>
              {group?.items?.map(
                (item) =>
                  item &&
                  item?.role &&
                  item?.role?.includes(user?.roleId as number) && (
                    <NavSectionItem
                      key={item.order}
                      item={item}
                      isMini={isMini}
                      checkIsActive={checkIsActive}
                    />
                  ),
              )}
            </Stack>
            {!isLast && <BorderStyle />}
          </Box>
        );
      })}
    </List>
  );
}
