import { lazy } from 'react';
import { BORouteIdEnum } from 'config/enums';

import AuthLayout from 'layouts/AuthLayout/AuthLayout';

const ResetPassword = lazy(() => import('pages/AuthPages/ResetPassword/ResetPassword'));
const ForgetPassword = lazy(() => import('pages/AuthPages/ForgetPassword/ForgetPassword'));
const VerificationCode = lazy(() => import('pages/AuthPages/VerificationCode/VerificationCode'));
const Login = lazy(() => import('pages/AuthPages/Login/Login'));

const AuthRoutes = {
  path: BORouteIdEnum.Auth,
  element: <AuthLayout />,
  children: [
    { path: BORouteIdEnum.Login, element: <Login /> },
    { path: BORouteIdEnum.ResetPassword, element: <ResetPassword /> },
    { path: BORouteIdEnum.ForgetPassword, element: <ForgetPassword /> },
    { path: BORouteIdEnum.VerificationCode, element: <VerificationCode /> },
  ],
};

export default AuthRoutes;
