export const GlobalVariables = {
  NoDataLength: 0,
  NoIndex: -1,
  ManyDataLength: 2,
  EmptyString: '',
  TOKEN: 'token',
  EmptyJsonString: '{}',
  TRUE: 'true',
  FALSE: 'false',
  STRING: 'string',
  DATE: 'date',
  shortTextInputLength: 100,
  DataLengthOverLimitDisplay: '+99',
  DataLengthLimit: 99,
  MIN: 'min',
  RequiredSymbol: '*',
  Space: ' ',

  DebounceTime: {
    Short: 100,
    Medium: 500,
    MediumLong: 1000,
  },

  Date: {
    DefaultFormat: 'DD/MM/YYYY',
    DefaultFormatShort: 'DD/MM/YY',
    DefaultFormatWithTime: 'DD/MM/YYYY HH:mm',
    DefaultStringFormatWithTime: 'DD/MM/YYYY - HH[h]mm',
    StringDateFormatWithTime: 'dddd D MMMM YYYY - HH[h]mm',
    StringDateFormat: 'dddd DD MMMM',
    StringDateFormatWithYear: 'dddd DD MMMM YYYY',
    PointsDateFormat: 'DD.MM.YY',
    TextFormat: 'DD MMM YYYY',
    TimeFormat: 'HH:mm',
    MonthFormat: 'MMMM YYYY',
    TimeSecondsFormat: 'HH:mm:ss',
    FullDateFormat: 'dddd DD MMMM YYYY',
    ISO8601DateFormat: 'YYYY-MM-DDT',
    ISO8601DateFormatWithTime: 'YYYY-MM-DDTHH:mm',
    MonthYearFormat: 'MMMM YYYY',
    YearFormat: 'YYYY',
    DayMonthFormat: 'DD MMMM',
    FullTextFormat: 'DD MMMM YYYY',
    DateTimeFormat: 'DD/MM/YYYY hh:mm A',
    TimeMinuteAndSecondsFormat: 'mm:ss',
  },

  Map: {
    DefaultZoom: 15,
    DefaultPosition: { lat: 48.856614, lng: 2.3522219 },
    MediumZoom: 10,
  },

  Inputs: {
    shortText: {
      minLength: 3,
      maxLength: 30,
    },
    longText: {
      minLength: 3,
      maxLength: 50,
    },
    mcc: {
      minLength: 4,
      maxLength: 4,
    },
    address: {
      minLength: 10,
      maxLength: 50,
      regex: /^[\s\S]{10,50}$/,
    },
    number: {
      regex: /[^0-9]/g,
    },
    shortPassword: {
      minLength: 8,
    },
    reasonText: {
      minLength: 3,
      maxLength: 150,
    },
    description: {
      minLength: 10,
      maxLength: 250,
    },
    name: {
      regex: /^[a-zA-ZÀ-ÿ\s']+$/,
    },
    postalCode: {
      minLength: 2,
      regex: /^[A-Za-z0-9]+$/,
      error_regex: /INVALID_[A-Z]+_POSTAL_CODE/g,
    },
    url: {
      minLength: 8,
      regex: /^(http[s]?:\/\/)?(www\.)?[^\s$.?#].[^\s]*$/,
    },
    phoneNumber: {
      min: 0,
      max: 30,
      minLength: 10,
      maxLength: 10,
      // TODO: use regex for ASC phone numbers
      regex: /^[0-9]*$/,
    },
    proNumberCard: {
      minLength: 11,
      maxLength: 11,
      regex: /^[0-9]{5}[a-zA-Z]{2}[0-9]{4}$/,
    },
    ibanNumber: {
      minLength: 14,
      maxLength: 14,
      regex: /^[0-9]*$/,
    },
    bicNumber: {
      minLength: 14,
      maxLength: 14,
      regex: /^[0-9]*$/,
    },
    price: {
      min: 1, // TODO: check with client
    },
    password: {
      regex:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&$_=,:.;+-])[A-Za-z\d@$!%*?&$_=,:.;+-]{8,}$/,
    },
    shortActivityText: {
      minLength: 3,
      maxLength: 30,
    },
    placeBicNumber: {
      minLength: 8,
      maxLength: 11,
    },
    email: {
      minLength: 3,
      maxLength: 100,
    },
    roomDescription: {
      maxLength: 60,
    },
    insuranceNumber: {
      regex: /^[a-zA-Z0-9 ]*$/,
    },
  },

  Alerts: {
    DefaultDuration: 3000,
    ClearStateDuration: 5300,
  },

  CurrencySymbol: '€',

  Percentage: '%',

  /* Pagination */
  Pagination: {
    ElementsPerPage: { min: 25, median: 50, max: 100 },
    RowsPerPage: 10,
    FirstPage: 1,
  },

  numberOfInstallments: { max: 3 },

  KeyDownTypes: {
    Enter: 'Enter',
    Escape: 'Escape',
  },

  File: {
    DefaultType: 'file',
    DefaultAccept: '*',
    DefaultAcceptType: ['*'],
    DefaultMaxSize: 10485760,
    DefaultMaxSizeInMb: 10,
    maxFileListCount: 5,
    Image: {
      Type: 'file',
      MaxSize: 10485760,
      MaxSizeInMb: 10,
      AcceptType: ['.jpg', '.jpeg', '.png'],
      DefaultAlt: 'image',
    },
    Video: {
      Type: 'file',
      MaxSize: 62914560, // TODO - recheck
      MaxSizeInMb: 60, // TODO - recheck
      AcceptType: ['.mp4', '.avi', '.wmv'],
      DefaultAlt: 'video',
    },
    Document: {
      Type: 'file',
      MaxSize: 10485760,
      MaxSizeInMb: 10,
      AcceptType: ['.jpg', '.jpeg', '.png', 'gif', 'svg', '.pdf'],
    },
  },

  /* Inputs autocomplete */
  AUTOCOMPLETE_OFF: 'off',
  AUTOCOMPLETE_ON: 'on',
  AUTOCOMPLETE_EMAIL: 'email',
  AUTOCOMPLETE_CURRENT_PASSWORD: 'current-password',
  AUTOCOMPLETE_ON_NEW_PASSWORD: 'new-password',

  Calendar: {
    Views: {
      Month: 'Month',
      Week: 'Week',
    },
    WorkHours: {
      Min: '00:00:00', // TODO - Recheck with back
      Max: '24:00:00',
    },
  },

  DEBOUNCE_TIME: 500,
  DEBOUNCE_TIME_LONG: 1000,
  DEBOUNCE_TIME_SHORT: 100,
  ALL_CHARACTERS: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789=-&()=+!-',
  CONFIRM_DELETE: 'SUPPRIMER',
  ROLES: {
    ADMIN: [1],
    EXPERT: [2],
    ALL: [1, 2],
  },
  ALL_CHARACTERS_MAJ: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  ALL_CHARACTERS_MIN: 'abcdefghijklmnopqrstuvwxyz',
  ALL_NUMBERS: '0123456789',
  ALL_SPECIAL_CHARACTERS: '=-&+!-',

  X_AMZ_EXPIRES: 30000,
};
