import { CoachingTypeEnum } from 'config/enums/coaching.enum';
import { AffirmativeResponse } from 'config/enums/common.enums';
import { PlaceTypologyEnum, TrainingPlaceType } from 'config/enums/trainingPlace.enum';
import { ApiArrayDataResponse, ApiFilter } from 'types/models/ApiResponse';
import TrainingPlace, {
  CreateTrainingPlace,
  TrainingPlaceEquipment,
} from 'types/models/TrainingPlace/TrainingPlace';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { transformApiResponse } from '../BaseApiTransform';
import { transformAvailability } from '../availability/availabilityApi.transform';
import {
  ApiAvailabilityDay,
  ApiAvailabilitySlot,
  ApiAvailabilitySpecialDay,
} from '../availability/availabilityApi.type';
import { defaultTrainingRoomAvailabilities } from './placeApi.constants';
import {
  AllPlacesQueryParams,
  ApiCreatePlace,
  ApiEquipment,
  ApiTrainingPlace,
} from './placeApi.type';
import { dayjsToTimestamp, timestampToDayjs } from 'utils/helpers';
import dayjs from 'dayjs';
import { transformApiMediaType } from 'redux/api/commonTypes/file';

export const transformEquipment = (equipment: ApiEquipment): TrainingPlaceEquipment => ({
  id: equipment.id,
  name: equipment.name,
  image: transformApiMediaType(equipment.icon),
});

export const transformEquipmentResponse = (
  response: ApiArrayDataResponse<ApiEquipment>,
): TrainingPlaceEquipment[] => response.data.map(transformEquipment);

export const transformTrainingPlace = (apiTrainingPlace: ApiTrainingPlace): TrainingPlace => {
  return {
    id: apiTrainingPlace.id,
    image: transformApiMediaType(apiTrainingPlace.photo ?? apiTrainingPlace.picture ?? ''),
    name: apiTrainingPlace.name,
    location: {
      lat: parseFloat(apiTrainingPlace.latitude),
      lng: parseFloat(apiTrainingPlace.longitude),
      address: apiTrainingPlace.address,
      driving: apiTrainingPlace.driving,
      bicycling: apiTrainingPlace.bicycling,
      transit: apiTrainingPlace.transit,
    },
    price: apiTrainingPlace.amount_per_hour ?? 0,
    capacity: apiTrainingPlace.capacity,
    isAsc: apiTrainingPlace.is_asc,
    equipments: apiTrainingPlace.services.map(transformEquipment),
    typology: apiTrainingPlace.type,
    coachingTypes:
      apiTrainingPlace.product_type === 3
        ? [CoachingTypeEnum.Private, CoachingTypeEnum.Public]
        : [apiTrainingPlace.product_type],
    country: apiTrainingPlace.country
      ? {
          id: apiTrainingPlace.country.id,
          code: apiTrainingPlace.country.code,
          name: apiTrainingPlace.country.translated_name,
          translatedName: apiTrainingPlace.country.translated_name,
        }
      : undefined,
    availability: apiTrainingPlace.availability
      ? {
          ...transformAvailability(apiTrainingPlace.availability, false),
          access: {
            value: apiTrainingPlace.is_accessible
              ? AffirmativeResponse.Yes
              : AffirmativeResponse.No,
            description: apiTrainingPlace.accessibility_terms ?? '',
          },
        }
      : undefined,
    paymentStatus: apiTrainingPlace.payment_provider_status,
    countryId: apiTrainingPlace.person_country_id,
    info: {
      room: {
        email: apiTrainingPlace.email,
        phone: apiTrainingPlace.phone,
        url: apiTrainingPlace.url,
        sirenNumber: apiTrainingPlace.siret,
      },
      personal: {
        firstName: apiTrainingPlace.person_first_name,
        lastName: apiTrainingPlace.person_last_name,
        email: apiTrainingPlace.person_email,
        phone: apiTrainingPlace.person_phone,
        city: apiTrainingPlace.person_city,
        firstAddress: apiTrainingPlace.person_address_line1,
        secondAddress: apiTrainingPlace.person_address_line2,
        postalCode: apiTrainingPlace.person_postal_code,
        birthDate: timestampToDayjs(apiTrainingPlace?.person_birth_date ?? 0),
      },
    },
  };
};

export const transformPlacesResponse = (response: ApiArrayDataResponse<ApiTrainingPlace>) => {
  return {
    ...transformApiResponse(response),
    data: response.data.map(transformTrainingPlace),
    categories: transformPlacesFilters(response.filters),
  };
};

export const transformCreatePlaceBody = (trainingPlace: CreateTrainingPlace) => {
  let days: ApiAvailabilityDay[] = defaultTrainingRoomAvailabilities.days;
  let special: ApiAvailabilitySpecialDay[] = [];
  let placePrivateInfo: Record<string, unknown> = {};
  let { time_zone, availability_unit, availability_count, accessibility_terms, is_accessible } =
    defaultTrainingRoomAvailabilities;

  if (trainingPlace.availability) {
    const { access, numberOfDays, timeZoneId, weekDays, specificDays } = trainingPlace.availability;

    accessibility_terms = access?.description ?? '';
    is_accessible = access?.value ?? defaultTrainingRoomAvailabilities.is_accessible;
    time_zone = timeZoneId ?? defaultTrainingRoomAvailabilities.time_zone;
    availability_unit = numberOfDays.type ?? defaultTrainingRoomAvailabilities.availability_unit;
    availability_count =
      numberOfDays.number ?? defaultTrainingRoomAvailabilities.availability_count;

    days = days.map((defaultDay) => {
      const slots: ApiAvailabilitySlot[] =
        weekDays
          .find((d) => d.id === defaultDay.day)
          ?.slots.map((slot) => ({
            start_time: slot.start as number,
            end_time: slot.end as number,
          })) ?? [];

      return {
        ...defaultDay,
        slots,
      };
    });

    special = specificDays.map((specificDay) => {
      const slots: ApiAvailabilitySlot[] = specificDay.slots.map((slot) => ({
        start_time: slot.start as number,
        end_time: slot.end as number,
      }));

      return {
        date: specificDay.date - new Date().getTimezoneOffset() * 60,
        slots,
      };
    });
  }

  if (trainingPlace.typology === PlaceTypologyEnum.Room) {
    placePrivateInfo = {
      email: trainingPlace.info?.room.email,
      phone: trainingPlace.info?.room.phone,
      siret: trainingPlace.info?.room.sirenNumber,
      url: trainingPlace.info?.room.url,
      person_country_id: trainingPlace?.info?.personal?.country?.id ?? 0,
      person_address_line1: trainingPlace.info?.personal.firstAddress,
      person_address_line2: trainingPlace.info?.personal.secondAddress,
      person_city: trainingPlace.info?.personal.city,
      person_email: trainingPlace.info?.personal.email,
      person_first_name: trainingPlace.info?.personal.firstName,
      person_last_name: trainingPlace.info?.personal.lastName,
      person_phone: trainingPlace.info?.personal.phone,
      person_postal_code: trainingPlace.info?.personal.postalCode,
      person_birth_date: dayjsToTimestamp(trainingPlace.info?.personal.birthDate ?? dayjs()),
    };
  }

  const apiPlace: ApiCreatePlace = {
    name: trainingPlace.name,
    type: trainingPlace.typology,
    address: trainingPlace.location.address ?? '',
    latitude: trainingPlace.location.lat,
    services: trainingPlace.equipments,
    longitude: trainingPlace.location.lng,
    district_id: trainingPlace.regionId ?? 0,
    product_type: trainingPlace.coachingTypes.length === 2 ? 3 : trainingPlace.coachingTypes[0],
    capacity: trainingPlace.capacity,
    amount_per_hour: trainingPlace.price,
    has_supplement_amount: trainingPlace.price ? 1 : 0,
    photo_id: trainingPlace.image,
    is_accessible,
    accessibility_terms,
    days,
    special,
    time_zone,
    availability_unit,
    availability_count,
    country_id: trainingPlace.countryId ?? 0,
    ...placePrivateInfo,
  };

  return apiPlace;
};

const getFilterLabel = (
  filter: ApiFilter,
  subFilter: {
    label: string;
    value: number;
    translated_key?: string;
  },
) => {
  const { translated_key, label } = subFilter;
  const { translated_key: filterTranslatedKey } = filter;

  if (translated_key && filterTranslatedKey !== 'cities') {
    if (filterTranslatedKey === 'country') {
      return `COUNTRIES.${translated_key}`;
    }
    return `placesFilters.${translated_key}`;
  } else {
    return label;
  }
};

export const transformPlacesFilters = (filters?: ApiFilter[]) =>
  filters
    ? [
        {
          id: 0,
          label: 'common.categories',
          value: [{ label: 'common.all_categories', value: 0 }],
        },
        ...filters.map((filter) => ({
          id: filter.id,
          label: filter.translated_key ? `placesFilters.${filter.translated_key}` : filter.label,
          value: filter.value.map((subFilter) => ({
            label: getFilterLabel(filter, subFilter),
            value: subFilter.value,
          })),
        })),
      ]
    : [
        {
          id: 0,
          label: 'common.categories',
          value: [{ label: 'common.all_categories', value: 0 }],
        },
      ];

export const transformGetPlaceParams = (params: AllPlacesQueryParams) => {
  return {
    ...paginatorToApiPagination(params.paginator),
    ...params.filters,
    coaches: params.type === TrainingPlaceType.Personal ? 1 : undefined,
  };
};
