import { CustomStepperType } from 'types/interfaces/CustomStepperType';

export enum AccountStatusEnum {
  STATE_ACCOUNT_TO_VERIFY = 1,
  STATE_ACCOUNT_WAITING_FOR_APPOINTMENT = 2,
  STATE_ACCOUNT_VISIO_DONE = 3,

  STATE_ACCOUNT_REFUSED = 6,
  // suspended
  STATE_ACCOUNT_DEACTIVATED = 8,
  STATE_ACCOUNT_ACTIVATED = 9,
  STATE_ACCOUNT_STRIPE_CREATED = 10,
  // TO FIX WITH API
  STATE_ACCOUNT_ACCEPTED = 11,
}

export const ACCOUNT_INFOS_STATUS: CustomStepperType[] = [
  { label: 'coaches.status.account_to_verify', id: AccountStatusEnum.STATE_ACCOUNT_TO_VERIFY },
  {
    label: 'coaches.status.account_waiting_for_appointment',
    id: AccountStatusEnum.STATE_ACCOUNT_WAITING_FOR_APPOINTMENT,
  },
  { label: 'coaches.status.account_visio_done', id: AccountStatusEnum.STATE_ACCOUNT_VISIO_DONE },
  {
    label: 'coaches.status.account_stripe_created',
    id: AccountStatusEnum.STATE_ACCOUNT_STRIPE_CREATED,
  },
];

export enum PersonEnum {
  woman = 1,
  man = 2,
  other = 3,
}
