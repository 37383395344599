import { Box, styled, keyframes } from '@mui/material';

export const slideAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const SlideContainer = styled(Box)(() => ({
  width: '100px',
  height: '100px',
  animation: `${slideAnimation} 2s ease-in-out infinite`,
}));
