import { Review, ReviewsFromApi } from 'redux/api/notice/noticeApi.type';

export const mapReviews = (apiReview: ReviewsFromApi): Review => {
  return {
    id: apiReview.id,
    coach: {
      id: apiReview.coach.id,
      firstName: apiReview.coach.first_name,
      lastName: apiReview.coach.last_name,
      profilePicture: apiReview.coach.profile_picture,
      rating: apiReview.coach.rating,
    },
    user: {
      id: apiReview?.user?.id,
      firstName: apiReview?.user?.first_name,
      lastName: apiReview?.user?.last_name,
      profilePicture: apiReview?.user?.photo,
    },
    product: {
      name: apiReview.product.name,
      type: apiReview.product.type,
      photo: apiReview.product.photo,
    },
    rating: apiReview.rating,
    content: apiReview.content,
    createdAt: apiReview.created_at,
    updatedAt: apiReview.updated_at,
  };
};
