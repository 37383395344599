export const stripe = {
  title: 'vérifier votre entreprise',
  commerceType: 'Vous êtes',
  businessType: "Type d'entreprise",
  company: 'Une entreprise',
  indiv: 'Personne Physique',
  tva: 'TVA',
  society: 'Entreprise',
  stripeLink: 'Compte Stripe',
  requiredActions: 'Actions requises',
  ExternalAccount: 'Ajouter un compte bancaire ou une carte de débit',
  businessProfile: 'Ajouter les informations manquantes concernant le profil de l’entreprise',
  individual: 'Ajouter les informations manquantes concernant la personne.',
  personal_account: 'Ajouter les informations manquantes concernant le dirigeant',
  representative: 'Ajouter les informations manquantes concernant le représentant',
  directors: 'Ajouter les informations manquantes concernant les directeurs',
  executives: 'Ajouter les informations manquantes concernant les cadres executifs',
  owners: 'Ajouter les informations manquantes concernant les propriétaires',
  company_account: 'Ajouter les informations manquantes concernant la sociéte',
  country_account: 'Pays du compte',
  account_success_validation: 'Compte Stripe Validé avec succèes',
  account_bank_success_added: 'Le compte bancaire ajouté avec succcès',
  validation_inProgress: 'Le compte est en cours de validation',
  companyType: {
    auto: 'Auto entrepreneur',
    sasu: ' SASU, SAS etc…',
    residingAbroad: 'Résidant à l’étranger',
  },
  click_to_see_details: 'Cliquez pour voir les détails de votre compte bancaire',

  mcc: 'MCC ( Merchant Category Codes )',
  mcc_placeholder: '3000',
  mcc_required: 'MCC est requis',
  mcc_too_short: 'MCC est trop court',

  product_description: 'Description du produit',
  product_description_placeholder: 'description',
  product_description_required: 'La description est requis',
  product_description_too_short: 'La description est trop court',

  support_phone: 'Téléphone de support',
  support_phone_required: 'Téléphone est requis',
  support_phone_too_short: 'Téléphone est trop court',

  url: 'URL',
  url_placeholder: 'https://www.example.com',
  url_required: 'URL est requise',
  url_too_short: 'URL est trop court',
  url_invalid: 'URL est invalide',

  address: 'Adresse',
  relationship: 'Relation',

  executive: 'Exécutif',
  executive_placeholder: 'Nom de l’exécutif',
  executive_required: 'Nom de l’exécutif est requis',
  executive_too_short: 'Nom de l’exécutif est trop court',
  executive_too_long: 'Nom de l’exécutif est trop long',

  relationship_title: 'Titre',
  title_placeholder: 'CEO, Partenaire, etc...',
  title_required: 'Titre est requis',
  title_too_short: 'Titre est trop court',
  title_too_long: 'Titre est trop long',

  directors_provided: 'Directeurs',
  directors_provided_placeholder: 'Directeurs....',
  directors_provided_required: 'Driecteur est requis',
  directors_provided_too_short: 'Directeur est trop court',
  directors_provided_too_long: 'Directeur est trop long',

  owners_provided: 'Propriétaires',
  owners_provided_placeholder: 'Propriétaires....',
  owners_provided_required: 'Propriétaire est requis',
  owners_provided_too_short: 'Propriétaire est trop court',
  owners_provided_too_long: 'Propriétaire est trop long',

  executives_provided: 'Executifs',
  executives_provided_placeholder: 'Executifs....',
  executives_provided_required: 'Exécutif est requis',
  executives_provided_too_short: 'Exécutif est trop court',
  executives_provided_too_long: 'Exécutif est trop long',

  tax_id: 'Tax ID',
  tax_id_placeholder: '1234',
  tax_id_required: 'Tax ID est requis',
  tax_id_too_short: 'Tax ID est trop court',
  tax_id_too_long: 'Tax ID est trop long',

  external_account_hero: 'Ajouter un compte bancaire ou une carte de débit',
  business_profile_hero: 'Ajouter  le profil de l’entreprise',
  company_hero: 'Ajouter le profil de la société',
  directors_hero: 'Ajouter le profil du directeur',
  executives_hero: 'Ajouter le profil du cadre executif',
  owners_hero: 'Ajouter le profil de propriétaire',
  representative_hero: 'Ajouter le profil du représentant',

  company_name: 'Nom',
  company_name_placeholder: 'Nom de l’entreprise',
  company_name_required: 'Nom de l’entreprise est requis',
  company_name_too_short: 'Nom de l’entreprise est trop court',
  company_name_too_long: 'Nom de l’entreprise est trop long',
  validated_section_success: 'La section a été validée avec succès',
};
