import { Theme } from '@mui/material';

export default function Avatar(theme: Theme) {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[900],
          textTransform: 'uppercase',
        },
      },
    },
  };
}
