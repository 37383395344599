import { Box, Stack } from '@mui/material';
import NoImageFound from 'assets/images/NoImageFound.png';
import { GlobalVariables } from 'config/constant';
import { SyntheticEvent, useMemo, useState } from 'react';
import { VariantsArray } from './CustomImage.constants';
import { getMediaSource } from './CustomImage.helper';
import { LoadingStyle, RootStyle } from './CustomImage.style';
import { CustomImageProps } from './CustomImage.type';
import { MediaSources } from 'types/models/common/MediaType/MediaType';

function CustomImage({
  src,
  alt,
  height,
  width,
  clickable,
  sx,
  imgSx,
  loadedImgSx,
  imagePlaceholder,
  variant,
  skeletonVariant,
  onViewImage,
  isUpdating,
}: Readonly<CustomImageProps>) {
  const variantIndex = VariantsArray.indexOf(variant);
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const source = useMemo(() => {
    if (typeof src !== 'string') {
      return getMediaSource(src as MediaSources, VariantsArray[variantIndex]);
    } else {
      return src;
    }
  }, [src, variantIndex]);
  const initialSrc = useMemo(
    () => (loaded ? source : typeof src !== 'string' && src?.thumbnail),
    [src, loaded],
  );

  const onImageError = (event: SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = imagePlaceholder ?? NoImageFound;
    event.currentTarget.alt = GlobalVariables.EmptyString;
  };

  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <RootStyle
        src={initialSrc || (imagePlaceholder ?? NoImageFound)}
        alt={alt}
        height={thumbnailLoaded ? height : '0px'}
        width={thumbnailLoaded ? width : '0px'}
        loading="lazy"
        onLoad={() => setThumbnailLoaded(true)}
        onError={onImageError}
        onClick={onViewImage}
        clickable={clickable ? 1 : 0}
        sx={loadedImgSx && loaded ? loadedImgSx : imgSx}
      />
      <img
        src={source}
        onLoad={() => setLoaded(true)}
        style={{ visibility: 'hidden', display: 'none' }}
      />
      {thumbnailLoaded ? null : (
        <Stack alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'}>
          <LoadingStyle
            sx={{ ...sx, borderRadius: skeletonVariant ? undefined : '5px' }}
            width={width}
            height={height}
            variant={skeletonVariant ?? 'rectangular'}
          />
        </Stack>
      )}
    </Box>
  );
}

export default CustomImage;
