import { Box, Grid, styled } from '@mui/material';

export const StackStyle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  paddingInline: '12px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const BoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  maxWidth: 60,
  '&: hover': {
    fill: theme.palette.primary.main,
  },
}));
