import Paginator from 'types/interfaces/Paginator';
import { IParamsRoomsToApi } from 'types/models/Rooms/Rooms.type';

// Inject a paginator in a request params
export const injectPaginator = (url: string, paginator: Paginator) => {
  const { page, rowsPerPage } = paginator;
  return `${url}?perPage=${rowsPerPage}&page=${page}`;
};

// Inject a name in a request params
export const injectName = (url: string, name: string, isExtra?: boolean) => {
  return `${url}${isExtra ? '&' : '?'}name=${name}`;
};

export const paginatorToApiPagination = (paginator: Paginator | undefined) => {
  if (paginator) {
    return {
      // Todo: update this when the api is updated
      per_page: paginator.rowsPerPage,
      order_by: paginator.orderBy,
      direction: paginator.order,
      group_by: paginator.groupBy,
      keyword: paginator.searchText,
      page: paginator.page,
    };
  }
  return {};
};
export const paramsRoomsToApi = (params: IParamsRoomsToApi) => {
  return {
    [`params[${params?.capacity?.operator}]`]: params?.capacity?.value,
    is_asc: params?.is_asc,
    services: params?.services,
    type: params?.type,
  };
};

export const arrayToParams = (key: string, array: Array<number | string>) => {
  let string = '';
  array.forEach((value) => {
    string += `${key}[]=${value}&`;
  });
  return string;
};
