import { Grid } from '@mui/material';
import CustomBackdrop from 'components/CustomLoader/CustomBackdrop/CustomBackdrop';
import { RouterPaths } from 'config/constant';
import { AppThemes } from 'config/enums/theme.enum';
import useLoggedIn from 'hooks/useLoggedIn';
import { Suspense, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { changeTheme } from 'redux/slices/app/appSlice';

export default function AuthLayout() {
  const isLoggedIn = useLoggedIn();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeTheme({ theme: AppThemes.EPR }));
  }, []);

  if (isLoggedIn) {
    return <Navigate to={RouterPaths.DashboardPaths.dashboardPath} replace />;
  }

  return (
    <Suspense fallback={<CustomBackdrop />}>
      <Grid container>
        <Outlet />
      </Grid>
    </Suspense>
  );
}
