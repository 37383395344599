import { Group } from 'features/Translation/Types/models/Group';
import { Platform } from 'features/Translation/Types/models/Platform';
import { Section } from 'features/Translation/Types/models/Section';
import { SectionTypeEnum } from 'features/Translation/config/enums/groups.enum';

export const addGroupToNestedGroup = (
  groups: Group[],
  parentId: number,
  payload: Group,
): Group[] => {
  return groups.map((group) => {
    if (group.id === parentId) {
      const updatedGroups = group.groups ? [...group.groups, payload] : [payload];
      return {
        ...group,
        groups: updatedGroups,
      };
    } else if (group.groups) {
      return {
        ...group,
        groups: addGroupToNestedGroup(group.groups, parentId, payload),
      };
    }
    return group;
  });
};

export const addGroupToGroup = (
  sections: Section[],
  activeSectionIndex: number,
  payload: Group,
): Section[] => {
  return sections.map((section) => {
    if (section.id === sections[activeSectionIndex].id) {
      return {
        ...section,
        groups: addGroupToNestedGroup(section.groups, payload.parentId, payload),
      };
    }
    return section;
  });
};

export const removeGroupFromGroup = (
  groupId: number,
  parentId: number,
  sections: Section[],
): Section[] => {
  return sections.map((section) => {
    if (section.id === parentId) {
      return {
        ...section,
        groups: section.groups?.filter((element) => element.id !== groupId),
      };
    }
    if (section.groups) {
      return {
        ...section,
        groups: removeGroupFromNestedGroup(groupId, section.groups),
      };
    }
    return section;
  });
};

export const removeGroupFromNestedGroup = (groupId: number, groups: Group[]): Group[] => {
  return groups
    .map((group) => {
      if (group.id === groupId) {
        return undefined;
      }
      if (group.groups) {
        return {
          ...group,
          groups: removeGroupFromNestedGroup(groupId, group.groups),
        };
      }
    })
    .filter((group) => !!group) as Group[];
};

export const updateGroupInSection = (
  sections: Section[],
  parentId: number,
  groupId: number,
  name: string,
): Section[] => {
  return sections
    .map((section) => {
      if (section.id === parentId) {
        return {
          ...section,
          groups: section.groups?.map((group) => {
            if (group.id === groupId) {
              return {
                ...group,
                name,
              };
            }
            return group;
          }),
        };
      }
      if (section.groups) {
        return {
          ...section,
          groups: updateGroupFromNestedGroup(groupId, section.groups, name),
        };
      }
    })
    .filter((section) => !!section) as Section[];
};

export const updateGroupFromNestedGroup = (
  groupId: number,
  groups: Group[],
  name: string,
): Group[] => {
  return groups
    .map((group) => {
      if (group.id === groupId) {
        return {
          ...group,
          name,
        };
      }
      if (group.groups) {
        return {
          ...group,
          groups: updateGroupFromNestedGroup(groupId, group.groups, name),
        };
      }
    })
    .filter((group) => !!group) as Group[];
};

export const getConstantsSectionIds = (platforms: Platform[]): number[] => {
  const constantsSectionIds: number[] = [];

  platforms.forEach((platform) => {
    platform.sections.forEach((section) => {
      if (section.type === SectionTypeEnum.STATIC) {
        constantsSectionIds.push(section.id);
      }
    });
  });

  return constantsSectionIds;
};
