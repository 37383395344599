import { transformApiResponse } from 'redux/api/BaseApiTransform';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { PopUps } from 'types/models/Marketing/PopUps/PopUps';
import { PopUpOptions, PopUpsAPI } from './popup.type';

export const transformPopUpApiOptions = (options?: PopUpOptions) => {
  return {
    start_date: options?.startDate,
    end_date: options?.endDate,
  };
};

export const transformPopUpsResponse = (
  response: ApiArrayDataResponse<PopUpsAPI>,
): ArrayDataResponse<PopUps> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((popUpApi) => {
      return {
        id: popUpApi.id,
        title: popUpApi.title,
        target: popUpApi.target,
        content: popUpApi.content,
        endDate: popUpApi.end_date,
        startDate: popUpApi.start_date,
        viewCount: popUpApi.view_count,
      };
    }),
  };
};

export const transformPopUpResponse = (response: PopUpsAPI): PopUps => {
  return {
    id: response.id,
    title: response.title,
    target: response.target,
    content: response.content,
    endDate: response.end_date,
    startDate: response.start_date,
    viewCount: response.view_count,
  };
};
